// vendors
import React, {useState} from "react";

//components
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import DetailViewTemplate from "com/templates/DetailViewTemplate";

import ContentPanel from 'com/widgets/ContentPanel';

import DataTree from "com/widgets/DataTree";

import './style.css';

const EcommerceCategories = (props) => {

    const headerNav = [
		{ to: '/ecommerce', label: 'Dashboard' },
		{ to: '/ecommerce/products', label: 'Products' },
		{ to: '/ecommerce/categories', label: 'Categories' },
        { to: '/ecommerce/orders', label: 'Orders'},
        { to: '/ecommerce/warehouse', label: 'Warehouse'},
	];

    const initData = [
		{
			id: "1",
			name: "Home",
			link: "http://google-home.com",
			children: [
				{
					id: "1a",
					name: "Home 1",
					link: "http://google-home1.com",
					children: [

					]
				},
				{
					id: "1b",
					name: "Home 2",
					link: "http://google-home2.com",
					children: [

					]
				}
			]
		}
    ];

    const [data, setData] = useState(initData);

    const onEdit = (node, pl) => {
		console.log('edit node', node, pl);
	};

	const onCreateChild = (node, pl) => {
		console.log('create child node', node, pl);
	};

	const onDelete = (node, pl) => {
		console.log('delete node', node, pl);
	};

    return (
        <ApplicationTemplate
            headerTitle="E-Commerce"
            headerNavigation={headerNav}
        >
            <DetailViewTemplate>
                <DetailViewTemplate.Main>
                    <div className="categories-panel-wrapper">
                        <ContentPanel title="Categories" customClassName="categories-content-panel">
                            <ContentPanel.Body>
                                <DataTree
                                    data={data}
                                    setData={setData}
                                    fullTree={data}
                                    onEdit={onEdit}
                                    onCreateChild={onCreateChild}
                                    onDelete={onDelete}
                                    // onlyWithinParent={true} // ONLY reorder within the node it is positioned in
                                />
                            </ContentPanel.Body>
                        </ContentPanel>
                        <ContentPanel title="Category Details" customClassName="categories-content-panel">
                            <ContentPanel.Body>
                                TEST
                            </ContentPanel.Body>
                        </ContentPanel>
                    </div>
                </DetailViewTemplate.Main>
            </DetailViewTemplate>
        </ApplicationTemplate>

    );
};

export default EcommerceCategories;