// Piblic components
import Login from 'com/pages/Login';
import CreateAccount from 'com/pages/CreateAccount';
import ForgotPassword from 'com/pages/ForgotPassword';
import RequestAccess from 'com/pages/RequestAccess';
import PublicAuthMessage from 'com/pages/PublicAuthMessage';
import ResetPassword from 'com/pages/ChangePassword';
import TermsAndConditions from 'com/pages/TermsAndConditions';
// 
import MainDashboard from 'com/pages/MainDashboard';
// Users
import UsersDashboard from 'com/pages/UsersDashboard';
import UsersAccountsList from 'com/pages/UsersAccountsList';
import UsersAccountsDetails from 'com/pages/UsersAccountsDetails';
import UsersAccessRequests from 'com/pages/UsersAccessRequests';
// Pages & Forms
import PagesFormsDashboard from 'com/pages/PagesFormsDashboard';
import PagesFormsPages from 'com/pages/PagesFormsPages';
import PagesFormsForms from 'com/pages/PagesFormsForms';
// Contacts
import ContactsDashboard from 'com/pages/ContactsDashboard';
import ContactsPeople from 'com/pages/ContactsPeople';
import ContactsCompanies from 'com/pages/ContactsCompanies';
// E-Commerce
import EcommerceDashboard from 'com/pages/EcommerceDashboard';
import EcommerceProducts from 'com/pages/EcommerceProducts';
import EcommerceCategories from 'com/pages/EcommerceCategories';
import EcommerceOrders from 'com/pages/EcommerceOrders';
import EcommerceWarehouse from 'com/pages/EcommerceWarehouse';
// ---------------------------------------------------
// ----------------- COMPONENT DEMOS -----------------
// ---------------------------------------------------
import DemoInput from 'com/pages/ComponentsDemo/DemoInput';
import DemoRadioGroup from 'com/pages/ComponentsDemo/DemoRadioGroup';
import DemoSwitch from 'com/pages/ComponentsDemo/DemoSwitch';
import DemoCheckbox from 'com/pages/ComponentsDemo/DemoCheckbox';
import DemoCheckboxGroup from 'com/pages/ComponentsDemo/DemoCheckboxGroup';
import DemoTextarea from 'com/pages/ComponentsDemo/DemoTextarea';
import DemoTagbox from 'com/pages/ComponentsDemo/DemoTagbox';
import DemoDropdownInput from 'com/pages/ComponentsDemo/DemoDropdownInput';
import DemoPopoutInput from 'com/pages/ComponentsDemo/DemoPopoutInput';
import DemoDropdown from 'com/pages/ComponentsDemo/DemoDropdown';
import DemoDatePicker from 'com/pages/ComponentsDemo/DemoDatePicker';
import DemoDataTable from 'com/pages/ComponentsDemo/DemoDataTable';
import DemoGalleryManager from 'com/pages/ComponentsDemo/DemoGalleryManager';
import DemoModalWindow from 'com/pages/ComponentsDemo/DemoModalWindow';
import DemoNotifications from 'com/pages/ComponentsDemo/DemoNotifications';
import DemoInputTable from 'com/pages/ComponentsDemo/DemoInputTable';
import DemoPopoutDataFilter from 'com/pages/ComponentsDemo/DemoPopoutDataFilter';
import DemoStatsCarousel from 'com/pages/ComponentsDemo/DemoStatsCarousel';
import DemoStatTableCard from 'com/pages/ComponentsDemo/DemoStatTableCard';
import DemoContentPanel from 'com/pages/ComponentsDemo/DemoContentPanel';
import DemoSectionTitle from 'com/pages/ComponentsDemo/DemoSectionTitle';
import DemoActivityCard from 'com/pages/ComponentsDemo/DemoActivityCard';
import DemoItemCard from 'com/pages/ComponentsDemo/DemoItemCard';
import DemoActionsMenu from 'com/pages/ComponentsDemo/DemoActionsMenu';
import DemoDetailsItemCard from 'com/pages/ComponentsDemo/DemoDetailsItemCard';
import DemoDataTree from 'com/pages/ComponentsDemo/DemoDataTree';

export default {
    ROOT: {
        path: '/',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: Login
    },
    CREATE_ACCOUNT: {
        path: '/create-account',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: CreateAccount
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: ForgotPassword
    },
    REQUEST_ACCESS: {
        path: '/request-access',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: RequestAccess
    },
    PUBLIC_MESSAGE: {
        path: '/auth-message/:id',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: PublicAuthMessage
    },
    CHANGE_PASSWORD: {
        path: '/reset-password/:id',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: ResetPassword
    },
    TERMS_AND_CONDITIONS: {
        path: '/tas',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: TermsAndConditions
    },
    // NOT_FOUND_404: {
    //     path: '*',
    //     groups: ['public'],
    //     acl: ['*'],
    //     exact: true,
    //     component: Login
    // },
    DASHBOARD: {
        path: '/',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: MainDashboard 
    },
    // ---------------------------------------------------
    // ---------------------- USERS ----------------------
    // ---------------------------------------------------
    USERS_DASHBOARD: {
        path: '/users',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: UsersDashboard 
    },
    USERS_ACCOUNTS_LIST: {
        path: '/users/accounts',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: UsersAccountsList
    },
    USERS_ACCOUNTS_DETAILS: {
        path: '/users/accounts/:id',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: UsersAccountsDetails
    },
    USERS_ACCESS_REQUESTS: {
        path: '/users/access-requests',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: UsersAccessRequests
    },
    // -------------------------------------------------
    // ----------------- PAGES & FORMS -----------------
    // -------------------------------------------------
    PAGESFORMS_DASHBOARD: {
        path: '/pagesforms',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: PagesFormsDashboard 
    },
    PAGESFORMS_PAGES: {
        path: '/pagesforms/pages',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: PagesFormsPages 
    },
    PAGESFORMS_FORMS: {
        path: '/pagesforms/forms',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: PagesFormsForms 
    },  
    
    // --------------------------------------------------
    // --------------------- CONTACTS -------------------
    // --------------------------------------------------
    CONTACTS_DASHBOARD: {
        path: '/contacts',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: ContactsDashboard 
    },
    CONTACTS_PEOPLE: {
        path: '/contacts/people',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: ContactsPeople 
    },
    CONTACTS_COMPANIES: {
        path: '/contacts/companies',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: ContactsCompanies 
    },

    // --------------------------------------------------
    // -------------------- E-COMMERCE ------------------
    // --------------------------------------------------
    ECOMMERCE_DASHBOARD: {
        path: '/ecommerce',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: EcommerceDashboard 
    },
    ECOMMERCE_PRODUCTS: {
        path: '/ecommerce/products',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: EcommerceProducts
    },
    ECOMMERCE_CATEGORIES: {
        path: '/ecommerce/categories',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: EcommerceCategories
    },
    ECOMMERCE_ORDERS: {
        path: '/ecommerce/orders',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: EcommerceOrders
    },
    ECOMMERCE_WAREHOUSE: {
        path: '/ecommerce/warehouse',
        groups: ['top-level'],
        acl: ['*'],
        exact: true,
        component: EcommerceWarehouse
    },

    // ---------------------------------------------------
    // ----------------- COMPONENT DEMOS -----------------
    // ---------------------------------------------------
    DEMO_INPUT: {
        path: '/demo',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoInput 
    },
    DEMO_RADIO_GROUP: {
        path: '/demo/radio-group',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoRadioGroup
    },
    DEMO_SWITCH: {
        path: '/demo/switch',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoSwitch
    },
    DEMO_CHECKBOX: {
        path: '/demo/checkbox',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoCheckbox
    },
    DEMO_CHECKBOX_GROUP: {
        path: '/demo/checkbox-group',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoCheckboxGroup
    },
    DEMO_TEXTAREA: {
        path: '/demo/textarea',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoTextarea
    },
    DEMO_TAGBOX: {
        path: '/demo/tagbox',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoTagbox
    },
    DEMO_DROPDOWN_INPUT: {
        path: '/demo/dropdown-input',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDropdownInput
    },
    DEMO_POPOUT_INPUT: {
        path: '/demo/popout-input',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoPopoutInput
    },
    DEMO_DROPDOWN: {
        path: '/demo/dropdown',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDropdown
    },
    DEMO_DATEPICKER: {
        path: '/demo/datepicker',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDatePicker
    },
    DEMO_DATA_TABLE: {
        path: '/demo/data-table',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDataTable
    },
    DEMO_GALLERY_MANAGER: {
        path: '/demo/gallery-manager',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoGalleryManager
    },
    DEMO_MODAL: {
        path: '/demo/modal',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoModalWindow
    },
    DEMO_NOTIFICATIONS: {
        path: '/demo/notifications',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoNotifications
    },
    DEMO_INPUT_TABLE: {
        path: '/demo/input-table',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoInputTable
    },
    DEMO_POPOUT_DATA_FILTER: {
        path: '/demo/popout-data-filter',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoPopoutDataFilter
    },
    DEMO_STATS: {
        path: '/demo/stats',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoStatsCarousel
    },
    DEMO_STATS_TABLE_CARD: {
        path: '/demo/stat-table-card',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoStatTableCard
    },
    DEMO_DETAIL_CONTENT_PANELS: {
        path: '/demo/content-panel',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoContentPanel
    },
    DEMO_SECTION_TITLE: {
        path: '/demo/section-title',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoSectionTitle
    },
    DEMO_ACTIVITY_CARD: {
        path: '/demo/activity-card',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoActivityCard
    },
    DEMO_ITEM_CARD: {
        path: '/demo/item-card',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoItemCard
    },
    DEMO_ACTIONS_MENU: {
        path: '/demo/actions-menu',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoActionsMenu
    },
    DEMO_DETAILS_ITEM_CARD: {
        path: '/demo/details-item-card',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDetailsItemCard
    },
    DEMO_DATA_TREE: {
        path: '/demo/data-tree',
        groups: ['public'],
        acl: ['*'],
        exact: true,
        component: DemoDataTree
    }
}