// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './style.css';

const Radio = (props) => {
	let setDisabled = props.disabled ? 'disabled' : '';

	return (
		<label className={`radio ${setDisabled}`}>
			<span className='radio__label'>{props.label}</span>
			<input
				className='radio__input'
				type="radio"
				name={props.name}
				value={props.value}
				disabled={props.disabled}
				checked={props.checked}
				onChange={props.onChange}
			/>
			<span className="radio__span"></span>
		</label>
	);
};

Radio.propTypes = {
	name: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
	onChange: PropTypes.func
};

Radio.defaultProps = {
	name: '',
	value: '',
	disabled: false,
	checked: false,
	onChange: () => { }
};

export default Radio;