import React from 'react';

import PublicTemplate from 'com/templates/PublicTemplate';

import LoginBox from 'com/templates/LoginBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';

import './style.css';

const CreateAccount = (props) => {
    return (
        <PublicTemplate>
            <LoginBox title="Welcome" showNavigation={true}>
                <div className="create-account-row">
                    <div className="col">
                        <Input type="text" label="First name" />
                    </div>
                    <div className="col">
                        <Input type="text" label="Last name" />
                    </div>
                </div>
                <Input type="email" label="E-mail" />
                <Input type="password" label="Password" />
                <Input type="password" label="Re-type password" />
                <div className="create-account-footer">
                    <Button>Create Account</Button>
                </div>
            </LoginBox>
        </PublicTemplate>
    );
};

export default CreateAccount;