import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Tagbox from 'com/ui/Tagbox';

const DemoTagbox = (props) => {

    const categories = ['fruits', 'vegetables'];

    const [tagbox, setTagbox] = useState(categories);

    const changeTags = (e) => {
        console.log(e);
        setTagbox(e.target.value);
    };

    return (
        <ComponentDemoTemplate>
            <>
                <Tagbox
                    label="Categories"
                    value={tagbox}
                    onChange={changeTags}
                    disabled={false}
                />
                <hr/>
                <Tagbox
                    label="Categories"
                    value={tagbox}
                    onChange={changeTags}
                    disabled={true}
                />
                <hr />
                <Tagbox
                    label="Categories"
                    value={tagbox}
                    onChange={changeTags}
                    error="Error has happened!"
                    disabled={false}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoTagbox;