import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './ducks/user';
import notificationsReducer from './ducks/notifications';
import notificationToastsReducer from './ducks/notification_toasts';
import authReducer from './ducks/auth';
import statsReducer from './ducks/carousel_stats';

const reducers = combineReducers({
    auth: authReducer.reducer,
    user: userReducer.reducer,
    notifications: notificationsReducer.reducer,
    notification_toasts: notificationToastsReducer.reducer,
    carousel_stats: statsReducer.reducer,
});
const middleware = applyMiddleware(thunk);
const store = createStore(reducers, middleware);

export default store;