import React, {useState} from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import PopoutDataFilter from 'com/widgets/PopuoutDataFilter';

const DemoPopoutDataFilter = () => {

    const initFilter = {
        active: [
            {condition: 'list_not_equals', v1: '0', v2: ''},
        ],
        created: [
            {condition: 'date_not_equals', v1: '', v2: ''},
            {condition: 'date_between', v1: '', v2: ''}
        ],
        order_id: [
            { condition: 'string_contains', v1: 'sdf', v2: undefined },
            { condition: 'string_equals', v1: 'ffffffff', v2: undefined },
            { condition: 'string_equals', v1: 'asdf', v2: undefined }
        ]
    };

    const [filter, setFilter] = useState(initFilter);

    const [tableHeaders, setTableHeaders] = useState([
        { title: 'Order ID', field: 'order_id', type: 'string', sort: 'none', sortable: true },
        { title: 'Buyer', field: 'buyer', type: 'string', sort: 'none', sortable: true },
        { title: 'No. Items', field: 'no_items', type: 'number', sort: 'none', sortable: true },
        { title: 'Total', field: 'total', type: 'number', sort: 'none', sortable: true },
        { title: 'Tax', field: 'tax', type: 'number', sort: 'none', sortable: true },
        { title: 'Revenue', field: 'revenue', type: 'number', sort: 'none', sortable: true },
        { title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
        { title: 'Delivery Scheduled', field: 'delivery_scheduled', type: 'date', sort: 'none', sortable: true },
        { title: 'Created', field: 'created', type: 'date', sort: 'none', sortable: true },
        { title: 'Active', field: 'active', type: 'list', sort: 'none', sortable: true }
    ]);

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    return (
        <ComponentDemoTemplate>
            <PopoutDataFilter 
                dataTypes={tableHeaders}
                filterOptions={data}
                tableName="orders"
                onChange={setFilter}
            />
        </ComponentDemoTemplate>
    )
};

export default DemoPopoutDataFilter;