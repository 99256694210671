//vendor imports
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import oblax from 'services/oblax';
import { AccountRequest } from '@oblax/sdk-js';
//components
import PublicTemplate from 'com/templates/PublicTemplate';
import LoginBox from 'com/templates/LoginBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Error from 'com/ui/Error';
//services 
import L11n from 'services/l11n';
//constants
import { REQUEST_ACCESS_SCHEMA } from 'schemas/requestAccessSchema';
//styles
import './style.css';

const RequestAccess = (props) => {
    const history = useHistory();
    
    const initCredentials = {
        first_name: '',
        last_name: '',
		email: ''
	};

    const initErrors = {
        first_name: '',
        last_name: '',
        email: ''
    };

    const [credentials, setCredentials] = useState(initCredentials);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(initErrors);
    const [xhttpError, setXhttpError] = useState('');

    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value});
    };

    const onSubmit = async () => {
        if(!validateForm() || showLoader) return;
        setShowLoader(true);
        try {
            let data = {
                first_name: credentials.first_name,
                last_name: credentials.last_name,
                email: credentials.email
            };
            let ac = new AccountRequest();
            ac.loadFromJSON(data);
            let res = await oblax.auth.requestAccess(ac);
            if(res.ok) {
                history.push("/auth-message/request-access");
                setCredentials(initCredentials);
            }

        } catch(err) {
            if(process.env.REACT_APP_ENV !== 'prod') {
                console.log(err);
            }
            setXhttpError(L11n.t(`ERROR_${err.data.type}`));
            setShowLoader(false);
        }
    };

    const validateForm = () => {
		setErrors(initErrors);
		let validate = oblax.validator.validate(REQUEST_ACCESS_SCHEMA, credentials);
		if (!validate) {
			let errs = oblax.validator.getValidationErrors();
            console.log('errors', errs);
			for (let e of errs) {
				setErrors(state => { return { ...state, [e.key]: L11n.t(`ERROR_${e.code}`) } });
			}
		}
		return validate;
	};

    const removeError = () => {
        setXhttpError('');
    };

    return (
        <PublicTemplate>
            <LoginBox title="Welcome" showNavigation={true}> 
                <p className="request-access-text">Please enter your credentials to request access.</p>
                <div className="create-account-row">
                    <div className="col">
                        <Input 
                            name="first_name" 
                            type="text" 
                            label="First name" 
                            value={credentials.first_name} 
                            onChange={onChange} 
                            error={errors.first_name} 
                        />
                    </div>
                    <div className="col">
                        <Input 
                            name="last_name" 
                            type="text" 
                            label="Last name" 
                            value={credentials.last_name} 
                            onChange={onChange} 
                            error={errors.last_name}
                        />
                    </div>
                </div>
                <Input 
                    name="email" 
                    type="email" 
                    label="Email address" 
                    value={credentials.email} 
                    onChange={onChange} 
                    error={errors.email}
                />
                <div className="request-access-footer">
                    <Button showLoader={showLoader} onClick={onSubmit}>Send request</Button>
                </div>
            </LoginBox>
            {xhttpError ? <Error message={xhttpError} onClick={removeError} /> : null}
        </PublicTemplate>
    );
};

export default RequestAccess;