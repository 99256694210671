import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import CheckboxGroup from 'com/ui/Checkbox/CheckboxGroup'; 

const DemoCheckboxGroup = (props) => {

    const beverages = [
        { value: 'beer', label: 'Beer', selected: true },
        { value: 'wine', label: 'Wine', selected: false },
        { value: 'tea', label: 'Tea', selected: true },
        { value: 'coffee', label: 'Coffee', selected: false },
        { value: 'juice', label: 'Juice', selected: false },
        { value: 'liquor', label: 'Liquor', selected: true }
    ];

    const [checkboxGroup, setCheckboxGroup] = useState(beverages);

    return (
        <ComponentDemoTemplate>
            <>
                <CheckboxGroup
                    name="drinks"
                    values={checkboxGroup}
                    disabled={false}
                    onChange={setCheckboxGroup}
                />
                <hr/>
                <CheckboxGroup
                    name="drinks"
                    values={checkboxGroup}
                    disabled={true}
                    onChange={setCheckboxGroup}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoCheckboxGroup;