// vendor imports 
import React, { useState, useEffect } from "react";
import oblax from 'services/oblax';

// components
import StatTableCard from "com/widgets/StatTableCard";
// import Avatar from "com/widgets/Avatar";

// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

// styles
import './style.css';

const StatsOutOfStock = (props) => {

	const [loading, setLoading] = useState(true);
	const [productsData, setProductsData] = useState([]);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			try {
				let res = await oblax.stats.outOfStockIndex();
				if (res.ok) {
					setLoading(false);
					setProductsData(res.data);
				}
			} catch (err) {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log(err);
				}
				sendNotification({ type: 'error', title: l11n.t('ERROR_GET_OUT_OF_STOCK_INDEX') });
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return (
		<StatTableCard title="Out of stock index" icon="indeterminate-circle-fill ">
			{
				loading ?
					<StatTableCard.Loader cols={2} rows={5} /> :
					<TableData data={productsData} />
			}
		</StatTableCard>
	);
};

const TableData = (props) => {

	const setProgressColors = (percent) => {
		if (percent < 25) {
			return "red";
		} else if (percent >= 25 && percent < 50) {
			return "lightOrange";
		} else if (percent >= 50 && percent < 75) {
			return "yellow";
		} else if (percent >= 75) {
			return "green";
		}
	};

	return (
		props.data.length > 0 ?
			props.data.map((p, i) => {
				const src = Array.isArray(p.product_images) ? `${process.env.REACT_APP_MINIO_CDN}/products/${p.product_images.filter(i => i.cover)[0].path.replaceAll(' ', '%20')}` : null;
				let perc = Math.round((p.products_imported / p.products_in_stock) * 100);
				let progressColor = setProgressColors(perc);
				return (
					<tr key={i} className="stat-tables__container-clean">
						<td className="stat-tables__container-clean">
							<div className="product-data__wrapper">
								<div className="product-data__wrapper-picture">
									<img src={src} alt="" />
								</div>
								<div className="product-data__details">
									<span className="product-data__details-name">{p.product_name}</span>
									<span className="product-data__details-vendor">{p.manufacturer_name}</span>
								</div>
							</div>
						</td>
						<td>
							<div className="product-data__amount-wrapper">
								<div className="product-data__amount-stock">
									<span>{p.products_imported} of </span>
									<span>{p.products_in_stock} left</span>
								</div>
								<progress
									className={`product-data__amount-progress ${progressColor}`}
									value={`${p.products_imported}`}
									max={`${p.products_in_stock}`}
								>
								</progress>
							</div>
						</td>
					</tr>
				);
			}) :
			<StatTableCard.NoData colspan={1} />
	);
};

export default StatsOutOfStock;