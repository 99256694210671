import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'com/widgets/Spinner';

import './style.css';

const Button = (props) => {
    return (
        <button 
            className={`button ${props.type} ${props.customClassName}`}
            onClick={props.onClick}
        >
            {
                props.showLoader ? 
                <Spinner /> :
                <>
                    {props.icon ? <img src={props.icon} alt="" /> : null}
                    <span className="button__label">
                        {props.children}
                    </span>
                    {props.badge ? <span className="button__badge">{props.badge}</span> : null}
                </>
            }
        </button>
    )
};

Button.propTypes = {
    type: PropTypes.oneOf(['ghost', 'primary', 'secondary']),
    onClick: PropTypes.func,
    showLoader: PropTypes.bool,
    icon: PropTypes.node,
    badge: PropTypes.number,
    customClassName: PropTypes.string
};

Button.defaultProps = {
    type: 'primary',
    onClick: () => {},
    showLoader: false,
    icon: null,
    badge: 0,
    customClassName: ''
};

export default Button;