import React from 'react';
// templates
import ComponentDemoTemplate from "com/templates/ComponentDemoTemplate";
// com
import ActivityCard from 'com/widgets/ActivityCard';

const DemoActivityCard = () => {
    const date = new Date();

    return (
        <ComponentDemoTemplate>
            <ActivityCard 
                icon="home-gear" 
                statusColor="blue" 
                status="User" 
                action="User Details" 
                // actionLocation="User Details" 
                date={date}
            >
                <ActivityCard.Body>
                    <ActivityCard.Left>
                        Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, 
                        and a search for 'lorem ipsum'.
                    </ActivityCard.Left>
                    <ActivityCard.Right>
                        Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, 
                        and a search for 'lorem ipsum'.
                    </ActivityCard.Right>
                </ActivityCard.Body>
            </ActivityCard>
            <ActivityCard 
                icon="cloud"
                statusColor="purple"
                status="Finished"
                action="Go to demo"
                actionLocation="/demo" 
                date={date}
            >
                <ActivityCard.Body>
                    <ActivityCard.Left>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                        and more recently with desktop publishing software like Aldus PageMaker.
                    </ActivityCard.Left>
                    <ActivityCard.Right>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, 
                        and more recently with desktop publishing software like Aldus PageMaker.
                    </ActivityCard.Right>
                </ActivityCard.Body>
            </ActivityCard>
        </ComponentDemoTemplate>
    );
}

export default DemoActivityCard;