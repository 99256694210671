import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'com/ui/Icon';

import d from 'services/util/date';

import NO_NOTIFICATIONS from 'assets/images/no-notifications.svg';
import ICON_UPGRADE from 'assets/images/icons/notification-upgrade.svg';
import ICON_FEATURES from 'assets/images/icons/notification-features.svg';
import ICON_BILLING from 'assets/images/icons/notification-billing.svg';
import ICON_USERS from 'assets/images/icons/notification-users.svg';


const icons = {
    upgrade: ICON_UPGRADE,
    features: ICON_FEATURES,
    billing: ICON_BILLING,
    users: ICON_USERS
};

const Menu = (props) => {
    return (
        <div className="notifications-menu-dropdown">
            {
                props.items.length > 0
                ? props.items.map((v, i) => <Item data={v} key={i} />)
                : <Empty />
            }
            <div className="notification-center-link">
                <Icon name="delete-bin" color="darkgrey" size="smaller" type="line"/>
                <button>Clear all notifications</button>
            </div>
        </div>
    );
};

const Empty = (props) => {
    return (
        <div className="notifications-menu-empty">
            <img src={NO_NOTIFICATIONS} alt="" />
            <span>You have read all your notifications</span>
        </div>
    );
};

const Item = (props) => {
    return (
        <Link to={props.data.link} className="notification-menu-item">
            <img src={icons[props.data.icon]} alt="" />
            <div>
                <span className="notification-menu-item__title">{props.data.title}</span>
                <span className="notification-menu-item__datetime">{d.dateLong(props.data.timestamp)}</span>
            </div>
            <div className="notification-menu-item__clear">
                <span className="notification-menu-item__clear__icon">&times;</span>
                <span className="notification-menu-item__clear__text">clear</span>
            </div>
        </Link>
    );
};

export default Menu;