export default [
    {
        id: 'ma123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'kj123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'os123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'sd123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'et123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'we123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'mq123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'mp123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'xa123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'sg123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'op123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'op123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'op123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'op123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
    {
        id: 'op123456789',
        name: 'Nike',
        avatar: '',
        contacts: 5,
        city: 'Skopje',
        country: 'North Macedonia', 
        category: 'company',
        registration_date: new Date(), 
    },
];