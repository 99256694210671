import { useState, useEffect } from "react";
import dataFilters from 'services/util/dataFilters';
import oblax from 'services/oblax';

const useDataTable = (filterConfig, initTableHeaders, fetchCall) => {
    const pagingInit = { total_rows: 0, page: 1, limit: 10 };

    const [paging, setPaging] = useState(pagingInit);
    const [dataFilter, setDataFilter] = useState(filterConfig);
    const [usersData, setUsersData] = useState([]);
    const [tableHeaders, setTableHeaders] = useState(initTableHeaders);

    useEffect(() => {
        (async () => {
            try {
                const [fetchClass, fetchMethod] = fetchCall.split('.');
                let data = await oblax[fetchClass][fetchMethod](dataFilter);
                setUsersData(data.data.data);
                setPaging({...paging, total_rows: data.data.count});
            } catch(err) {
                console.log(err);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const [fetchClass, fetchMethod] = fetchCall.split('.');
                let data = await oblax[fetchClass][fetchMethod](dataFilter);
                setUsersData(data.data.data);
                setPaging({...paging, total_rows: data.data.count});
            } catch(err) {
                console.log(err);
            }
        })();
    }, [dataFilter]);

    const pagingChange = (p) => {
        setPaging({ ...paging, page: p });
        setDataFilter({...dataFilter, offset: ((p - 1) * dataFilter.limit)});
    };

    const columnSort = async (field, type) => {
        setTableHeaders(tableHeaders.map(item => {
            if (item.field === field && item.sortable) {
                item.sort = item.sort === 'up' ? 'down' : 'up';
                setDataFilter({
                    ...dataFilter,
                    order: {dir: item.sort === 'up' ? 'asc' : 'desc', field}
                });
            } else {
                item.sort = 'none';
            }
            return item;
        }));
    };

    const filterApply = (data) => {
        let qsData = dataFilters.convertToQSFormat(data);
        setDataFilter({
            ...dataFilter,
            filters: qsData
        });
    };

    return [
        usersData,
        tableHeaders,
        paging,
        pagingChange,
        columnSort,
        filterApply
    ];
};

export default useDataTable;