const initialState = {
    accountsStats: {
        registrations: [],
        lastRegistrations: [],
        socialLogins: [],
        activeUsers: 0,
        inactiveUsers: 0,
        topLocations: [],
    },
    productsStats: {
        domesticProducts: 0,
        productsWithPriceOverThousand: 0,
        totalActiveProducts: 0,
    },
    ordersStats: {
        pendingOrders: 0,
        deliveredOrders: 0,
        totalOrders: 0,
    }
};

const ACCOUNTS_REGISGRATION_STATS = 'ACCOUNTS_REGISTRATION_STATS';
const LAST_REGISTRATIONS_STATS = 'LAST_REGISTRATIONS_STATS';
const SOCIAL_LOGINS = 'SOCIAL_LOGINS';
const ACTIVE_USERS = 'ACTIVE_USERS';
const INACTIVE_USERS = 'INACTIVE_USERS';
const TOP_LOCATIONS = 'TOP_LOCATIONS';

const DOMESTIC_PRODUCTS = 'DOMESTIC_PRODUCTS';
const PRODUCTS_WITH_PRICE_OVER_THOUSAND = 'PRODUCTS_WITH_PRICE_OVER_THOUSAND';
const TOTAL_ACTIVE_PRODUCTS = 'TOTAL_ACTIVE_PRODUCTS';

const PENDING_ORDERS = 'PENDING_ORDERS';
const DELIVERED_ORDERS = 'DELIVERED_ORDERS';
const TOTAL_ORDERS = 'TOTAL_ORDERS';

export const setAccountsRegistrationStats = (data) => {
    return {
        type: ACCOUNTS_REGISGRATION_STATS,
        payload: data
    };
};

export const setLastRegistrationsStats = (data) => {
    return {
        type: LAST_REGISTRATIONS_STATS,
        payload: data
    };
};

export const setSocialLogins = (data) => {
    return {
        type: SOCIAL_LOGINS,
        payload: data
    };
};

export const setActiveUsers = (data) => {
    return {
        type: ACTIVE_USERS,
        payload: data
    };
};

export const setInactiveUsers = (data) => {
    return {
        type: INACTIVE_USERS,
        payload: data
    };
};

export const setTopLocations = (data) => {
    return {
        type: TOP_LOCATIONS,
        payload: data
    };
};

export const setDomesticProducts = (data) => {
    return {
        type: DOMESTIC_PRODUCTS,
        payload: data
    };
};

export const setProductsWithPriceOverThousand = (data) => {
    return {
        type: PRODUCTS_WITH_PRICE_OVER_THOUSAND,
        payload: data
    };
};

export const setTotalActiveProducts = (data) => {
    return {
        type: TOTAL_ACTIVE_PRODUCTS,
        payload: data
    };
};

export const setPendingOrders = (data) => {
    return {
        type: PENDING_ORDERS,
        payload: data
    };
};

export const setDeliveredOrders = (data) => {
    return {
        type: DELIVERED_ORDERS,
        payload: data
    };
};

export const setTotalOrders = (data) => {
    return {
        type: TOTAL_ORDERS,
        payload: data
    };
};

const statsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNTS_REGISGRATION_STATS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    registrations: action.payload
                }
            };
        case LAST_REGISTRATIONS_STATS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    lastRegistrations: action.payload
                }
            };
        case SOCIAL_LOGINS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    socialLogins: action.payload
                }
            };
        case ACTIVE_USERS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    activeUsers: action.payload
                }
            };
        case INACTIVE_USERS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    inactiveUsers: action.payload
                }
            };
        case TOP_LOCATIONS:
            return {
                ...state,
                accountsStats: {
                    ...state.accountsStats,
                    topLocations: action.payload
                }
            };
        case DOMESTIC_PRODUCTS:
            return {
                ...state,
                productsStats: {
                    ...state.productsStats,
                    domesticProducts: action.payload
                }
            };
        case PRODUCTS_WITH_PRICE_OVER_THOUSAND:
            return {
                ...state,
                productsStats: {
                    ...state.productsStats,
                    productsWithPriceOverThousand: action.payload
                }
            };
        case TOTAL_ACTIVE_PRODUCTS:
            return {
                ...state,
                productsStats: {
                    ...state.productsStats,
                    totalActiveProducts: action.payload
                }
            };
        case PENDING_ORDERS:
            return {
                ...state,
                ordersStats: {
                    ...state.ordersStats,
                    pendingOrders: action.payload
                }
            };
        case DELIVERED_ORDERS:
            return {
                ...state,
                ordersStats: {
                    ...state.ordersStats,
                    deliveredOrders: action.payload
                }
            };
        case TOTAL_ORDERS:
            return {
                ...state,
                ordersStats: {
                    ...state.ordersStats,
                    totalOrders: action.payload
                }
            };
        default:
            return state;
    };
};

export default {
    setAccountsRegistrationStats,
    setLastRegistrationsStats,
    setSocialLogins,
    setActiveUsers,
    setInactiveUsers,
    setTopLocations,
    setDomesticProducts,
    setProductsWithPriceOverThousand,
    setTotalActiveProducts,
    setPendingOrders,
    setDeliveredOrders,
    setTotalOrders,
    reducer: statsReducer
};