// vendor imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import Input from 'com/ui/Input';
import Dropdown from 'com/ui/Dropdown';
import DatePicker from 'com/ui/DatePicker';
import Icon from 'com/ui/Icon';
// styles
import './style.css';

const InputTable = (props) => {

	const generateInitState = () => {
		let state = {};
		let field = {};
		props.columns.forEach(column => {
			field.type = column.type;
			if (column.type === 'dropdown') {
				field.value = props.values[0].value;
			}
			state[column.name] = fieldInitValue(field);
			field = {};
		});
		return state;
	};

	const fieldInitValue = (options) => {
		switch (options.type) {
			case 'number':
				return 0;
			case 'string':
				return '';
			case 'dropdown':
				return options.value;
			case 'datepicker':
				return new Date();
			default:
				return '';
		}
	};

	const initData = generateInitState();
	const [data, setData] = useState(initData);
	const [showForm, setShowForm] = useState(false);

	const onShowForm = () => {
		setShowForm(true);
	};

	const onChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.type === 'number' ? Number(event.target.value) : event.target.value
		});
	};

	const onAddRecord = () => {
		props.addRecord(data);
		resetState();
	};

	const onCancel = () => {
		resetState();
	};

	const resetState = () => {
		setShowForm(false);
		setData(initData);
	};

	const generateCustomField = (type, name, value) => {
		switch (type) {
			case 'number':
				return <Input customClassName='input-table-field' name={name} type='number' value={Number(value).toString()} onChange={onChange} />;
			case 'string':
				return <Input customClassName='input-table-field' name={name} value={value} onChange={onChange} />;
			case 'dropdown':
				return <Dropdown customClassName='input-table-field' name={name} options={props.values} value={value} onChange={onChange} />;
			case 'datepicker':
				return <DatePicker customClassName='input-table-field-dp' name={name} value={value} onChange={onChange} />;
			default:
				return <Input customClassName='input-table-field' name={name} value={value} onChange={onChange} />;
		}
	};

	return (
		<table cellPadding='0' cellSpacing='0' className='input-table'>
			<thead>
				<tr>
					{props.columns.map((column, index) => {
						return <th key={index}>{column.label}</th>;
					})}
					<th></th>
				</tr>
			</thead>
			<tbody>
				{showForm
					?
					<tr className="input-table__form-row">
						{props.columns.map((column, index) => {
							return <td key={`${index}_${column.name}`}>{generateCustomField(column.type, column.name, data[column.name])}</td>
						})}
						<td className="input-table__form-row__actions">
							<div className="input-table__form-row__actions__wrapper">
								<button className="input-table__form-row__actions__submit" onClick={onAddRecord}>+</button>
								<button className="input-table__form-row__actions__cancel" onClick={onCancel}>&times;</button>
							</div>
						</td>
					</tr>
					:
					<tr>
						<td colSpan={props.columns.length + 1} className="button-row">
							<button className="button-row__add-button" onClick={onShowForm}>
								<Icon name="add-circle" size="large" color="white" />
								Add Row
							</button>
						</td>
					</tr>

				}
				{props.children}
			</tbody>
		</table>
	);
};

InputTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object),
	values: PropTypes.arrayOf(PropTypes.object),
	addRecord: PropTypes.func
};

InputTable.defaultProps = {
	columns: [],
	values: [],
	addRecord: () => { }
};

const Cell = (props) => {
	return (
		<td>
			<div className='input-table-cell'>
				{props.children}
			</div>
		</td>
	);
};

const Row = (props) => {
	const handleOnClick = () => {
		props.onClick(props.index);
	};

	return (
		<tr className={`input-table-row ${props.active ? "input-table-row-active" : ""}`} onClick={handleOnClick}>
			{props.children}
		</tr>
	);
};

Row.propTypes = {
	index: PropTypes.number,
	active: PropTypes.bool,
	onClick: PropTypes.func
};

Row.defaultProps = {
	index: 0,
	active: false,
	onClick: () => { }
}

const Remove = (props) => {
	const onClick = (e) => {
		e.stopPropagation();
		props.action(props.index);
	};

	return (
		<td>
			<div className='input-table-cell input-table-cell-action'>
				<button onClick={onClick} className="input-table-cell-action__transparent-button">
					<Icon name="delete-bin" type="line" size="medium" color={props.active ? "white" : "grey"} />
				</button>
			</div>
		</td>
	);
};

Remove.propTypes = {
	index: PropTypes.number,
	active: PropTypes.bool,
	action: PropTypes.func
};

Remove.defaultProps = {
	index: 0,
	active: false,
	action: () => { }
};

InputTable.Cell = Cell;
InputTable.Row = Row;
InputTable.Remove = Remove;

export default InputTable;