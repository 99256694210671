import React, { useEffect, useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import DatePicker from '../../ui/DatePicker';

const DemoDatePicker = (props) => {

    const [datepicker, setDatepicker] = useState(new Date('2022-10-29'));
    const [valueFrom, setValueFrom] = useState();
    const [valueTo, setValueTo] = useState();
    const dateFormat1 = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' };
    const dateFormat2 = { year: 'numeric', month: '2-digit', day: 'numeric' };
    const dateFormat3 = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const dateFormat4 = { year: 'numeric', month: 'short', day: 'numeric' };
    
    const setDateRange = (dates) => {
        console.log(dates.target.value);
        setValueFrom(dates.target.value.from);
        setValueTo(dates.target.value.to);
    };

    const datepickerChange = (e) => {
        console.log(e);
        setDatepicker(e.target.value);
    };

    return (
        <ComponentDemoTemplate>
            <>
                <DatePicker
                    label={"Available from"}
                    value={datepicker}
                    onChange={datepickerChange}
                    dateFormat={dateFormat1}
                    // mondayFirst={false}
                    locale="en-US"
                />
                <hr/>
                <DatePicker
                    label={"Available from (range from - to)"}
                    // value={datepicker}
                    onChange={setDateRange}
                    dateFormat={dateFormat4}
                    mondayFirst={true}
                    locale="en-US"
                    type="range"
                    valueFrom={valueFrom}
                    valueTo={valueTo}
                />
                <hr />
                <DatePicker
                    label={"Available from"}
                    value={datepicker}
                    onChange={datepickerChange}
                    dateFormat={dateFormat2}
                    mondayFirst={true}
                    locale="mk-MK"
                    disabled={true}
                />
                <hr />
                <DatePicker
                    label={"Available from"}
                    value={datepicker}
                    onChange={datepickerChange}
                    dateFormat={dateFormat3}
                    mondayFirst={true}
                    locale="de-DE"
                    error="Some error"
                />
                <hr />
                <DatePicker
                    value={datepicker}
                    onChange={datepickerChange}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoDatePicker;