import React, {useContext, useEffect, useState} from 'react';

import Button from 'com/ui/Button';
import Dropdown from 'com/ui/Dropdown';
import Input from 'com/ui/Input';
import DatePicker from 'com/ui/DatePicker';
import Tagbox from 'com/ui/Tagbox';

import oblax from 'services/oblax';
import {DataFilter} from '@oblax/sdk-js';

import {FilterContext} from './context';

import ICON_SAVE from 'assets/images/icons/btn-save-white.svg';
import ICON_BACK from 'assets/images/icons/btn-back-orange.svg';
import ICON_TRASH from 'assets/images/icons/trash-grey.svg';

const FilterBuilder = (props) => {
    const filterCtx = useContext(FilterContext);
    const [selectedField, setSelectedField] = useState({field: '', type: ''});
    /*
    {
        order_id: [
            {condition: 'number_greater_than', v1: '', v2: ''},
            {condition: 'number_less_than', v1: '', v2: ''},
        ],
        buyer: [
            {condition: 'number_greater_than', v1: '', v2: ''},
            {condition: 'number_less_than', v1: '', v2: ''},
        ]
    }
    */

    useEffect(() => {
        setSelectedField(filterCtx.dataTypes[0]);
    }, []);

    useEffect(() => {
        if (!filterCtx.filter[selectedField.field] && selectedField.type) {
            filterCtx.setFilter(state => {
                return {
                    ...state,
                    [selectedField.field]: []
                }
            });
        }
    }, [selectedField]);

    const filterOptions = {
        number: [
            {label: 'Greater Than', value: 'number_greater_than'},
            {label: 'Less Than', value: 'number_less_than'},
            {label: 'Equals', value: 'number_equals'},
            {label: 'Does Not Equal', value: 'number_not_equals'},
            {label: 'Between', value: 'number_between'},
            {label: 'One Of', value: 'number_one_of'},
        ],
        string: [
            {label: 'Equals', value: 'string_equals'},
            {label: 'Does Not Equals', value: 'string_not_equals'},
            {label: 'Contains', value: 'string_contains'},
            {label: 'Begins With', value: 'string_begins_with'},
            {label: 'Ends With', value: 'string_ends_with'},
            {label: 'One Of', value: 'string_one_of'},
        ],
        date: [
            {label: 'Equals', value: 'date_equals'},
            {label: 'Does Not Equals', value: 'date_not_equals'},
            {label: 'After', value: 'date_greater_than'},
            {label: 'Before', value: 'date_less_than'},
            {label: 'Between', value: 'date_between'},
        ],
        list: [
            {label: 'Equals', value: 'list_equals'},
            {label: 'Does not equal', value: 'list_not_equals'},
        ]
    };

    const fieldGenerator = (c, v1, v2, order) => {
        switch (c) {
            case 'number_greater_than':
            case 'number_less_than':
            case 'number_equals':
            case 'number_not_equals':
                return (<>
                    <Input 
                        type="number" 
                        name={`v1__${order}__${c}`} 
                        customClassName="fields-editor__row__two-thirds" 
                        label="Value" 
                        value={v1} 
                        onChange={inputChange}
                    />
                </>);
            case 'number_between': 
                return (<>
                    <Input 
                        type="number" 
                        name={`v1__${order}__${c}`} 
                        customClassName="fields-editor__row__third" 
                        label="Value From" 
                        value={v1} 
                        onChange={inputChange}
                    />
                    <Input 
                        type="number" 
                        name={`v2__${order}__${c}`} 
                        customClassName="fields-editor__row__third" 
                        label="Value To" 
                        value={v2} 
                        onChange={inputChange}
                    />
                </>);
            case 'number_one_of':
                return (<>
                    <Tagbox 
                        type="number" 
                        name={`v1__${order}__${c}`} 
                        customClassName="fields-editor__row__two-thirds" 
                        label="Values" 
                        value={v1} 
                        onChange={inputChange}
                    />
                </>);
            case 'string_equals':
            case 'string_not_equals':
            case 'string_contains':
            case 'string_begins_with':
            case 'string_ends_with':
                return (<>
                    <Input 
                        type="text" 
                        name={`v1__${order}__${c}`} 
                        customClassName="fields-editor__row__two-thirds" 
                        label="Value" 
                        value={v1} 
                        onChange={inputChange}
                    />
                </>);
            case 'string_one_of':
                return (<>
                    <Tagbox 
                        type="string" 
                        name={`v1__${order}__${c}`} 
                        customClassName="fields-editor__row__two-thirds" 
                        label="Values" 
                        value={v1} 
                        onChange={inputChange} 
                    />
                </>);
            case 'date_equals':
            case 'date_not_equals':
            case 'date_greater_than':
            case 'date_less_than':
                return (<>
                    <DatePicker 
                        type="date" 
                        name={`v1__${order}__${c}`} 
                        label="Value" 
                        value={v1} 
                        onChange={inputChange}
                    />
                </>);
            case 'date_between':
                return (<>
                    <DatePicker 
                        type="range" 
                        name={`v1__${order}__${c}`} 
                        label="Range" 
                        valueFrom={v1}
                        valueTo={v2}
                        onChange={doubleInputChange}
                    />
                </>);
            case 'list_equals':
            case 'list_not_equals':
                let options = filterCtx.filterOptions[selectedField.field] ? filterCtx.filterOptions[selectedField.field] : [];
                return (<>
                    <Dropdown 
                        name={`v1__${order}__${c}`} 
                        label="Options"
                        value={v1}
                        onChange={inputChange}
                        options={options}
                        customClassName="fields-editor__row__two-thirds" 
                    />
                </>);
        }
    };

    const dataFieldClick = (e) => {
        setSelectedField(e.target.dataset);
    };

    const onConditionChange = (e) => {
        let order = Number(e.target.name.split('__')[1]);

        filterCtx.setFilter(state => {
            return {
                ...state,
                [selectedField.field]: state[selectedField.field].map((sf, i) => {
                    let { v1, v2 } = defaultNull(e.target.value);
                    if(order === i) {
                        sf.condition = e.target.value;
                        sf.v1 = v1;
                        sf.v2 = v2;
                    }
                    return sf;
                })
            }
        });
    };

    const addFilterRow = () => {
        let { v1, v2 } = defaultNull(filterOptions[selectedField.type][0].value);
        filterCtx.setFilter(state => {
            return {
                ...state,
                [selectedField.field]: [
                    ...(state[selectedField.field] ? state[selectedField.field] : []),
                    {
                        condition: filterOptions[selectedField.type][0].value, 
                        v1: v1,
                        v2: v2,
                    }
                ]
            }
        });
    };

    const defaultNull = (type) => {
        switch(type) {
            case 'number_greater_than':
            case 'number_less_than':
            case 'number_equals':
            case 'number_not_equals':
            case 'number_between':
                return {v1: 0, v2: 0};
            case 'string_equals':
            case 'string_not_equals':
            case 'string_contains':
            case 'string_begins_with':
            case 'string_ends_with':
                return {v1: '', v2: undefined};
            case 'number_one_of':
            case 'string_one_of':
                return {v1: [], v2: undefined};
            case 'date_equals':
            case 'date_not_equals':
            case 'date_between':
            case 'date_greater_than':
            case 'date_less_than':
                return {v1: new Date(), v2: null};
            case 'list_equals':
            case 'list_not_equals':
                return {v1: '', v2: ''};
        }
    };

    const removeFilterRow = (e) => {
        filterCtx.setFilter(state => {
            return {
                ...state,
                [selectedField.field]: state[selectedField.field].filter((f, i) => {
                    return Number(e.target.dataset.order) !== i;
                })
            }
        });
    };

    const inputChange = (e) => {
        const [name, order] = e.target.name.split('__');
        filterCtx.setFilter(state => {
            return {
                ...state,
                [selectedField.field]: state[selectedField.field].map((f, i) => {
                    if(Number(i) === Number(order)) {
                        f[name] = e.target.value;
                    }
                    return f;
                })
            }
        })
    };

    const doubleInputChange = (e) => {
        const [name, order] = e.target.name.split('__');
        filterCtx.setFilter(state => {
            return {
                ...state,
                [selectedField.field]: state[selectedField.field].map((f, i) => {
                    if (Number(i) === Number(order)) {
                        f.v1 = e.target.value.from;
                        f.v2 = e.target.value.to;
                    }
                    return f;
                })
            }
        })
    };

    const onSave = async () => {
        let df = new DataFilter();
        df.loadFromJSON({
            name: filterCtx.name,
            filter: {...filterCtx.filter}
        });
        try {
            if(filterCtx.id) {
                await oblax.dataFilter.updateDataFilter(filterCtx.id, df);
                filterCtx.setSavedFilters(filterCtx.savedFilters.map(f => {
                    if(f.id === filterCtx.id) {
                        f.filter = filterCtx.filter;
                        f.name = filterCtx.name;
                    }
                    return f;
                }));
            } else {
                let res = await oblax.dataFilter.createDataFilter(filterCtx.tableName, df);
                filterCtx.setSavedFilters([
                    {
                        ...res.data,
                        _created: new Date().toISOString()
                    },
                    ...filterCtx.savedFilters
                ]);
            }
        } catch(err) {
            console.log(err);
        }
    };

    const onApply = () => {
        filterCtx.applyFilters(true);
    };

    const getFieldRulesNumber = (field) => {
        if (filterCtx.filter[field]){
            return filterCtx.filter[field].length > 0 ? filterCtx.filter[field].length : null;
        }
    };

    const nameFieldChange = (e) => {
        filterCtx.setName(e.target.value);
    };

    return (
        <div className="popout-data-filter__popover__filter-builder">
            <div className="filter-builder__header">
                <button className="filter-builder__header__back" onClick={props.onClickBack}>
                    <img src={ICON_BACK} alt="" />
                </button>
                <input 
                    className="filter-builder__header__name" 
                    placeholder="Unnamed filter" 
                    name="name" 
                    value={filterCtx.name} 
                    onChange={nameFieldChange}
                />
                <Button 
                    icon={ICON_SAVE} 
                    alt=""
                    onClick={onSave}
                >
                    Save
                </Button>
                <Button 
                    icon={ICON_SAVE} 
                    alt="" 
                    type="secondary" 
                    customClassName="filter-builder__apply"
                    onClick={onApply}
                >
                    Apply
                </Button>
            </div>
            <div className="filter-builder__body">
                <ul className="filter-builder__fields-list">
                    {filterCtx.dataTypes.map((p, i) => {
                        let selected = p.field === selectedField.field ? 'active' : '';
                        let activeRules = getFieldRulesNumber(p.field);
                        return (
                            <li 
                                key={i}
                                className={`filter-builder__fields-list__item ${selected}`}
                                data-field={p.field}
                                data-type={p.type}
                                onClick={dataFieldClick}
                            >
                                {p.title} 
                                {activeRules ? <span className="filter-builder__fields-list__item__badge">{activeRules}</span> : null}
                            </li>
                        )
                    })}
                </ul>
                <div className="filter-builder__fields-editor">
                    {filterCtx.filter[selectedField.field] ? 
                        filterCtx.filter[selectedField.field].map((f, i) => {
                            return (
                                <div className="filter-builder__fields-editor__row" key={i}>
                                    <Dropdown 
                                        options={filterOptions[selectedField.type]} 
                                        label="Condition" 
                                        customClassName="fields-editor__row__condition" 
                                        name={`condition__${i}`}
                                        onChange={onConditionChange} 
                                        value={f.condition} 
                                    />
                                    {fieldGenerator(f.condition, f.v1, f.v2, i)}
                                    <div className="fields-editor__row__remove">
                                        <button className="fields-editor__row__remove__btn" onClick={removeFilterRow}>
                                            <img src={ICON_TRASH} alt="" data-order={i}/>
                                        </button>
                                    </div>
                                </div>
                            )
                        }) :
                        null
                    }
                    <button onClick={addFilterRow} className="filter-builder__fields-editor__add-row-btn">Add Filter Row</button>
                </div>
            </div>
        </div>
    );
};

export default FilterBuilder;