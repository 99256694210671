import React, { useState, useEffect } from 'react';
// components
import StatTableCard from 'com/widgets/StatTableCard';
import Icon from 'com/ui/Icon';
// css
import './style.css'

const StatsMostActive = (props) => {

    const mostVisitedFormsHeaders = ["Form", "Total submits", "This week", "Last submission"];

    const [loading, setLoading] = useState(true);

    const formsData = [
        { form: "Contact Form", domain: "http://domain.mk/faq", total_submits: 228, this_week_submits: 28 },
        { form: "Jobs", domain: "http://domain.mk/about-us", total_submits: 228, this_week_submits: 28},
        { form: "Review Form", domain: "http://domain.mk/setup", total_submits: 228, this_week_submits: 28 },
        { form: "TAC Form", domain: "http://domain.mk/terms-and-cond", total_submits: 228, this_week_submits: 28}
    ];

      // The emptyData state and the useEffect are ONLY for testing purposes

      const [emptyData, setEmptyData] = useState([]);

      useEffect(() => {
           setTimeout(() => {
              setLoading(false);
          }, 2000);
      }, []);

    return (
        <StatTableCard title="Most active forms" icon="list-ordered " columns={mostVisitedFormsHeaders}>
            {
                loading ?
                <StatTableCard.Loader cols={4} rows={5}/> :
                <TableData data={formsData}/>
            }
        </StatTableCard>
    )

};

const TableData = (props) => {
    const timestamp = new Date().toLocaleString();
    return (
        props.data.length > 0 ?
        props.data.map((f,i) => {
            return (
                <tr key={i}>
                    <td>
                        <div className='form-data__wrapper'>
                            <Icon name="pages" color="secondary" type="line" size="largest" />
                            <div className='form-data__details'>
                                <span className='form-data__details__form-name'>{f.form}</span>
                                <span className='form-data__details__domain'>{f.domain}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className='form-data__total-submits'>
                            {f.total_submits}
                        </span>
                    </td>
                    <td>
                        <span className='form-data__this-week-submits'>
                            {f.this_week_submits}
                        </span>
                    </td>
                    <td>
                        <div className='form-data__last-submissions'>
                            <span className='form-data__last-submits__hours-ago'>4 hours ago</span>
                            <span className='form-data__last-submits__timestamp'>{timestamp}</span>
                        </div>
                    </td>
                </tr>
            );
        }) :
        <StatTableCard.NoData colspan={4}/>
    );
};

export default StatsMostActive;