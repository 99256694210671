import React, { useState, useEffect } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import DataTable, { Paging } from 'com/ui/DataTable';

const DemoDataTable = (props) => {

    const [tableHeaders, setTableHeaders] = useState([
        { title: 'Order ID', field: 'order_id', type: 'string', sort: 'none', sortable: true },
        { title: 'Buyer', field: 'buyer', type: 'string', sort: 'none', sortable: true },
        { title: 'No. Items', field: 'no_items', type: 'number', sort: 'none', sortable: true },
        { title: 'Total', field: 'total', type: 'number', sort: 'none', sortable: true },
        { title: 'Tax', field: 'tax', type: 'number', sort: 'none', sortable: true },
        { title: 'Revenue', field: 'revenue', type: 'number', sort: 'none', sortable: true },
        { title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
        { title: 'Delivery Scheduled', field: 'delivery_scheduled', type: 'date', sort: 'none', sortable: true },
        { title: 'Created', field: 'created', type: 'date', sort: 'none', sortable: true }
    ]);

    const orders = [
        { id: 'ma123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'nb123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'bc123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'vd123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'ce123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'xf123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'zg123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'ah123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'si123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'dj123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'fk123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'gb123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'hc123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'jd123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'ke123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'lf123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'g123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'ph123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'oi123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'ij123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'uk123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'yab123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'tbc123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'tcd123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'rse123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'eef123456789', buyer: { first_name: 'Ivana', last_name: 'Kozolovska', city: 'Skopje', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'MKD', lang_code: 'mk-MK' },
        { id: 'wfg123456789', buyer: { first_name: 'Ana', last_name: 'Trajkovska', city: 'London', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'GBP', lang_code: 'en-GB' },
        { id: 'qgh123456789', buyer: { first_name: 'Sara', last_name: 'Stefanovska', city: 'Gothenburg', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'pending', delivery_scheduled: new Date(), created: new Date(), currency: 'SEK', lang_code: 'sv-SE' },
        { id: 'uhi123456789', buyer: { first_name: 'Dejan', last_name: 'Mihajlovic', city: 'Belgrade', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'delivered', delivery_scheduled: new Date(), created: new Date(), currency: 'RSD', lang_code: 'sr-RS' },
        { id: 'oij123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'ajj123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' },
        { id: 'jkj123456789', buyer: { first_name: 'Mihail', last_name: 'Filipovski', city: 'Valletta', avatar: '' }, no_items: 12, total: 1600, tax: 280, revenue: 1320, status: 'canceled', delivery_scheduled: new Date(), created: new Date(), currency: 'EUR', lang_code: 'mt-MT' }
    ];

    const [paging, setPaging] = useState({ total_rows: orders.length, page: 1, limit: 5 });
    const [pageChange, setPageChange] = useState(1);
    const [filterData, setFilterData] = useState({
        name: '', status: '', registration_date: '', last_login: '', email: '', country: '',
        account_provider: '', role: '', total: '', orderBy: []
    });

    const [ordersState, setOrdersState] = useState([...orders.slice(0, paging.limit)]);
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        // TODO handle this when implementing the real table
        if (firstTime) {
            setFirstTime(false);
        } else {
            getData();
        }
    }, [pageChange]);

    const pagingChange = (p) => {
        setPaging({ ...paging, page: p });
        setPageChange(pageChange + 1);
    };

    const getData = () => {
        const start = paging.page * paging.limit - paging.limit;
        const end = start + paging.limit;
        let data = orders.slice(start, end);
        setOrdersState(data);
    };

    const columnSort = async (field, type) => {
        await setTableHeaders(tableHeaders.map(item => {
            if (item.field === field && item.sortable) {
                item.sort = item.sort === 'up' ? 'down' : 'up';
                if (filterData.orderBy.filter(data => data.field === field).length === 0) {
                    setFilterData({
                        ...filterData,
                        orderBy: [{ field: field, direction: item.sort }, ...filterData.orderBy]
                    });
                } else {
                    setFilterData({
                        ...filterData,
                        orderBy: [{ field: field, direction: item.sort },
                        ...filterData.orderBy.filter(c => c.field !== field)]
                    });
                }
            }
            return item;
        }));
    };

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];

    return (
        <ComponentDemoTemplate>
            <>
                <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                    {ordersState.map(order => {
                        return (
                            <tr key={order.id}>
                                <DataTable.IdCell>{order.id}</DataTable.IdCell>
                                <DataTable.UserCell avatar='' city={order.buyer.city}>{order.buyer.first_name} {order.buyer.last_name}</DataTable.UserCell>
                                <DataTable.Cell>{order.no_items}</DataTable.Cell>
                                <DataTable.ValueCell lang_code={order.lang_code} currency={order.currency}>{order.total}</DataTable.ValueCell>
                                <DataTable.ValueCell lang_code={order.lang_code} currency={order.currency}>{order.tax}</DataTable.ValueCell>
                                <DataTable.ValueCell lang_code={order.lang_code} currency={order.currency}>{order.revenue}</DataTable.ValueCell>
                                <DataTable.StatusCell status={order.status} />
                                <DataTable.DateCell separator='-'>{order.delivery_scheduled}</DataTable.DateCell>
                                <DataTable.DateCell>{order.created}</DataTable.DateCell>
                                <DataTable.ActionCell actions={actions} actionData={order} />
                            </tr>
                        );
                    })}
                </DataTable>
                <Paging totalRows={orders.length} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoDataTable;