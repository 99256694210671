import React from 'react';
import AppRoute from 'com/util/AppRoute';

import Routes from 'data/routes';

const AppRouteGroup = (props) => {
    let routes = Object.values(Routes).filter(r => r.groups.includes(props.name)).map((r, i) => {
        return <AppRoute route={r} exact={r.exact} key={i}/>
    });
    return routes;
};

AppRouteGroup.displayName = 'AppRouteGroup';

export default AppRouteGroup;