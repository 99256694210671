import React from 'react';
import { NavLink } from 'react-router-dom';
import PortalSearch from 'com/widgets/PortalSearch';
import NotificationsMenu from 'com/widgets/NotificationsMenu';
import UserMenu from 'com/widgets/UserMenu';

import './style.css';

const PortalHeader = (props) => {
    return (
        <div className="portal-header">
            <div className="portal-header__left">
                <h2 className="portal-header__left__title">{props.title}</h2>
                <nav className="portal-header__left__nav">
                    {props.navigation && props.navigation.map((n, i) => {
                        return <NavLink to={n.to} key={i} exact>{n.label}</NavLink>
                    })}
                </nav>
            </div>
            <div className="portal-header__search">
                <PortalSearch 
                    value={props.searchValue} 
                    onChange={props.onSearchChange}
                />
            </div>
            <div className="portal-header__notifications">
                <NotificationsMenu />
            </div>
            <span className="portal-header__separator"></span>
            <div className="portal-header__user-menu">
                <UserMenu />
            </div>
        </div>
    );
};

export default PortalHeader;