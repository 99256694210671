const init = {
    loggedIn: false,
    user: {
        csrf: "",
        email: "",
        exp: 0,
        full_name: "",
        iat: 0,
        iss: "",
        pid: "",
        role: "",
        uid: ""
    }
};

// constants
const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN';
const AUTH_SET_USER_DATA = 'AUTH_SET_USER_DATA';
const AUTH_CLEAR_USER_DATA = 'AUTH_CLEAR_USER_DATA';

// actions
export const setUserData = (data) => {
    return {
        type: AUTH_SET_USER_DATA,
        payload: data
    };
};

export const setLoggedIn = (data) => {
    return {
        type: AUTH_SET_LOGGED_IN,
        payload: data
    };
};

export const clearUserData = () => {
    return {
        type: AUTH_CLEAR_USER_DATA,
        payload: {
            loggedIn: false,
            user: null
        }
    };
};

// reducer
const authReducer = (state = init, action) => {
    switch (action.type) {
        case AUTH_SET_LOGGED_IN:
            return { ...state, loggedIn: action.payload };
        case AUTH_SET_USER_DATA:
            return { ...state, user: action.payload };
        case AUTH_CLEAR_USER_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default {
    setUserData,
    setLoggedIn,
    clearUserData,
    reducer: authReducer
};