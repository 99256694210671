import React from 'react';

import ApplicationTemplate from 'com/templates/ApplicationTemplate';

const UsersAccountsDetails = (props) => {

    const headerNav = [
        { to: '/users', label: 'Dashboard' },
        { to: '/users/accounts', label: 'Accounts' },
        { to: '/users/access-requests', label: 'Access Requests' },
    ];

    return (
        <ApplicationTemplate
            headerTitle="Users"
            headerNavigation={headerNav}
        >
            <div>This the users account details</div>
        </ApplicationTemplate>
    );
};

export default UsersAccountsDetails;