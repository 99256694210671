// vendor imports
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import oblax from 'services/oblax';
import { useHistory } from 'react-router';
// components
import Icon from 'com/ui/Icon';
// services
import L11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

const Menu = (props) => {
	const history = useHistory();
	const sendNotification = useNotification();

	const onLogout = (e) => {
		e.preventDefault();
		try {
			let res = oblax.auth.logout();
			if (res.ok) {
				history.push('/');
			}
		} catch (err) {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log(err);
			}
			sendNotification({ type: 'error', title: L11n.t(`ERROR_LOGOUT`) });
		}
	};

	return (
		<div className="user-menu-dropdown">
			<ul className="user-menu-dropdown__list">
				<li className="user-menu-dropdown__list-item" >
					<Link to="/portal/b" onMouseDown={(e) => e.preventDefault()}>
						<Icon
							name="user-3"
							color="grey"
							size="large"
							customClassName="user-menu-dropdown__icon"
						/>
						<span className="user-menu-dropdown__text">User settings</span>
					</Link>
				</li>
				<li className="user-menu-dropdown__list-item">
					<Link to="/portal/c" onMouseDown={(e) => e.preventDefault()}>
						<Icon
							name="settings-3"
							color="grey"
							size="large"
							customClassName="user-menu-dropdown__icon"
						/>
						<span className="user-menu-dropdown__text">Application settings</span>
					</Link>
				</li>
				<li className="user-menu-dropdown__list-item">
					<span onClick={onLogout}>
						<Icon
							name="logout-circle-r"
							color="grey"
							size="large"
							customClassName="user-menu-dropdown__icon"
						/>
						<span className="user-menu-dropdown__text">Log out</span>
					</span>
				</li>
			</ul>
		</div>
	);
};

export default withRouter(Menu);