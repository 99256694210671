import React from 'react';

import './style.css';

const ListViewTemplate = (props) => {

    const topPanel = React.Children.map(props.children, child => child.type.displayName === 'Top' ? child : null);
    const actionsPanel = React.Children.map(props.children, child => child.type.displayName === 'Actions' ? child : null);
    const mainPanel = React.Children.map(props.children, child => child.type.displayName === 'Main' ? child : null);

    return (
        <div className="list-view-tpl">
            {
                topPanel.length > 0 ?
                    <div className="list-view-tpl__top">{topPanel}</div> :
                    null
            }
            {
                actionsPanel.length > 0 ?
                    <div className="list-view-tpl__actions">{actionsPanel}</div> :
                    null
            }
            {
                mainPanel.length > 0 ?
                    <div className="list-view-tpl__main">{mainPanel}</div> :
                    null
            }
        </div>
    );
};

const Top = (props) => props.children;
Top.displayName = 'Top';
ListViewTemplate.Top = Top;

const Actions = (props) => props.children;
Actions.displayName = 'Actions';
ListViewTemplate.Actions = Actions;

const Main = (props) => props.children;
Main.displayName = 'Main';
ListViewTemplate.Main = Main;

export default ListViewTemplate;
