// vendor imports
import React from 'react';

export const Unit = (props) => {
	const selectItem = () => {
		props.selectItem(props.unit);
	};

	return (
		<div key={props.indexKey}
			onClick={selectItem}
			className={`dropdown-item ${props.value === props.unit ? "selected" : ""} ${props.active ? "active" : ""}`}
			ref={props.active ? props.scrollTo : null}
		>
			{props.unit}
		</div>
	);
};