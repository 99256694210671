import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Textarea from 'com/ui/Textarea'; 

const DemoTextarea = (props) => {

    const [textArea, setTextArea] = useState('');

    const textChange = (e) => {
        setTextArea(e.target.value);
    };

    return (
        <ComponentDemoTemplate>
            <>
                <Textarea
                    label="Detailed description"
                    placeholder="Sample content..."
                    value={textArea}
                    onChange={textChange}
                    disabled={false}
                />
                <Textarea
                    label="Detailed description"
                    placeholder="Sample content..."
                    value={textArea}
                    onChange={textChange}
                    error="An error occured"
                    disabled={false}
                />
                <Textarea
                    label="Detailed description"
                    placeholder="Sample content..."
                    value={textArea}
                    onChange={textChange}
                    disabled={true}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoTextarea;