const stripTags = (string) => {
    return string.replace(/(<([^>]+)>)/g, "");
};

const snakeToTitle = (string) => {
    let title = string.replaceAll("_", " ");
    return title;
};

export default {
    stripTags,
    snakeToTitle
};