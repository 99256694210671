import React from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
//components
import Button from 'com/ui/Button';
//icons
import ICON_CONFIG from 'assets/images/icons/settings-blue.svg';
import ICON_CLOSE from 'assets/images/icons/close.svg';
//styles
import './style.css'

const ModalWindow = (props) => {
    if(!props.open) return null
    return ReactDom.createPortal (
        <div className ="modal-overlay">
            <div className="modal">
                <div className="modal__header">
                    <div className="modal__header__title">
                        <img 
                            className="modal__header__icon" 
                            src={props.icon}
                            alt=""
                        />
                        <h2>{props.title}</h2>
                    </div>
                    <button className="modal__remove" onClick={props.onClose}>
                        <img className="modal__remove__icon" src={ICON_CLOSE} alt="close icon" />
                    </button>
                </div>
                <div className="modal__content">
                    {props.children}
                </div>
                {
                    props.footerActions ? 
                    (<div className="modal__buttons">
                        {props.footerActions.map((b, i)=> {
                            return b.primary 
                            ? <Button type="primary" onClick={b.action} key={i} showLoader={b.showLoader}>{b.label}</Button>
                            : <Button type="ghost" onClick={b.action} key={i}>{b.label}</Button>

                        })}
                    </div>) :
                    null
                }
            </div>
        </div>,
        document.getElementById('modal')
    )
};

ModalWindow.propTypes = {
    open: PropTypes.bool,
    icon: PropTypes.node,
    title: PropTypes.string,
    onClose: PropTypes.func,
    footerActions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func
    }))
};

ModalWindow.defaultProps = {
    open: false,
    icon: ICON_CONFIG,
    title: '',
    onClose: () => {},
    footerActions: []
};

export default ModalWindow;
