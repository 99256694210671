import React from "react";
import PropTypes from 'prop-types';
// 
import Icon from "com/ui/Icon";
//styles
import './style.css';

const ContentPanel = (props) => {
    const footer = React.Children.map(props.children, child => child.type.displayName === 'Footer' ? child : null);
    const body = React.Children.map(props.children, child => child.type.displayName === 'Body' ? child : null);

    return (
        <div className={`content-panel-container ${props.type} ${props.customClassName}`}>
            <h2 className="content-panel-container__header">
                <Icon name="arrow-down-s" color="primary" size="large" type="line"/>
                {props.title}
            </h2>
            <div className="content-panel-container__box">
                {
                    body.length > 0 ?
                    <div className="content-panel__content">{body}</div> :
                    null
                }
                {
                    footer.length > 0 ?
                    <div className="content-panel__footer">{footer}</div> :
                    null
                }
            </div>
        </div>
    );
};

ContentPanel.propTypes = {
    title: PropTypes.string,
    type: PropTypes.oneOf(['light', 'dark']),
    customClassName: PropTypes.string
};

ContentPanel.defaultProps = {
    title: '',
    type: 'light',
    customClassName: ''
};

const Footer = (props) => props.children; 
Footer.displayName = 'Footer';
ContentPanel.Footer = Footer;

const Body = (props) => props.children; 
Body.displayName = 'Body';
ContentPanel.Body = Body;

const Separator = (props) => <div className="content-panel__separator">{props.children}</div>; 
ContentPanel.Separator = Separator;

export default ContentPanel;