const initialState = [
    // {
    //     type: 'user',
    //     title: 'New user registration',
    //     text: 'A new user registered to the system',
    //     link: '/pero',
    //     icon: 'users',
    //     timestamp: '2020-09-10T12:47:38.418Z'
    // },
    // {
    //     type: 'system',
    //     title: 'Scheduled system upgrade',
    //     text: 'A new user registered to the system',
    //     link: '/pero',
    //     icon: 'upgrade',
    //     timestamp: '2020-09-10T12:47:38.418Z'
    // },
    // {
    //     type: 'system',
    //     title: 'New features available',
    //     text: 'A new user registered to the system',
    //     link: '/pero',
    //     icon: 'features',
    //     timestamp: '2020-09-10T12:47:38.418Z'
    // },
    // {
    //     type: 'system',
    //     title: 'Your invoice is ready',
    //     text: 'A new user registered to the system',
    //     link: '/pero',
    //     icon: 'billing',
    //     timestamp: '2020-09-10T12:47:38.418Z'
    // }
];

const notificationsReducer = (state = initialState, action) => {
    return state;
};

export default {
    reducer: notificationsReducer
};
