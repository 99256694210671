//vendor imports
import React from 'react';
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
//ui components
import DataTable, { Paging } from 'com/ui/DataTable'; 
import Button from "com/ui/Button";
//widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
import UsersStatsCarousel from 'com/widgets/StatsCarousel/UsersStatsCarousel';
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
//styles
import './style.css';
// data
import useDataTable from 'services/hooks/use_datatable';

const UsersAccountsList = (props) => {

    const headerNav = [
        { to: '/users', label: 'Dashboard' },
        { to: '/users/accounts', label: 'Accounts' },
        { to: '/users/access-requests', label: 'Access Requests' },
    ];

    const initTableHeaders = [
        { title: 'User', field: 'user', type: 'string', sort: 'none', sortable: true },
        { title: 'Status', field: 'active', type: 'number', sort: 'none', sortable: true },
        { title: 'Registaration Date', field: '_created', type: 'date', sort: 'none', sortable: true } ,
        // { title: 'Last Login', field: 'last_login', type: 'date', sort: 'none', sortable: true },
        { title: 'E-mail', field: 'email', type: 'string', sort: 'none', sortable: true },
        { title: 'Country', field: 'country', type: 'string', sort: 'none', sortable: true },
        { title: 'Acct.Provider', field: 'provider', type: 'string', sort: 'none', sortable: true },
        { title: 'Role', field: 'role', type: 'string', sort: 'none', sortable: true },
    ];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange, 
        columnSort,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initTableHeaders, 
        'users.getAllV2'
    );

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];
    
    const rowClickHandler = (e) => {
        let rowID = e.target.closest('tr').dataset.rowId;
        console.log(rowID);
    };

    const statusLabels = {
        "1": {
            label: 'active',
            color: 'GREEN'
        },
        "0": {
            label: 'inactive',
            color: 'RED'
        }
    };

    return (
        <ApplicationTemplate
            headerTitle="Users"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <UsersStatsCarousel />
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="users"
                        onApply={filterApply}
                    />
                    <Button
                        type="secondary"
                        icon={ICON_PLUS}
                        customClassName="users-accounts__create-new-btn"
                    >
                        Create New
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                        {tableData.map((u,i) => {
                            return (
                                <tr key={u.id} data-row-id={u.id} onClick={rowClickHandler}>
                                    <DataTable.UserCell avatar='' city={u.city}>{u.first_name} {u.last_name}</DataTable.UserCell>
                                    <DataTable.StatusCell labels={statusLabels} status={u.active}/>
                                    <DataTable.DateCell separator='-'>{u._created}</DataTable.DateCell>
                                    {/* <DataTable.DateCell>{u.last_login}</DataTable.DateCell> */}
                                    <DataTable.Cell>{u.email}</DataTable.Cell>
                                    <DataTable.LocationCell>{u.country}</DataTable.LocationCell>
                                    <DataTable.Cell>{u.provider}</DataTable.Cell>
                                    <DataTable.Cell>{u.role}</DataTable.Cell>
                                    <DataTable.ActionCell actions={actions} actionData={u}/>
                                </tr>
                            );
                        })}
                    </DataTable>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default UsersAccountsList;