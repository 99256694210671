import React from 'react';
// components
import StatsMostLoyal from './StatsMostLoyal';
import StatsBiggestSpenders from './StatsBiggestSpenders';
import StatsMostActive from './StatsMostActive';
// styles
import './style.css';

const StatTables = () => {

	return (
		<div className="stat-table-cards-wrapper">
			<div className="stat-table-card__container">
				<StatsMostLoyal/>
			</div>
			<div className="stat-table-card__container">
				<StatsBiggestSpenders/>
			</div>
			<div className="stat-table-card__container">
				<StatsMostActive/>
			</div>
		</div>
	);
};

export default StatTables;