import React from 'react';

import './style.css';

const PortalSearch = (props) => {
    return (
        <input type="text" placeholder="Search" className="portal-search-field"/>
    );
};

export default PortalSearch;