import React from 'react';

import ApplicationTemplate from 'com/templates/ApplicationTemplate';

const MainDashboard = (props) => {

    const headerNav = [
        {to: '/', label: 'Dashboard'},
        { to: '/some-other-thing', label: 'Other thing' },
    ];

    return (
        <ApplicationTemplate
            headerTitle="Main Dashboard"
            headerNavigation={headerNav}
        >
            <div>This is a div</div>
        </ApplicationTemplate>
    );
};

export default MainDashboard;