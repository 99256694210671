// vendor imports
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import oblax from 'services/oblax';
// components
import PublicTemplate from 'com/templates/PublicTemplate';
import LoginBox from 'com/templates/LoginBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Error from 'com/ui/Error';
// services
import L11n from 'services/l11n';
// constants
import { FORGOT_PASSWORD_SCHEMA } from 'schemas/forgotPassword';
// styles
import './style.css';

const ForgotPassword = () => {
    const history = useHistory();
    const initErrors = {
        email: ''
    };
    const [email, setEmail] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(initErrors);
    const [xhttpError, setXhttpError] = useState('');

    const onChange = (e) => {
        setEmail(e.target.value);
    };

    const sendPasswordResetEmail = async () => {
        if (!validateForm() || showLoader) return;
        setShowLoader(true);
        try {
            let res = await oblax.auth.resetPasswordLink(email);
            if (res.ok) {
                setShowLoader(false);
                history.push("/auth-message/check-email");
            }
        } catch (err) {
            if (process.env.REACT_APP_ENV !== 'prod') {
                console.log(err);
            }
            setXhttpError(L11n.t(`ERROR_${err.data.type}`));
            setShowLoader(false);
        }
    };

    const validateForm = () => {
        setErrors(initErrors);
        let data = { email: email };
        let validate = oblax.validator.validate(FORGOT_PASSWORD_SCHEMA, data);
        if (!validate) {
            let errs = oblax.validator.getValidationErrors();
            for (let e of errs) {
                setErrors(state => { return { ...state, [e.key]: L11n.t(`ERROR_${e.code}`) } });
            }
        }
        return validate;
    };

    const removeError = () => {
        setXhttpError('');
    };

    return (
        <PublicTemplate>
            <LoginBox title={L11n.t('WELCOME')} showNavigation={true}>
                <p className="forgot-password-text">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable.</p>
                <Input 
                    type="email" 
                    label="Enter your registered email" 
                    value={email} 
                    error={errors.email} 
                    onChange={onChange} 
                />
                <div className="forgot-password-footer">
                    <Button showLoader={showLoader} onClick={sendPasswordResetEmail}>Send Password Reset Email</Button>
                </div>
            </LoginBox>
            {xhttpError ? <Error message={xhttpError} onClick={removeError} /> : null}
        </PublicTemplate>
    );
};

export default ForgotPassword;