//vendor imports
import React, { useEffect, useState } from "react";
//components
import PublicTemplate from "com/templates/PublicTemplate";
// services
// import L11n from 'services/l11n';
//styles
import './style.css';

const TermsAndConditions = (props) => {

    return (
        <PublicTemplate>
            <div className="tac-container">
                <h2 className="tac-title">Terms &amp; Conditions</h2>
                <div className="tac-content">
                    <h3>Intro</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla non nulla quis eleifend. Nam blandit mi in diam venenatis, ac porta dolor eleifend. Donec accumsan, diam at mattis dapibus, risus sapien ullamcorper ligula, id elementum ante dui vel dolor. Praesent ac elit libero. Nullam condimentum varius orci. Aenean vitae erat id risus sodales dapibus. Aliquam et risus condimentum, sollicitudin justo sit amet, euismod diam. Fusce enim quam, porttitor sed metus condimentum, posuere molestie lectus. Fusce efficitur, ex ac dictum ultricies, nibh massa maximus justo, vel euismod lorem massa id quam. Praesent vel suscipit justo. Cras vel maximus orci. Integer nunc massa, rutrum in consequat eget, pretium pharetra lorem. Duis libero risus, maximus eu cursus sit amet, convallis id lorem.</p>
                    <p>Phasellus accumsan at lorem ut pellentesque. Nulla fermentum, erat sed ultrices laoreet, dolor lectus aliquam magna, ut eleifend augue lectus vel dolor. Proin et elit quis ipsum mattis volutpat ac sed velit. Maecenas accumsan quam eget aliquet facilisis. Etiam semper enim enim, sed commodo lectus molestie ac. Pellentesque est orci, porttitor egestas augue ut, tincidunt mattis nisi. Vivamus et massa faucibus, hendrerit tortor ac, placerat odio. Pellentesque vitae leo porta, lobortis libero in, imperdiet purus. Suspendisse et leo leo. Morbi iaculis dui ac nisl blandit venenatis. Aenean nec vulputate lectus.</p>
                    <h3>Intro 2</h3>
                    <p>Praesent sed orci eget urna tempor porta quis sit amet enim. Aliquam erat volutpat. Integer tincidunt pharetra viverra. Suspendisse eu odio accumsan, consectetur mi vel, rutrum metus. Etiam non ultrices libero. Integer ut justo id turpis efficitur mollis. Sed ultricies at lacus non maximus. Cras nibh lectus, feugiat ut nunc ut, ullamcorper luctus sapien.</p>
                    <p>Vestibulum sagittis malesuada venenatis. Quisque eget auctor tellus, id feugiat diam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras venenatis ante vel sem elementum maximus. Integer consectetur tortor nec est egestas, id eleifend lorem condimentum. Nulla sapien metus, maximus id tincidunt nec, pulvinar a augue. Etiam pulvinar et eros eu euismod. Donec vehicula tempor turpis. Integer commodo diam a mi gravida, id eleifend neque aliquam. Proin ornare urna a tellus faucibus vestibulum. Aliquam malesuada dolor non magna scelerisque ultricies at ut augue. Maecenas at felis id dui scelerisque aliquam. Suspendisse eget orci est. Nam varius sapien ac porttitor vulputate.</p>
                    <h3>Intro 3</h3>
                    <p>Nulla efficitur lobortis dolor et consequat. Integer tempus justo lorem, et vehicula dui scelerisque vel. Vestibulum tincidunt orci a risus laoreet, vitae tincidunt massa suscipit. Nunc consectetur, magna sed tempus sagittis, felis velit sodales est, eu consectetur tortor augue sit amet leo. Mauris sem ligula, consectetur vitae efficitur in, placerat molestie ligula. Praesent condimentum ipsum id risus tincidunt interdum. Vestibulum quis nibh velit. Nunc convallis semper bibendum. Fusce efficitur erat sit amet mauris volutpat pulvinar. Quisque eget tempus ex. Vestibulum aliquet congue nisi id blandit.</p>
                </div>
            </div>
        </PublicTemplate>
    )
}

export default TermsAndConditions;
