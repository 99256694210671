// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// utils
import dateFormatter from 'services/util/date';
// components 
import ActionsMenu from 'com/widgets/ActionsMenu';
import Paging from './paging';
import Echo from 'com/util/Echo';
// icons
import ICON_ID from 'assets/images/icons/input-type-id.svg';
import ICON_SORT_UP from 'assets/images/icons/arrow-up-grey.svg';
import ICON_SORT_DOWN from 'assets/images/icons/arrow-down-grey.svg';
import ICON_SORT_UP_ACTIVE from 'assets/images/icons/arrow-up-orange.svg';
import ICON_SORT_DOWN_ACTIVE from 'assets/images/icons/arrow-down-orange.svg';
import ICON_USER from 'assets/images/icons/user.svg';
import ICON_PRODUCT from 'assets/images/icons/product.svg';
// styles
import './style.css';

const DataTable = (props) => {
	return (
		<table cellPadding='0' cellSpacing='0' className='data-table'>
			<thead>
				<tr>
					{props.columns.map((column, index) => {
						return (
							<HeaderCell
								key={index}
								title={column.title}
								field={column.field}
								onClick={props.onColumnSort}
								type={column.type}
								sort={column.sort}
								sortable={column.sortable}
							/>
						);
					})}
				</tr>
			</thead>
			<tbody>{props.children}</tbody>
		</table>
	);
};

DataTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape({
		field: PropTypes.string,
		sort: PropTypes.string,
		sortable: PropTypes.bool,
		title: PropTypes.string,
		type: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
	})),
	onColumnSort: PropTypes.func
};

DataTable.defaultProps = {
	columns: [],
	onColumnSort: () => {}
};

const HeaderCell = (props) => {
	const onHeaderCellClick = () => {
		props.onClick(props.field, props.type);
	};
	return (
		<th onClick={onHeaderCellClick}>
			<div className='data-table__header-cell'>
				{props.sortable && <Sorter dir={props.sort}/>}
				<span>{props.title}</span>
			</div>
		</th>
	);
};

HeaderCell.propTypes = {
	title: PropTypes.string,
	field: PropTypes.string,
	sort: PropTypes.string,
	type: PropTypes.string,
	sortable:PropTypes.bool
};

HeaderCell.defaultProps = {
	title: '',
	field:'',
	sort: '',
	type: '',
	onClick: () => {}
};

const Sorter = (props) => {
	return (
		<div className='sorter-flags'>
			<div><img src={props.dir === 'up' ? ICON_SORT_UP_ACTIVE : ICON_SORT_UP} alt='' /></div>
			<div><img src={props.dir === 'down' ? ICON_SORT_DOWN_ACTIVE : ICON_SORT_DOWN} alt='' /></div>
		</div>
	);
};

Sorter.propTypes = {
	dir: PropTypes.string
};

Sorter.defaultProps = {
	dir: '',
};

const Cell = (props) => {
	return (
		<td>
			<div className='table-cell'>
				{props.children}
			</div>
		</td>
	);
};

const UserCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-user'>
				<picture className='table-cell-user__avatar'>
					<source srcSet='' />
					<img src={ICON_USER} alt='' />
				</picture>
				<div className='table-cell-user__info'>
				    <span className='table-cell-user__info__name'>{props.children}</span>
					{props.city && <span className='table-cell-user__info__city'>{props.city}</span>}
					{props.email && <span className='table-cell-user__info__email'>{props.email}</span>}
				</div>
			</div>
		</td>
	);
};

UserCell.propTypes = {
	avatar: PropTypes.string,
	city: PropTypes.string
};

UserCell.defaultProps = {
	avatar: '',
	city: ''
};

const DateCell = (props) => {
	return (
		<td>
			<div className='table-cell'>
				<Echo.Date {...props}>{props.children}</Echo.Date>
			</div>
		</td>
	);
};

DateCell.propTypes = {
	locale: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.objectOf(Date)
	]).isRequired,
	style: PropTypes.oneOf(['short', 'full']),
	onlyTime: PropTypes.bool
};

DateCell.defaultProps = {
	locale: 'en-GB',
	onlyTime: false
};

const IdCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-id'>
				<img src={ICON_ID} className='table-cell-id__icon' alt='' />
				{props.children}
			</div>
		</td>
	);
};

const StatusCell = (props) => {
    const statusColors = {
		GREY: 'grey',
		RED: 'red',
		BLUE: 'blue',
        GREEN: 'green',
		YELLOW: 'yellow',
		ORANGE: 'orange'
	};

    let out = props.status.toUpperCase();
    let classes = ['table-status'];

    if (props.labels && props.labels[out]) {
        let color = statusColors[props.labels[out].color];
        out = props.labels[out].label;
        classes = [...classes, color];
    }
	return (
		<td>
			<div className={`table-cell table-cell-status ${classes.join(' ')}`}>
                {out}
			</div>
		</td>
	);
};

StatusCell.propTypes = {
	status: PropTypes.string
};

StatusCell.defaultProps = {
	status: ''
};

const ValueCell = (props) => {
	let value = new Intl.NumberFormat(
		`${props.lang_code}`,
		{
			style: 'currency', 
			currency: props.currency 
		}
	).format(props.children);
	value = value.replace(',', '');

	return (
		<td>
			<div className='table-cell'>
				{value}
			</div>
		</td>
	);
};

ValueCell.propTypes = {
	lang_code: PropTypes.string,
	currency: PropTypes.string
};

ValueCell.defaultProps = {
	lang_code: 'en-US',
	currency: 'USD',
};

const ActionCell = (props) => {
	return (
		<td>
			<ActionsMenu actions={props.actions} data={props.actionData}/>
		</td>
	);
};

ActionCell.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.shape({
		action: PropTypes.func,
		label: PropTypes.string
	})),
	actionData: PropTypes.object
};

ActionCell.defaultProps = {
	actions: [],
	actionData: {}
};

const VendorCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-vendor'>
				<div className='table-cell-vendor'>
					<span className='table-cell-vendor__info__name'>{props.children}</span>
					<span className='table-cell-vendor__info__city'>{props.city}</span>
				</div>
			</div>
		</td>
	);
};

VendorCell.propTypes = {
	city: PropTypes.string
};

VendorCell.defaultProps = {
	city: ''
};

const ProductCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-product'>
				<picture className='table-cell-product__image'>
					<source srcSet='' />
					<img src={ICON_PRODUCT} alt='' />
				</picture>
				<div className='table-cell-product__info'>
					<span className='table-cell-product__info__name'>{props.children}</span>
					<span className='table-cell-product__info__company'>{props.company}</span>
				</div>
			</div>
		</td>
	);
};

ProductCell.propTypes = {
	company: PropTypes.string
};

ProductCell.defaultProps = {
	company: ''
};

const PriceCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-price'>
				<div className='table-cell-price__info'>
					<span className='table-cell-price__info__value'>{props.children}</span>
					<span className='table-cell-price__info__currency'>{props.currency.toLowerCase()}</span>
				</div>
			</div>
		</td>
	);
};

PriceCell.propTypes = {
	currency: PropTypes.string
};

PriceCell.defaultProps = {
	currency: ''
};

const LocationCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-location'>
				<div className='table-cell-location__info'>
					<span className='table-cell-location__info__city'>{props.children}</span>
					<span className='table-cell-location__info__country'>{props.country}</span>
				</div>
			</div>
		</td>
	);
};

LocationCell.propTypes = {
	country: PropTypes.string
};

LocationCell.defaultProps = {
	country: ''
};

const HistoryDateCell = (props) => {
	let intlDateTimeFormat = new Intl.DateTimeFormat('en-GB',
		{ dateStyle: 'short', timeStyle: 'short' }).format(props.children);
	let date = intlDateTimeFormat.replace(/\//g, '.').replace(',', ' -');

	return (
		<td>
			<div className='table-cell table-cell-hisotry-date'>
				<div className='table-cell-hisotry-date__info'>
					<span className='table-cell-hisotry-date__info__history'>{dateFormatter.timeBetween(props.children)}</span>
					<span className='table-cell-hisotry-date__info__date'>{date}</span>
				</div>
			</div>
		</td>
	);
};

HistoryDateCell.propTypes = {
	children: PropTypes.instanceOf(Date)
};

HistoryDateCell.defaultProps = {
	children:new Date()
};

const InStockCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-in-stock'>
				{props.children}
			</div>
		</td>
	);
};

InStockCell.propTypes = {
	children: PropTypes.number
};

InStockCell.defaultProps = {
	children: 0
};

// const BadgeCell = (props) => {
// 	return (
// 		<td>
// 			<div className='table-cell table-cell-badge'>
// 				{props.children.map(badge => {
// 					return <span className={`table-cell-badge__name table-cell-badge-${badge.color}`}>{badge.name}</span>
// 				})
// 				}
// 			</div>
// 		</td>
// 	);
// };

// BadgeCell.propTypes = {
// 	children: PropTypes.arrayOf(PropTypes.shape({
// 		color: PropTypes.string,
// 		name: PropTypes.string
// 	}))
// };

// BadgeCell.defaultProps = {
// 	children: []
// };

const TagCell = (props) => {
	const tagColors = {
		GREY: 'grey',
		RED: 'red',
		BLUE: 'blue',
        GREEN: 'green',
		YELLOW: 'yellow',
		ORANGE: 'orange'
	};

	return (
		<td>
			<div className="table-cell table-cell-tag">
				{props.children.map((t, i) => {
					let out = t;
					let classes = ['table-tag'];
					if(props.labels && props.labels[out]) {
						let color = tagColors[props.labels[out].color];
						out = props.labels[out].label;
						classes = [...classes, color, 'circle'];
					} 
					if(props.format === 'short') {
						out = t[0];
						classes = [...classes, 'short-table-tag'];
					} 
					return <span key={i} className={`${classes.join(' ')}`}>{out}</span>
				})}
				
			</div>
		</td>
	);
};

TagCell.propTypes = {
	children: PropTypes.arrayOf(PropTypes.string),
	format: PropTypes.oneOf(['short', 'long']),
	labels: PropTypes.object
};

TagCell.defaultProps = {
	children: [],
	format: 'long',
	labels: null
};

const AmountCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-amount'>
				<div className='table-cell-amount__info'>
					<span className='table-cell-amount__info__amount'>{props.children}</span>
					{props.type && <span className='table-cell-amount__info__currency'>{props.type.toLowerCase()}</span>}
				</div>
			</div>
		</td>
	);
};

AmountCell.propTypes = {
	type: PropTypes.string
};

AmountCell.defaultProps ={
	type: ''
};

const LastSeenCell = (props) => {
	return (
		<td>
			<div className='table-cell table-cell-last-seen'>
				{dateFormatter.timeBetween(props.children)}
			</div>
		</td>
	);
};

LastSeenCell.propTypes = {
	children: PropTypes.instanceOf(Date)
};

LastSeenCell.defaultProps = {
	children: new Date()
};

DataTable.Cell = Cell;
DataTable.UserCell = UserCell;
DataTable.DateCell = DateCell;
DataTable.IdCell = IdCell;
DataTable.StatusCell = StatusCell;
DataTable.ValueCell = ValueCell;
DataTable.ActionCell = ActionCell;
DataTable.VendorCell = VendorCell;
DataTable.ProductCell = ProductCell;
DataTable.PriceCell = PriceCell;
DataTable.LocationCell = LocationCell;
DataTable.HistoryDateCell = HistoryDateCell;
DataTable.InStockCell = InStockCell;
// DataTable.BadgeCell = BadgeCell;
DataTable.TagCell = TagCell;
DataTable.AmountCell = AmountCell;
DataTable.LastSeenCell = LastSeenCell;

export {
    Paging
};

export default DataTable;