// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// components
import Checkbox from './index';

const CheckboxGroup = (props) => {
	const onChange = (e) => {
		props.onChange([
			...props.values.map(cb => {
				if(cb.value === e.target.value) {
					cb.selected = !cb.selected
				}
				return cb;
			})
		]);
	}

	return (
		<>
			{props.values.map((value, i) => (
				<Checkbox
					key={i}
					label={value.label}
					name={`${props.name}[]`}
					value={value.value}
					disabled={props.disabled}
					checked={value.selected}
					onChange={onChange}
				/>
			))}
		</>
	)
};

CheckboxGroup.propTypes = {
	disabled: PropTypes.bool,
	name: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
		selected: PropTypes.bool
	})),
	onChange: PropTypes.func
};

CheckboxGroup.defaultProps = {
	disabled: false,
	name: '',
	values: [],
	onChange: () => {}
};

export default CheckboxGroup;