// vendor imports
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// components
import { SingleImage } from './singleImage';
// styles
import './style.css';

export const GalleryManager = (props) => {
	let images = [];
	const fileInputRef = useRef();

	const handleFiles = (files) => {
		const filesArray = Array.from(files).map(file =>
			URL.createObjectURL(file)
		);
		images = filesArray.map(item => {
			return {
				url: item,
				title: "",
				cover: false
			}
		});
		props.setImages(prevState => prevState.concat(images));
		Array.from(files).map(file =>
			URL.revokeObjectURL(file)
		);
	};

	const handleFilesSelected = () => {
		if (fileInputRef.current.files) {
			handleFiles(fileInputRef.current.files);
		}
	};

	const dragOver = (e) => {
		e.preventDefault();
		e.target.classList.add('color--darker');
	};

	const dragOverImages = (e) => {
		e.preventDefault();
	}

	const dragEnter = (e) => {
		e.preventDefault();
	};

	const dragLeave = (e) => {
		e.preventDefault();
		e.target.classList.remove('color--darker');
	};

	const dragLeaveImages = (e) => {
		e.preventDefault();
	};

	const fileDrop = (e) => {
		e.preventDefault();
		const files = e.dataTransfer.files;
		if (files) {
			handleFiles(files);
		}
	};

	const removeImage = (indexToRemove) => {
		props.setImages([...props.images.filter((img, index) => index !== indexToRemove)]);
	};

	const selectCover = (index, cover) => {
		props.setImages(props.images.map((img, i) =>
			index === i ? { ...img, cover: !cover } : { ...img, cover: false }
		));
	};

	const changeTitle = (index, title) => {
		props.setImages(props.images.map((img, i) =>
			index === i ? { ...img, title: title } : img
		));
	};

	return (
		<>
			{props.images.length !== 0 ?
				<div className='wrapper-images-container'
					onDragOver={dragOverImages}
					onDragEnter={dragEnter}
					onDragLeave={dragLeaveImages}
					onDrop={fileDrop}
				>
					<div className='images-container'>
						{props.images.map((image, index) => (
							<SingleImage
								index={index}
								image={image}
								selectCover={selectCover}
								removeImage={removeImage}
								title={image.title}
								changeTitle={changeTitle}
								key={index}
							/>
						))}
					</div>
					<div className='upload-images__description'>
						<span className='images__length'>{props.images.length} images</span>
						<span className='images__drop'>Drop images in the box to upload</span>
					</div>
				</div>
				:
				<div className='wrapper-add-image' >
					<label className='button-add-image'
						onDragOver={dragOver}
						onDragEnter={dragEnter}
						onDragLeave={dragLeave}
						onDrop={fileDrop}
					>
						<span className="input-text">Click or drop here for image upload</span>
						<input
							type="file"
							name="file"
							ref={fileInputRef}
							accept="image/*"
							className='image-btn'
							title=""
							multiple
							onChange={handleFilesSelected}
						/>
					</label>
				</div>
			}
		</>
	);
};

GalleryManager.propTypes = {
	images: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string,
		cover: PropTypes.bool,
		title: ''
	})),
	setImages: PropTypes.func.isRequired
};

GalleryManager.defaultProps = {
	images: [],
	//need to add defaultProps for setImages, ask and check
};