// vendor imports
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

const AppRoute = ({ route, ...rest }) => {
    const userData = useSelector(state => state.auth.user);
    let Component = route.component;
    return (
        <Route {...rest} path={route.path} exact={route.exact} render={
            (props) => {
                if (route.acl.includes('*') || route.acl.filter(r => userData.role.includes(r)).length > 0) {
                    return <Component {...props} {...rest} />;
                } 
                return <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
            }
        } />
    );
};

AppRoute.displayName = 'AppRoute';

export default AppRoute;