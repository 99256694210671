//vendor imports
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//ui components
import Icon from 'com/ui/Icon';
import ActionsMenu from 'com/widgets/ActionsMenu';
//components
import Echo from 'com/util/Echo';
//styles
import "./style.css";

const DetailsItemCard = (props) => {
    return (
        <div className="details-item-card">
            <div className="details-item-card__header">
                <Icon name="pages" color="secondary" size="smaller" type="fill"/>
                <span className="details-item-card__header-title">{props.title}</span>
            </div>
            <Link to={props.link} className="details-item-card__wrapper">
                <div className="details-item-card__content">
                    {props.children}
                </div>
                {
                    props.badges ? (
                        <div className="details-item-card__content-badges">
                            {props.badges.map((a, i) => {
                                return (
                                    <span key={i}>
                                        <Icon size="small" type="fill" name={a.icon} color={a.color}/>
                                    </span>
                                );
                            })}
                        </div>
                    ) :
                    null
                }
            </Link>
            <div className="details-item-card__footer">
                <div className="details-item-card__footer-left">
                    <span className="details-item-card__footer-left__label">{props.footerLabel}</span>
                    <span className="details-item-card__footer-left__date"><Echo.Date locale="en-GB" style="full">{props.date}</Echo.Date></span>
                </div>
                <ActionsMenu actions={props.actions}/>
            </div>
        </div>
    );
};

DetailsItemCard.propTypes = {
    link: PropTypes.string,
    footerLabel: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    badges: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        color: PropTypes.oneOf(['primary', 'lightgrey']),
    })),
    title: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        action: PropTypes.func,
        icon: PropTypes.string
    }))
};

DetailsItemCard.defaultProps =  {
    link: '/',
    footerLabel: '',
    date: new Date(),
    footerActions: [],
    title: '',
    actions: []
};

export default DetailsItemCard;
