//vendors
import React from "react";
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from "com/templates/ListViewTemplate";
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
//ui compoonents
import { Paging } from "com/ui/DataTable";
import Button from "com/ui/Button";
//widgets
import StatsCarousel from 'com/widgets/StatsCarousel';
import DetailsItemCard from "com/widgets/DetailsItemCard";
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
//services
import useDataTable from 'services/hooks/use_datatable';
import strings from 'services/util/strings';
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
//styles
import './style.css';

const PagesFormsPages = (props) => {

    const headerNav = [
		{ to: '/pagesforms', label: 'Dashboard' },
		{ to: '/pagesforms/pages', label: 'Pages' },
		{ to: '/pagesforms/forms', label: 'Forms' },
	];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const initFilterFields = [
        {title: 'Title', field: 'title', type: 'string', sort: 'none', sortable: true},
        {title: 'Content', field: 'content', type: 'string', sort: 'none', sortable: true }
    ];

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initFilterFields, 
        'pages.getAllV2'
    );

    const optionOne = () => {
        console.log("Option 1 clicked");
    };

    const optionTwo = () => {
        console.log("Option 2 clicked");
    };

    const optionThree = () => {
        console.log("Option 3 clicked");
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    let actions = [
        {label: 'option 1', action: optionOne},
        {label: 'option 2', action: optionTwo},
        {label: 'option 3', action: optionThree},
    ];

    return (
        <ApplicationTemplate
            headerTitle="Pages &amp; Forms"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <StatsCarousel>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                    </StatsCarousel>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                         dataTypes={tableHeaders}
                         filterOptions={data}
                         tableName="pages_details"
                         onApply={filterApply}
                    />
                    <Button 
                        customClassName="create-page"
                        icon={ICON_PLUS}
                        type="secondary"
                    >
                        Create new
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <ul className="pages-card-container">
                        {tableData.map(p => {
                            return (
                                <li className="pages-card-container__details-card">
                                    <DetailsItemCard
                                        title={p.title}
                                        actions={actions}
                                        link={p.permalink}
                                        date={p._modified}
                                        footerLabel={p._created === p._modified ? 'Created' : 'Last Modified'}
                                        key={p.id}
                                        >
                                        {strings.stripTags(p.content)}
                                    </DetailsItemCard>
                                </li>
                            )
                        })}
                    </ul>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default PagesFormsPages;