// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// styles
import './style.css';

const LoginBox = (props) => {
    return (
        <div className="login-box">
            <div className="login-box__header">
                <h2>{props.title}</h2>
                {
                    props.showNavigation
                        ? (
                            <nav>
                                <NavLink to="/" exact>Sign in</NavLink>
                                {/* <NavLink to="/create-account">Create account</NavLink> */}
                                <NavLink to="/request-access">Request access</NavLink>
                                <NavLink to="/forgot-password">Forgot password</NavLink>
                            </nav>
                        )
                        : null
                }
            </div>
            <div className="login-box__body">
                {props.children}
            </div>
        </div>
    );
};

LoginBox.propTypes = {
    title: PropTypes.string,
    showNavigation: PropTypes.bool
};

LoginBox.defaultProps = {
    title: 'Welcome',
    showNavigation: true
};

export default LoginBox;