import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import { GalleryManager } from 'com/ui/GalleryManager';

const DemoGalleryManager = (props) => {

    const [images, setImages] = useState([
        { 
            url: 'https://oblax.io/assets/images/screenshots/backoffice/dashboard.jpg', 
            cover: true, 
            title: 'Some title 1'
        },
        { 
            url: 'https://oblax.io/assets/images/screenshots/backoffice/products_filter.jpg', 
            cover: false, 
            title: 'Some title 2' 
        },
        { 
            url: 'https://oblax.io/assets/images/screenshots/backoffice/product_details.jpg', 
            cover: false, 
            title: 'Some title 3' 
        }
    ]);

    return (
        <ComponentDemoTemplate>
            <>
                <GalleryManager
                    images={images}
                    setImages={setImages}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoGalleryManager;