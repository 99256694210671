import React from 'react';
import PropTypes from 'prop-types';
// styles
import './style.css';

const Checkbox = (props) => {

    let setDisabled = props.disabled ? 'disabled' : '';

    return (
        <label className={`checkbox ${setDisabled}`}>
            <input
                className="checkbox__input"
                type="checkbox"
                name={props.name}
                value={props.value}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
            />
            <span className="checkbox__checker"></span>
            <span className="checkbox__label">{props.label}</span>
        </label>
    )
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
};

Checkbox.defaultProps = {
    checked: false,
    disabled: false,
    label: '',
    name: '',
    onChange: () => {},
    value: ''
};

export default Checkbox;