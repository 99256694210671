//vendor imports
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//components
import SectionTitle from "com/ui/SectionTitle";
import ActivityCard from "com/widgets/ActivityCard";
import Avatar from "com/widgets/Avatar";
import Icon from "com/ui/Icon";

//styles
import './style.css';


const CardsList = (props) => {

	const [usersData, setUsersData] = useState([
		{
			id: 1,
			picture: "",
			name: 'Joshua Murray',
			email: 'joshua@murray.com',
			city: 'Skopje',
			country: 'Republic of Macedonia',
			role: "user",
			status: "Registration Form"
		},
		{
			id: 2,
			picture: "",
			name: 'Nike Corp',
			email: 'contact@nike.com',
			city: 'Chicago',
			country: 'United States',
			role: "user",
			status: "Registration Form"
		},
		{
			id: 3,
			picture: "",
			name: 'Brad Stevans',
			email: 'brad@stevans.com',
			city: 'New York',
			country: 'United States',
			role: "user",
			status: "Internal Input"
		},
		{
			id: 4,
			picture: "",
			name: 'Adidas',
			email: 'contact@adidas.com',
			city: 'Berlin',
			country: 'Germany',
			role: "user",
			status: "Registration Form"
		},
		{
			id: 5,
			picture: "",
			name: 'Puma',
			email: 'contact@puma.com',
			city: 'Gothenburg',
			country: 'Sweden',
			role: "user",
			status: "Registration Form"
		},
	]);

	const [loading, setLoading] = useState(true);

    // the emptyData state and the useEffect are ONLY for testing purposes

    const [emptyData, setEmptyData] = useState([]);

    useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 6000);
	}, []);

	return (
		<div className="cards-list">
			<div className="cards-list__header">
				<SectionTitle icon="chat-poll">Latest registrations</SectionTitle>
				<Link className="card-list__header-button">
					<Icon name="arrow-right-s" color="white" type="line" size="larger" />
				</Link>
			</div>
			<div className="cards-list__wrapper">
				{
					loading ?
					<LoadingData/> :
					<CardData data={usersData}/>
				}
			</div>
		</div>
	);
};

const LoadingData = () => {
	return (
		<>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
		</>
	);
};

const CardData = (props) => {
	const date = new Date();
	return (
		props.data.length > 0 ?
		props.data.map((u, i) => {
			let color = u.status == "Registration Form" ? "blue" : "purple";
			return (
				<ActivityCard
					icon="team-fill "
					status={u.status}
					statusColor={color}
					action="Contact Details"
					date={date}
				>
					<ActivityCard.Body>
						<ActivityCard.Left>
							<div className="cards-list__user-picture"><Avatar src={u.picture} /></div>
							<div className="cards-list__user-details">
								<span className="cards-list__user-details-name">{u.name}</span>
								<span className="cards-list__user-details-email">{u.email}</span>
							</div>
						</ActivityCard.Left>
						<ActivityCard.Right>
							<Icon name="map-pin" color="secondary" type="fill" size="large" />
							<div className="cards-list__user-location">
								<span className="cards-list__user-location-city">{u.city}</span>
								<span className="cards-list__user-location-country">{u.country}</span>
							</div>
						</ActivityCard.Right>
					</ActivityCard.Body>
				</ActivityCard>
			);
		}) :
		<ActivityCard.NoData/>
	);
};

export default CardsList;