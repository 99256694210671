import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import PopoutInput from 'com/ui/PopoutInput';

const DemoPopoutInput = (props) => {

    const [popoutInput, setPopoutInput] = useState('Some pink cubes');

    return (
        <ComponentDemoTemplate>
            <>
                <PopoutInput
                    value={popoutInput}
                    onChange={setPopoutInput}
                    placeholder="Text description"
                    disabled={false}
                />
                <hr/>
                <PopoutInput
                    value={popoutInput}
                    onChange={setPopoutInput}
                    placeholder='Text description'
                    error="Error has happened!"
                    disabled={false}
                />
                <hr />
                <PopoutInput
                    value={popoutInput}
                    onChange={setPopoutInput}
                    placeholder="Text description"
                    disabled={true}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoPopoutInput;