import React, { useState } from 'react';
import { connect } from 'react-redux';

import Menu from './menu';
import Icon from 'com/ui/Icon';

import './style.css';

const NotificationsMenu = (props) => {
    const [menuVisibility, setMenuVisibility] = useState(false);
    return (
        <button 
            className="notifications-menu" 
            onBlur={() => setMenuVisibility(false)}
            onClick={() => setMenuVisibility(!menuVisibility)}
        >
            <Icon
                name="notification-3"
                color="grey"
                size="large"
            />
            { // show or hide the menu
                menuVisibility 
                ? <Menu items={props.notifications}/> 
                : null
            }
            { // show or hide the notifications badge
                props.notifications.length > 0
                ? <span className="notifications-menu__badge">{props.notifications.length}</span>
                : null
            }   
        </button>
    );
};

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications
    };
}

export default connect(mapStateToProps)(NotificationsMenu);