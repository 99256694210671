//vendors
import React, { useState } from "react";
//ui components
import Button from "com/ui/Button";
//widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
//compoonents
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
import Carousel from "./Carousel";
import ItemCard from "com/widgets/ItemCard";
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
import ICON_COMPANY from 'assets/images/logo_login_text.svg';
//styles
import './style.css';
//fake data
import comapnies from 'data/companies.fake';

const ContactsCompanies = (props) => {

    const headerNav = [
		{ to: '/contacts', label: 'Dashboard' },
		{ to: '/contacts/people', label: 'People' },
		{ to: '/contacts/companies', label: 'Companies' },
	];

    const initFilter = {
        active: [
            {condition: 'list_not_equals', v1: '0', v2: ''},
        ],
        created: [
            {condition: 'date_not_equals', v1: '', v2: ''},
            {condition: 'date_between', v1: '', v2: ''}
        ],
        order_id: [
            { condition: 'string_contains', v1: 'sdf', v2: undefined },
            { condition: 'string_equals', v1: 'ffffffff', v2: undefined },
            { condition: 'string_equals', v1: 'asdf', v2: undefined }
        ]
    };

    const [filter, setFilter] = useState(initFilter);

    const [tableHeaders, setTableHeaders] = useState([
        { title: 'Company', field: 'company', type: 'string', sort: 'none', sortable: true },
        { title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
        { title: 'Registaration Date', field: 'registration_date', type: 'date', sort: 'none', sortable: true } ,
        { title: 'Last Login', field: 'last_login', type: 'date', sort: 'none', sortable: true },
        { title: 'E-mail', field: 'email', type: 'string', sort: 'none', sortable: true },
        { title: 'Country', field: 'country', type: 'string', sort: 'none', sortable: true },
        { title: 'Acct.Provider', field: 'acct_provider', type: 'string', sort: 'none', sortable: true },
        { title: 'Role', field: 'role', type: 'string', sort: 'none', sortable: true },
    ]);
    
    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    const [companiesData, setCompaniesData] = useState(comapnies);

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];

    return (
        <ApplicationTemplate
            headerTitle="Contacts"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <Carousel/>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="people"
                        onChange={setFilter}
                    />
                    <Button
                        type="secondary"
                        icon={ICON_PLUS}
                        customClassName="companies__create-new"
                    >
                        Create New
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <div className="companies-container">
                        {companiesData.map((c, i) => {
                            return (
                                <ItemCard
                                    link="/"
                                    role={c.category}
                                    image={ICON_COMPANY}
                                    title={c.name}
                                    details={`${c.contacts} contacts`}
                                    subtitle={`${c.country}, ${c.city}`}
                                    footerLabel="Last modified"
                                    date={c.registration_date}
                                    actions={actions}
                                    customClassName="companies__item-card"
                                />
                            )
                        })}

                    </div>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default ContactsCompanies;