//vendor imports
import React, { useState, useEffect } from 'react';
import oblax from 'services/oblax';
import { AccountRequest } from '@oblax/sdk-js';
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
//ui components
import DataTable, { Paging } from 'com/ui/DataTable';
import Button from 'com/ui/Button';
//widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
import Modal from "com/widgets/Modal";
import UsersStatsCarousel from 'com/widgets/StatsCarousel/UsersStatsCarousel'; 
//services
import useNotification from 'services/hooks/use_notification';
import l11n from 'services/l11n';
//styles
import './style.css';

import useDataTable from 'services/hooks/use_datatable';

const UsersAccessRequests = (props) => {

    const headerNav = [
        { to: '/users', label: 'Dashboard' },
        { to: '/users/accounts', label: 'Accounts' },
        { to: '/users/access-requests', label: 'Access Requests' },
    ];

    const initTableHeaders = [
        { title: 'Name', field: 'user_name', type: 'string', sort: 'none', sortable: true },
        { title: 'Request Date', field: 'request_date', type: 'date', sort: 'none', sortable: true },
        { title: 'Location', field: 'location', type: 'string', sort: 'none', sortable: true },
        { title: 'Actions', field: 'actions', type: 'string', sort: 'none', sortable: true },
    ];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: [{type: 'eq', field: 'status', value: 'PENDING'}]
       
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    }; 

    const [isModalApproveOpen, setIsModalApproveOpen] = useState(false);
    const [isModalRejectOpen, setIsModalRejectOpen] = useState(false);
    const [storedUser, setStoredUser] = useState({});
    const [showLoader, setShowLoader] = useState(false);

    const STATUS_ACCEPTED = 'ACCEPTED';
    const STATUS_DENIED = 'DENIED';

    const sendNotification = useNotification();

    const openApproveModal = (e) => {
        setIsModalApproveOpen(true);
        const uid = e.target.closest('tr').dataset.rowId;
        const user = tableData.find(u => u.id === uid);
        setStoredUser(user);
    };

    const openRejectModal = (e) => {
        setIsModalRejectOpen(true);
        const uid = e.target.closest('tr').dataset.rowId;
        const user = tableData.find(u => u.id === uid);
        setStoredUser(user);
    };

    const handleCloseApproveModal = () => {
        setIsModalApproveOpen(false);
    };

    const handleCloseRejectModal = () => {
        setIsModalRejectOpen(false);
    };

    const handleApprove = async (e) => {
        setShowLoader(true)
        try {
            let data = {
                first_name: storedUser.first_name,
                last_name: storedUser.last_name,
                email: storedUser.email,
                status: STATUS_ACCEPTED,
                role: 'user'
            };
            let ac = new AccountRequest();
            ac.loadFromJSON(data);
            let res = await oblax.auth.updateAccessApplication(storedUser.id , ac);
            if (res.ok) {
                setShowLoader(false)
                setIsModalApproveOpen(false);
                sendNotification({ type:'success', title: l11n.t('APPROVED_SUCCESS')});
                setStoredUser({});
                filterApply({status:[{condition:'string_equals', v1:'PENDING'}]});
            }
        } catch (err) {
            if (process.env.REACT_APP_ENV != 'prod') {
                console.log(err);
            }
            sendNotification({ type:'error', title: l11n.t('APPROVED_FAILURE')});
            setShowLoader(false);
        } 
    };

    const handleReject = async (e) => {
        setShowLoader(true)
        try {
            let data = {
                first_name: storedUser.first_name,
                last_name: storedUser.last_name,
                email: storedUser.email,
                status: STATUS_DENIED,
                role:'user' 
            };
            let ac = new AccountRequest();
            ac.loadFromJSON(data);
            let res = await oblax.auth.updateAccessApplication(storedUser.id , ac);
            console.log(res);
            if (res.ok) {
                setShowLoader(false)
                setIsModalRejectOpen(false);
                sendNotification({ type:'success', title: l11n.t('REJECT_SUCCESS')});
                setStoredUser({});
                filterApply({status:[{condition:'string_equals', v1:'PENDING'}]});
            }
        } catch (err) {
            if (process.env.REACT_APP_ENV != 'prod') {
                console.log(err);
            }
            sendNotification({ type:'error', title: l11n.t('REJECT_FAILURE')});
            setShowLoader(false);
        }
    };

    const footerActionsApprove = [
        {
            label: 'Cancel',
            primary: false,
            action: handleCloseApproveModal
        },
        {
            label: 'Approve',
            primary: true,
            showLoader: showLoader,
            action: handleApprove
        }
    ];

    const footerActionsReject = [
        {
            label: 'Cancel',
            primary: false,
            action: handleCloseRejectModal
        },
        {
            label: 'Reject',
            primary: true,
            showLoader: showLoader,
            action: handleReject
        }
    ];

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange, 
        columnSort,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initTableHeaders, 
        'auth.getAllAccessApplicationsV2'
    );

    return (
        <ApplicationTemplate
            headerTitle="Users"
            headerNavigation={headerNav}
         >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <UsersStatsCarousel />
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="access_requests"
                        onApply={filterApply}
                    />
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                        {tableData.map((u,i) => {
                            return (
                                <tr key={u.id} data-row-id={u.id}>
                                    <DataTable.UserCell avatar='' city={u.email}>{u.first_name} {u.last_name}</DataTable.UserCell>
                                    <DataTable.DateCell>{u._created}</DataTable.DateCell>
                                    <DataTable.LocationCell>{u.city}, {u.country}</DataTable.LocationCell>
                                    <DataTable.Cell>
                                        <div className="user-access-request__actions">
                                            <Button type="ghost-approve" onClick={openApproveModal}>approve</Button>
                                            <Button type="ghost-reject" onClick={openRejectModal}>reject</Button>
                                        </div>
                                    </DataTable.Cell>
                                </tr>
                            )
                        })}
                    </DataTable>
                    <Modal 
                        open={isModalApproveOpen}
                        footerActions={footerActionsApprove}
                        onClose={handleCloseApproveModal}
                        title="Confirmation needed"
                    >
                        {`Click Approve to allow ${storedUser.first_name} ${storedUser.last_name} to access the app`}
                    </Modal>
                    <Modal 
                        open={isModalRejectOpen}
                        footerActions={footerActionsReject}
                        onClose={handleCloseRejectModal}
                        title="Confirmation needed"
                    >
                        {`Click  Reject to reject ${storedUser.first_name} ${storedUser.last_name} to access the app`}
                    </Modal>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default UsersAccessRequests;