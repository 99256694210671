import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Popover from './popover';
import TagList from './tagList';

import Button from 'com/ui/Button';

import oblax from 'services/oblax';

import ICON_FILTER from 'assets/images/icons/btn-filter-white.svg';

import './style.css';
// 
import { FilterContextProvider } from './context';

const PopoutDataFilter = (props) => {

    const [active, setActive] = useState(false);
    const [savedFilters, setSavedFilters] = useState([]);
    const popoverRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                let filters = await oblax.dataFilter.getDataFilters(props.tableName);
                setSavedFilters(filters.data);
            } catch(err) {
                console.log(err);
            }
        })();
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (checkIfOrphan(e.target) && popoverRef.current && !popoverRef.current.contains(e.target)) {
            setActive(false);
        }
    };

    const checkIfOrphan = (node) => {
        if(node) {
            if(node.nodeName !== 'BODY') return checkIfOrphan(node.parentNode);
            return true;
        }
        return false;
    };

    const onButtonClick = () => {
        setActive(state => !state);
    };

    const popoverSetActive = (a) => {
        setActive(a);
    };

    return (
        <div className="popout-data-filter">
            <FilterContextProvider 
                filterOptions={props.filterOptions} 
                dataTypes={props.dataTypes}
                onApply={props.onApply}
                savedFilters={savedFilters}
                popoverSetActive={popoverSetActive}
                tableName={props.tableName}
            >
                <div className="popout-data-filter__button-wrap" ref={popoverRef}>
                    <Button 
                        icon={ICON_FILTER} 
                        // badge={tags.length} 
                        onClick={onButtonClick}
                    >
                        Filters
                    </Button>
                    { active ? <Popover/> : null }
                </div>
                <div className="popout-data-filter__tags-wrap">
                    <TagList/>
                </div>
            </FilterContextProvider>
        </div>
    );
};

PopoutDataFilter.propTypes = {
    dataTypes: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        field: PropTypes.string,
        type: PropTypes.oneOf(['string', 'number', 'date', 'list'])
    })),
    tableName: PropTypes.string.isRequired
};

PopoutDataFilter.defaultProps = {
    dataTypes: []
};

export default PopoutDataFilter;