// The QS Format
// {type: 'like', field: 'first_name', value: '*jan*'}

const convertToQSFormat = (data) => {
    let out = [];
    Object.keys(data).forEach(k => data[k].forEach((f) => {
        let typeData = parseType(f);
        if(typeData.length === 2 && typeData[1]) {
            out = [ 
                ...out,  
                { type: typeData[0], field: k, value: typeData[1] }
            ];
        } else if(typeData.length === 4 && typeData[1] && typeData[3]) {
            out = [ 
                ...out,  
                { type: typeData[0], field: k, value: typeData[1] },
                { type: typeData[2], field: k, value: typeData[3] }
            ];
        }
    }));
    return out;
};

const parseType = (filter) => {
    switch(filter.condition) {
        case 'number_greater_than':
            return [ 'gt', Number(filter.v1) ];
        case 'number_less_than':
            return [ 'lt', Number(filter.v1) ];
        case 'number_equals':
            return [ 'eq', Number(filter.v1) ];
        case 'number_not_equals':
            return [ 'ne', Number(filter.v1) ];
        case 'number_between':
            return [ 
                'gte', Number(filter.v1), 
                'lte', Number(filter.v2)
            ];
        case 'string_equals':
            return [ 'eq', filter.v1 ];
        case 'string_not_equals':
            return [ 'n2', filter.v1 ];
        case 'string_contains':
            return [ 'like', `*${filter.v1}*` ];
        case 'string_begins_with':
            return [ 'like', `${filter.v1}*` ];
        case 'string_ends_with':
            return [ 'like', `*${filter.v1}` ];
        case 'number_one_of':
            return [ 'in', filter.v1.join(',') ];
        case 'string_one_of':
            return [ 'in', filter.v1.join(',') ];
        case 'date_equals':
            return [ 'eq', dateFormat(filter.v1) ];
        case 'date_greater_than':
            return [ 'gt', dateFormat(filter.v1) ];
        case 'date_less_than':
            return [ 'lt', dateFormat(filter.v1) ];
        case 'date_not_equals':
            return [ 'ne', dateFormat(filter.v1) ];
        case 'date_between':
            return [ 
                'gte', dateFormat(filter.v1), 
                'lte', dateFormat(filter.v2)
            ];
        case 'list_equals':
            return [ 'eq', filter.v1 ];
        case 'list_not_equals':
            return [ 'ne', filter.v1 ];
    }
};

const dateFormat = (date) => {
    let d = new Date(date);
    // 2022-06-25
    let y = d.getFullYear();
    let m = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    let a = d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1;
    return `${y}-${m}-${a}`;
};

export default {
    convertToQSFormat
};