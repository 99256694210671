const initialState = {
	profile: {
		active: false,
		confirmed: false,
		email: "",
		first_name: "",
		last_name: "Gavrovski",
		gender: "",
		id: "",
		phone: "",
		picture: "",
		role: "",
	}
};

// constants
const USER_SET_DATA = 'USER_SET_DATA';

// actions
export const setUserData = (data) => {
	return {
		type: USER_SET_DATA,
		payload: data
	};
};

// reducer
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_SET_DATA:
			return { ...state, profile: action.payload };
		default:
			return state;
	}
};

export default {
	setUserData,
	reducer: userReducer
};
