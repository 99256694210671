//vendor imports
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import oblax from 'services/oblax';

//components
import SectionTitle from "com/ui/SectionTitle";
import ActivityCard from "com/widgets/ActivityCard";
import Avatar from "com/widgets/Avatar";
import Icon from "com/ui/Icon";

// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

//styles
import './style.css';

const CardsList = () => {

	const [usersData, setUsersData] = useState([]);
	const sendNotification = useNotification();
	const [emptyData, setEmptyData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				let res = await oblax.stats.signups();
				if (res.ok) {
					setLoading(false);
					setUsersData(res.data);
				}
			} catch (err) {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log(err);
				}	
				sendNotification({ type: 'error', title: l11n.t('ERROR_GET_LATEST_SIGNUPS') });
			} finally {
                setLoading(false);
            }
		})();
	}, []);

	return (
		<div className="cards-list">
			<div className="cards-list__header">
				<SectionTitle icon="team-fill ">Latest signups</SectionTitle>
				<Link to="/" className="card-list__header-button">
					<Icon name="arrow-right-s" color="white" type="line" size="larger" />
				</Link>
			</div>
			<div className="cards-list__wrapper">
				{
					loading ?
					<LoadingData/> :
					<CardData data={usersData}/>
				}
			</div>
		</div>
	);
};

const LoadingData = () => {
	return (
		<>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
		</>
	);
};

const CardData = (props) => {
	return (
		props.data.length > 0 ?
		props.data.map((user, i) => {
			let color = user.role == "user" ? "blue" : "purple";
			return (
				<ActivityCard
					icon="team-fill "
					status={user.role}
					statusColor={color}
					action="User Details"
					date={user._created}
				>
					<ActivityCard.Body>
						<ActivityCard.Left>
							<div key={i} className="cards-list__user-picture">
								<Avatar src={user.picture} />
							</div>
							<div className="cards-list__user-details">
								<span className="cards-list__user-details-name">
									{`${user.first_name} ${user.last_name}`}
								</span>
								<span className="cards-list__user-details-email">
									{user.email}
								</span>
							</div>
						</ActivityCard.Left>
						<ActivityCard.Right>
							<Icon name="map-pin" color="secondary" type="fill" size="large" />
							<div className="cards-list__user-location">
								<span className="cards-list__user-location-city">
									{user.city}
								</span>
								<span className="cards-list__user-location-country">
									{user.country}
								</span>
							</div>
						</ActivityCard.Right>
					</ActivityCard.Body>
				</ActivityCard>
			);
		}) :
		<ActivityCard.NoData/>
	);
};

export default CardsList;