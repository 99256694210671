// vendor imports 
import React, { useState, useEffect } from "react";
import oblax from 'services/oblax';
import { Link } from "react-router-dom";

// components
import StatTableCard from "com/widgets/StatTableCard";
import Icon from "com/ui/Icon";

// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

// styles
import './style.css';

const StatsOrdersPipeline = (props) => {

	const [loading, setLoading] = useState(true);
	const [productsData, setProductsData] = useState([]);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			try {
				let res = await oblax.stats.ordersPipeline();
				if (res.ok) {
					setLoading(false);
					setProductsData(res.data);
				}
			} catch (err) {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log(err);
				}
				sendNotification({ type: 'error', title: l11n.t('ERROR_GET_ORDERS_PIPELINE') });
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return (
		<StatTableCard title="Orders pipeline" icon="bar-chart-horizontal-fill ">
			{
				loading ?
					<StatTableCard.Loader cols={2} rows={5} /> :
					<TableData data={productsData} />
			}
		</StatTableCard>
	);
};

const TableData = (props) => {

	const orderIcons = {
		NEW: "file-text",
		PROCESSING: "archive",
		SHIPPED: "truck",
		DELIVERED: "star"
	};

	return (
		props.data.length > 0 ?
			props.data.map((p, i) => {
				let icon = orderIcons[p.order_status];
				return (
					<tr key={i} className="pipeline_stats">
						<td className="stat-tables__container-borderless">
							<div className="order-pipeline__wrapper">
								<div className="order-pipeline__wrapper-details">
									<Icon name={icon} color="primary" type="fill" size="smaller" />
									<span className="order-pipeline__status">{l11n.t(`${p.order_status}`)}</span>
								</div>
								<div className="order-pipeline__status-details">
									<span className="order-pipeline__amount">{p.orders}</span>
									<div className="order-pipeline__product-details">
										<div className="order-pipeline__product-amount">
											<span className="order-pipeline__product-num">{p.products}</span>
											<span className="order-pipeline__product-tag"> products</span>
										</div>
										<div className="order-pipeline__product-worth">
											<span className="order-pipeline__product-sum">{p.total_price}</span>
											<span className="order-pipeline__product-curr"> {p.currency}</span>
										</div>
										<Link to="/ecommerce" className="order-pipeline__link">
											<Icon
												name="arrow-right-s"
												color="grey"
												size="larger"
												type="line"
											/>
										</Link>
									</div>
								</div>
							</div>
						</td>
					</tr>
				);
			}) :
			<StatTableCard.NoData colspan={1} />
	);
};

export default StatsOrdersPipeline;