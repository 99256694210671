const TOASTS_ADD = 'TOASTS_ADD';
const TOASTS_REMOVE = 'TOASTS_REMOVE';
const TOASTS_REMOVE_ALL = 'TOASTS_REMOVE_ALL';

const initialState = [ 
//    {
//        id: 123,
//        type: 'info',
//        title: 'Info title',
//        timeout: 2000
//    },
//    {
//        id: 234,
//        type: 'error',
//        title: 'An error occured',
//        timeout: 3000
//    },
//    {
//         id: 567,
//         type: 'warning',
//         title: 'Warning title',
//         timeout: 4000
//     },
//     {
//         id: 789,
//         type: 'success',
//         title: 'Success title',
//         timeout: 5000
//     },
//     {
//         id: 321,
//         type: 'rich',
//         title: 'Title 1',
//         content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ',
//         actions: []
//     }, 

//     {
//         id: 543,
//         type: 'rich',
//         title: 'Title 2',
//         content: 'There are many variat, but the majority have suffered alteration in some form, by injected humour.',
//         actions: []
//     },

//     {
//         id: 765,
//         title: 'Title 3',
//         type: 'rich',
//         content: 'When our power of choice is untrammelled and when nothing prevents our being able to do what we like best',
//         actions: []
//     },
//     {
//         id: 987,
//         title: 'Title 4',
//         type: 'rich',
//         content: ' These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best',
//         actions: []
//     },
];

export const toastsAdd = (data) => {
    return {
        type: TOASTS_ADD,
        payload: data
    }
};

export const toastsRemove = (id) => {
    return {
        type: TOASTS_REMOVE,
        payload: id
    }
};

export const toastsRemoveAll = () => {
    return {
        type: TOASTS_REMOVE_ALL
    }
};

const notificationToastsReducer = (state = initialState, action) => {
    switch(action.type) {
        case TOASTS_ADD: 
            return [ 
                ...state,
                {
                    id: Date.now(),
                    ...action.payload
                }
            ];
        case TOASTS_REMOVE:
            return state.filter(t => t.id !== action.payload);
        case TOASTS_REMOVE_ALL:
            return [];
        default: 
            return state
    }
};

export default {
    toastsAdd,
    toastsRemove,
    toastsRemoveAll,
    reducer: notificationToastsReducer
};

