// vendor imports
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import oblax from 'services/oblax';
// components
import PublicTemplate from 'com/templates/PublicTemplate';
import LoginBox from 'com/templates/LoginBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Checkbox from 'com/ui/Checkbox';
import Error from 'com/ui/Error';
// services
import L11n from 'services/l11n';
// constants
import { LOGIN_SCHEMA } from 'schemas/loginSchema';
// styles
import './style.css';

const Login = () => {
	const initCredentials = {email: '', password: ''};
	const initErrors = {email: '', password: ''};
	const history = useHistory();
	const [credentials, setCredentials] = useState(initCredentials);
	const [errors, setErrors] = useState(initErrors);
	const [xhttpError, setXhttpError] = useState('');
	const [showLoader, setShowLoader] = useState(false);

	const onChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value
		});
	};

	const onSubmit = async () => {
		if (!validateForm() || showLoader) return;
		setShowLoader(true);
		try {
			let res = await oblax.auth.login(credentials.email, credentials.password);
			if (res.ok) {
				history.push('/');
			}
		} catch (err) {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log(err);
			}
			setXhttpError(L11n.t(`ERROR_${err.data.type}`));
			setShowLoader(false);
		}
	};

	const validateForm = () => {
		setErrors(initErrors);
		let data = { email: credentials.email, password: credentials.password };
		let validate = oblax.validator.validate(LOGIN_SCHEMA, data);
		if (!validate) {
			let errs = oblax.validator.getValidationErrors();
			for (let e of errs) {
				setErrors(state => { return { ...state, [e.key]: L11n.t(`ERROR_${e.code}`) } });
			}
		}
		return validate;
	};

	const removeError = () => {
		setXhttpError('');
	};

	return (
		<PublicTemplate>
			<LoginBox title={L11n.t('WELCOME')} showNavigation={true}>
				<Input 
					name="email" 
					type="email" 
					label="Your email address" 
					value={credentials.email} 
					error={errors.email} 
					onChange={onChange} 
				/>
				<Input 
					name="password" 
					type="password" 
					label="Password" 
					value={credentials.password} 
					error={errors.password} 
					onChange={onChange} 
				/>
				<div className="login-footer">
					<Checkbox label="Keep me signed in" />
					<Button onClick={onSubmit} showLoader={showLoader}>Sign in</Button>
				</div>
			</LoginBox>
			{xhttpError ? <Error message={xhttpError} onClick={removeError} /> : null}
		</PublicTemplate>
	);
};

export default Login;