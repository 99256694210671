import React, { useEffect,useState } from 'react';
import oblax from 'services/oblax';
// ui
import Icon from 'com/ui/Icon';
// components
import StatTableCard from 'com/widgets/StatTableCard';
import Avatar from 'com/widgets/Avatar';
// services
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';
// styles
import './style.css';

const StatsBiggestSpenders = (props) => {
    const [biggestSpenders,setBiggestSpenders] = useState([]);
    const [loading, setLoading] = useState(true);
    const biggestSpendersCardHeaders = ['User', 'Monthly total'];
    const sendNotification = useNotification();

    useEffect(() => {
        (async() => {
            try {
                let res = await oblax.stats.usersWithMostPurchases();
                if (res.ok) {
                    setBiggestSpenders(res.data);
                    // this should be uncommented
                    setLoading(false); 
                }
            } catch (err) {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log(err);
                }
                sendNotification({ type:'error', title: l11n.t('ERROR_GET_BIGGEST_SPENDERS')});
            }
        })();
    },[]);

    return (
        <StatTableCard title="Biggest spenders" icon="list-ordered " columns={biggestSpendersCardHeaders}>
            {
                loading ?
                <StatTableCard.Loader cols={2} rows={5}/> :
                <TableData data={biggestSpenders}/>
            }
		</StatTableCard>
    )
};

const TableData = (props) => {
    return (
        props.data.length > 0 ?
        props.data.map((user) => {
            return (
                <tr key={user.id}>
                    <td>
                        <div className="user-data-wrapper">
                            <Avatar src={user.picture} />
                            <div className="user-details">
                                <span className="user-details--full-name">
                                    {`${user.first_name} ${user.last_name}`}
                                </span>
                                <span className="user-details--email">
                                    {user.email}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="user-details--amount-wrapper">
                            <span className="user-details--amount">
                                {user.monthly_total ? user.monthly_total.toLocaleString() : 0}
                            </span>
                            <span className="user-details--currency">
                                {user.currency}
                            </span>
                        </div>
                    </td>
                </tr>
            )
        }) : 
        <StatTableCard.NoData colspan={2}/>
    );
};

export default StatsBiggestSpenders;