import React from "react";
import PropTypes from 'prop-types';
// 
import Icon from "com/ui/Icon";
// services
import l11n from 'services/l11n';
// styles 
import './style.css';

const StatTableCard = (props) => {
    return (
        <div className="stat-table-card">
            <div className="stat-table-card__header">
                <Icon name={props.icon} color="secondary" size="large"/>
                <h2 className="stat-table-card__header__separator">{props.title}</h2>
            </div>
            <table className="stat-table-card__table">
                <thead>
                    <tr>
                        {props.columns.map((c, i) => <th key={i}>{c}</th>)}
                    </tr>
                </thead>
                <tbody>{props.children}</tbody>
            </table>
        </div>
    );
};

StatTableCard.NoData = (props) => {
    return (
        <tr>
            <td colSpan={props.colspan} className="no-data">
                <Icon name="bubble-chart" type="fill" size="biggest" color="lightgrey"/>
                <span className="no-data__text">{l11n.t('NO_DATA')}</span>
            </td>
        </tr>
    );
};

StatTableCard.Loader = (props) => {
    let rows = [];
    for(let i = 0; i < props.rows; i++) {
        let cols = [];
        for(let y = 0; y < props.cols; y++) {
            cols = [
                ...cols, 
                <td key={y}><span className="stat-table-card__animated-fake-content"></span></td>
            ];
        }
        rows = [
            ...rows,
            <tr key={i}>{cols}</tr>
        ];
    }
    return rows;
};

StatTableCard.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string)
};

StatTableCard.defaultProps = {
    icon: 'settings-3',
    title: '',
    columns: []
};

export default StatTableCard;
