export default [
    { name: 'Input', link: '/demo' },
    { name: 'RadioGroup', link: '/demo/radio-group' },
    { name: 'Switch', link: '/demo/switch' },
    { name: 'Checkbox', link: '/demo/checkbox' },
    { name: 'CheckboxGroup', link: '/demo/checkbox-group' },
    { name: 'Textarea', link: '/demo/textarea' },
    { name: 'Tagbox', link: '/demo/tagbox' },
    { name: 'DropdownInput', link: '/demo/dropdown-input' },
    { name: 'PopoutInput', link: '/demo/popout-input' },
    { name: 'Dropdown', link: '/demo/dropdown' },
    { name: 'DatePicker', link: '/demo/datepicker' },
    { name: 'DataTable', link: '/demo/data-table' },
    { name: 'GalleryManager', link: '/demo/gallery-manager' },
    { name: 'Modal', link: '/demo/modal' },
    { name: 'Notifications', link: '/demo/notifications' },
    { name: 'InputTable', link: '/demo/input-table'},
    { name: 'PopoutDataFilter', link: '/demo/popout-data-filter' },
    { name: 'StatsCarousel', link: '/demo/stats' },
    { name: 'StatTableCard', link: '/demo/stat-table-card' },
    { name: 'ContentPanel', link: '/demo/content-panel' },
    { name: 'SectionTitle', link: '/demo/section-title' },
    { name: 'ActivityCard', link: '/demo/activity-card'},
    { name: 'ItemCard', link: '/demo/item-card'},
    { name: 'ActionsMenu', link: '/demo/actions-menu'},
    { name: 'DetailsItemCard', link: '/demo/details-item-card'},
    { name: 'DataTree', link: '/demo/data-tree'},
];