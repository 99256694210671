// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// icons
import DRAG_HANDLE from 'assets/images/ui/dnd-tree-handle.svg';
//styles
import './style.css';

const DataTree = (props) => {

	const dragOver = (e) => {
		e.preventDefault();
		let dt = getDropType(e);
		switch(dt) {
			case 'ABOVE':
				e.target.classList.remove('push-middle');
				e.target.closest('.category-tree__list-item').classList.remove('push-bottom');
				e.target.closest('.category-tree__list-item').classList.add('push-top');
			break;
			case 'BELOW':
				e.target.classList.remove('push-middle');
				e.target.closest('.category-tree__list-item').classList.remove('push-top');
				e.target.closest('.category-tree__list-item').classList.add('push-bottom');
			break;
			case 'IN':
				e.target.closest('.category-tree__list-item').classList.remove('push-top');
				e.target.closest('.category-tree__list-item').classList.remove('push-bottom');
				e.target.classList.add('push-middle');
			break;
		}
	};

	const getDropType = (e) => {
		let rect = e.target.getBoundingClientRect();
		let y = e.clientY - rect.top;  //y position within the element.
		// let x = e.clientX - rect.left;
		if(y < rect.height / 3) {
			return 'ABOVE';
		} else if(y > rect.height / 3 * 2) {
			return 'BELOW';
		} else {
			return 'IN';
		}
	};

	const dragLeave = (e) => {
		e.target.closest('.category-tree__list-item').classList.remove('push-top');
		e.target.closest('.category-tree__list-item').classList.remove('push-bottom');
		e.target.classList.remove('push-middle');
	};

	const drag = (e) => {
		e.dataTransfer.setData("text", JSON.stringify(e.target.dataset));
	};

	const markForDeletion = (treeArr, path) => {
		let out;
		if (path.length > 1) {
			let rest = path.filter((_, i) => i !== 0);
			out = treeArr.map(el => {
				if(el.id === path[0]) {
					el.children = markForDeletion(treeArr.children, rest);
				}
				return el;
			})
		} else {
			out = treeArr.map(el => {
				if(el.id === path[0]) {
					el.delete = true;
				}
				return el;
			});
		}
		return out;
	};

	const draggedElementIterator = (treeArr, path) => {
		if (path.length > 1) {
			let crumb = path[0];
			let rest = path.filter((_, i) => i !== 0);
			let childrenArr = treeArr.find(el => {
				return el.id === crumb
			});
			return draggedElementIterator(childrenArr.children, rest)
		}
		return treeArr.find(el => el.id === path[0]);
	};

	const dropzoneElementIterator = (treeArr, path, newNode) => {
		let ta;
		if (path.length > 1) {
			let rest = path.filter((_, i) => i !== 0);
			ta = treeArr.map(el => {
				if (el.id === path[0]) {
					el.children = dropzoneElementIterator(el.children, rest, newNode);
				}
				return el;
			});
		} else {
			ta = treeArr.map(el => {
				if (el.id === path[0]) {
					el.children = [
						...el.children, 
						newNode
					]
				}
				return el;
			});
		}
		return ta;
	};

	const dropzoneElementMove = (treeArr, path, newNode, position) => {
		let ta;
		if (path.length > 1) {
			let rest = path.filter((_, i) => i !== 0);
			ta = treeArr.map(el => {
				if (el.id === path[0]) {
					el.children = dropzoneElementMove(el.children, rest, newNode, position);
				}
				return el;
			});
		} else {
			let index;
			treeArr.find((el, i) => {
				if(el.id === path[0]) index = i;
			});
			switch(position) {
				case 'ABOVE':
				ta = [
					...treeArr.filter((_, i) => i < index).filter(el => el.id != newNode.id),
					newNode,
					...treeArr.filter((_, i) => i >= index).filter(el => el.id != newNode.id),
				];
				break;
				case 'BELOW':
				ta = [
					...treeArr.filter((_, i) => i <= index).filter(el => el.id != newNode.id),
					newNode,
					...treeArr.filter((_, i) => i > index).filter(el => el.id != newNode.id),
				];
				break;
			}
		}
		return ta;
	};

	const removeSourceElementIterator = (treeArr, path) => {
		let ta;
		if (path.length > 1) {
			let rest = path.filter((_, i) => i !== 0);
			ta = treeArr.map(el => {
				if (el.id === path[0]) {
					el.children = removeSourceElementIterator(el.children, rest);
				}
				return el;
			});
		} else {
			ta = treeArr.filter((el, idx) => el.id !== path[0]);
		}
		return ta;
	};

	const drop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const dropData = JSON.parse(e.dataTransfer.getData("text"));
		let dropZone = e.target.closest('.category-tree__list-item').dataset;

		let dragPathArray = dropData.parentLine.split("__")
		dragPathArray.push(dropData.id)
		dragPathArray = dragPathArray.filter(el => el !== "")
		// get the dragged object from the fullTree
		let dei = draggedElementIterator(props.fullTree, dragPathArray)

		let dropPathArray = dropZone.parentLine.split("__");
		dropPathArray.push(dropZone.id);
		dropPathArray = dropPathArray.filter(el => el !== "");
		let newTree;

		let dt = getDropType(e);

		if(props.onlyWithinParent && (dt === 'IN' || (dropZone.parentLine !== dropData.parentLine))) return;
		
		switch(dt){
			case 'ABOVE':
				// place the dragged object in the dropzone in the fullTree
				newTree = dropzoneElementMove(props.fullTree, dropPathArray, {...dei}, 'ABOVE');
				// remove the original object from the fullTree
				if(dropZone.parentLine !== dropData.parentLine) {
					newTree = removeSourceElementIterator(newTree, dragPathArray);
				}
				// update tree
				props.setData(newTree);
				break;
			case 'BELOW':
				// place the dragged object in the dropzone in the fullTree
				newTree = dropzoneElementMove(props.fullTree, dropPathArray, {...dei}, 'BELOW');
				// remove the original object from the fullTree
				if(dropZone.parentLine !== dropData.parentLine) {
					newTree = removeSourceElementIterator(newTree, dragPathArray);
				}
				// update tree
				props.setData(newTree);
				break;
			case 'IN':
				let ddpl = dropData.parentLine.split('_');
				if(
					dropZone.id !== dropData.id
					&& dropZone.id !== (ddpl.length === 1 ? ddpl[0] : ddpl[ddpl.length - 1])
				) {
					// place the dragged object in the dropzone in the fullTree
					newTree = dropzoneElementIterator(props.fullTree, dropPathArray, {...dei});
					// remove the original object from the fullTree
					newTree = removeSourceElementIterator(newTree, dragPathArray);
					// update tree
					props.setData(newTree);
				}
				break;
		}

		e.target.closest('.category-tree__list-item').classList.remove('push-top');
		e.target.closest('.category-tree__list-item').classList.remove('push-bottom');
		e.target.classList.remove('push-middle');
	};

	return (
		<ul className='category-tree__list '>
			{props.data ?
				props.data.map(el => {
					let pl = props.parentLine ? `${props.parentLine}__${el.id}` : el.id;
					return (
						<li
							className='category-tree__list-item'
							draggable='true'
							key={el.id}
							id={el.id}
							data-id={el.id}
							data-parent-line={props.parentLine}
							onDragStart={drag}
						>
							<div
								onDrop={drop}
								onDragOver={dragOver}
								onDragLeave={dragLeave}
								className="leaf-container"
							>
								<div className='leaf-data'>
									<img src={DRAG_HANDLE} className="leaf-data__handle" />
									{el.name}
									<div className="leaf-data__actions">
										<button 
											className="leaf-data__actions-button add"
											onClick={() => props.onCreateChild(el, props.parentLine)}
										></button>
										<button 
											className="leaf-data__actions-button edit"
											onClick={() => props.onEdit(el, props.parentLine)}
										></button>
										<button 
											className="leaf-data__actions-button delete"
											onClick={() => props.onDelete(el, props.parentLine)}
										></button>
									</div>
								</div>
							</div>
							{el.children.length > 0 ?
								<DataTree
									data={el.children}
									fullTree={props.fullTree ? props.fullTree : props.data}
									setData={props.setData}
									parentLine={pl}
									onEdit={props.onEdit}
									onCreateChild={props.onCreateChild}
									onDelete={props.onDelete}
									onlyWithinParent={props.onlyWithinParent}
								/>
								: null
							}
						</li>
					)
				})
				: null
			}
		</ul>
	)
};

DataTree.propTypes = {
	data: PropTypes.array,
	parentLine: PropTypes.string,
	onCreateChild: PropTypes.func,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onlyWithinParent: PropTypes.bool
};

DataTree.defaultProps = {
	data: [],
	parentLine: '',
	onCreateChild: () => {},
	onEdit: () => {},
	onDelete: () => {},
	onlyWithinParent: false
};

export default DataTree;
