// vendor imports
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import oblax from 'services/oblax';
// components
import PublicTemplate from 'com/templates/PublicTemplate';
import LoginBox from 'com/templates/LoginBox';
import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import Error from 'com/ui/Error';
// services
import L11n from 'services/l11n';
// constants
import { RESET_PASSWORD_SCHEMA } from 'schemas/resetPassword';
// styles
import './style.css';

const ResetPassword = () => {
    const resetHash = useParams().id;
    const history = useHistory();
    const initErrors = {
        password: '',
        repeatPassword: ''
    };
    const initPasswordInputs = {
        password: '',
        repeatPassword: ''
    };
    const [resetPassword, setResetPassword] = useState(initPasswordInputs);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState(initErrors);
    const [xhttpError, setXhttpError] = useState('');

    const onChange = (e) => {
        setResetPassword({
            ...resetPassword,
            [e.target.name]: e.target.value
        });
    };

    const onSetNewPassword = async () => {
        setXhttpError('');
        if (!validateForm() || showLoader) return;
        if (resetPassword.password !== resetPassword.repeatPassword) {
            setXhttpError('Passwords do not match');
            return;
        }
        setShowLoader(true);
        try {
            let res = await oblax.auth.resetPassword(resetHash, resetPassword.password);
            if (res.ok) {
                setShowLoader(false);
                history.push("/auth-message/password-changed");
            }
        } catch (err) {
            if (process.env.REACT_APP_ENV !== 'prod') {
                console.log(err);
            }
            setXhttpError(L11n.t(`ERROR_${err.data.type}`));
            setShowLoader(false);
        }
    };

    const validateForm = () => {
        setErrors(initErrors);
        let validate = oblax.validator.validate(RESET_PASSWORD_SCHEMA, resetPassword);
        if (!validate) {
            let errs = oblax.validator.getValidationErrors();
            for (let e of errs) {
                setErrors(state => { return { ...state, [e.key]: L11n.t(`ERROR_${e.code}`) } });
            }
        }
        return validate;
    };

    const removeError = () => {
        setXhttpError('');
    };

    return (
        <PublicTemplate>
            <LoginBox title={L11n.t('TITLE_RESET_PASSWORD')} showNavigation={false}>
                <p className="change-password-text">
                    Your email address has been successfully confirmed. You can now proceed to logging in.
                </p>
                <Input 
                    type="password" 
                    label="Password" 
                    name="password" 
                    error={errors.password} 
                    onChange={onChange} 
                />
                <Input 
                    type="password" 
                    label="Re-Type Password" 
                    name="repeatPassword" 
                    error={errors.repeatPassword} 
                    onChange={onChange} 
                />
                <div className="change-password-footer">
                    <Button showLoader={showLoader} onClick={onSetNewPassword}>Set New Password</Button>
                </div>
            </LoginBox>
            {xhttpError ? <Error message={xhttpError} onClick={removeError} /> : null}
        </PublicTemplate>
    );
};

export default ResetPassword;