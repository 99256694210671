//vendor imports
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
//components
import PublicTemplate from "com/templates/PublicTemplate";
import LoginBox from "com/templates/LoginBox";
import Button from "com/ui/Button";
// services
import L11n from 'services/l11n';
//styles
import './style.css';

const PublicAuthMessage = (props) => {

    const contentInit = {
        title: '',
        text: '',
        button_label: '',
        button_link: ''
    };

    const [content, setContent] = useState(contentInit);
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        console.log(id);
        switch(id) {
            case 'request-access':
                setContent({
                    title: L11n.t('PU_VIEW_REQUEST_ACCESS_MSG_TITLE'),
                    text: L11n.t('PU_VIEW_REQUEST_ACCESS_MSG_TEXT'),
                    button_label: L11n.t('PU_VIEW_REQUEST_ACCESS_MSG_BUTTON'),
                    button_link: '/'
                });
            break;
            case 'check-email':
                setContent({
                    title: L11n.t('PU_VIEW_CHECK_EMAIL_MSG_TITLE'),
                    text: L11n.t('PU_VIEW_CHECK_EMAIL_MSG_TEXT'),
                    button_label: L11n.t('PU_VIEW_CHECK_EMAIL_MSG_BUTTON'),
                    button_link: '/'
                });
            break;
            case 'password-changed':
                setContent({
                    title: L11n.t('PU_VIEW_PASSWORD_CHANGED_MSG_TITLE'),
                    text: L11n.t('PU_VIEW_PASSWORD_CHANGED_MSG_TEXT'),
                    button_label: L11n.t('PU_VIEW_PASSWORD_CHANGED_MSG_BUTTON'),
                    button_link: '/'
                });
            break;
            default: 
                setContent({
                    title: '',
                    text: '',
                    button_label: '',
                    button_link: '/'
                });
            break;
        }
    }, []);

    const backToLogin = () => {
        history.push(content.button_link);
    };

    return (
        <PublicTemplate>
            <LoginBox title={content.title} showNavigation={false}>
                <p className="public-auth-message__text">
                    {content.text}
                </p>
                <div className="public-auth-message__footer">
                    <Button onClick={backToLogin}>{content.button_label}</Button>
                </div>
            </LoginBox>
        </PublicTemplate>
    )
}

export default PublicAuthMessage;
