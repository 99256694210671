import React, {ReactChildren} from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router';

const AppZones = (props) => {
    const auth = useSelector(state => state.auth);
    let matchFound = false;
    const childrenWithProps = React.Children.map(props.children, child => {
        if (!matchFound && React.isValidElement(child)) {
            if (child.type.displayName === 'Private' && auth.loggedIn) {
                matchFound = true;
                return (
                    <Switch>
                        {React.Children.map(child.props.children, c => {
                            switch(c.type.displayName) {
                                case 'AppRouteGroup':
                                    return React.Children.map(c, el => React.cloneElement(el, {}));
                                case 'AppRoute':
                                    return React.cloneElement(c, { path: c.props.route.path, exact: c.props.route.exact });
                                default:
                                    return;
                            }
                        })}
                    </Switch>
                );

            } else if (child.type.displayName === 'Public' && !auth.loggedIn) {
                matchFound = true;
                return (
                    <Switch>
                        {React.Children.map(child.props.children, c => {
                            switch (c.type.displayName) {
                                case 'AppRouteGroup':
                                    return React.Children.map(c, el => React.cloneElement(el, {}));
                                case 'AppRoute':
                                    return React.cloneElement(c, { path: c.props.route.path, exact: c.props.route.exact });
                                default:
                                    return;
                            }
                        })}
                    </Switch>
                );
            }
        }
    });
    return childrenWithProps;
};

const Private = (props) => props.children;
Private.displayName = 'Private';

const Public = (props) => props.children;
Public.displayName = 'Public';

export {
    AppZones,
    Public,
    Private
};