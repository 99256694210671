// vendor imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import oblax from 'services/oblax';
// widgets
import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCard from 'com/widgets/StatsCard';
// services
import Stats from 'services/redux/ducks/carousel_stats';

const UsersStatsCarousel = () => {

    const dispatch = useDispatch();
    const { accountsStats } = useSelector(state => state.carousel_stats);
    
    useEffect(() => {
        if (!accountsStats.registrations.length) {
           (async() => {
                try {
                    let res = await oblax.stats.registrations();
                    if (res.ok) {
                        const data = Object.entries(res.data).map(([label, value]) => ({ label, value }));
                        dispatch(Stats.setAccountsRegistrationStats(data));
                    }
                } catch (err) {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(err);
                    }
                    // todo err handling on prod. ask Bojan
                }
            })();
        }
    },[]);

    useEffect(() => {
        if(!accountsStats.lastRegistrations.length) {
            (async() => {
                try {
                    let res = await oblax.stats.lastRegistrations();
                    if (res.ok) {
                        const data = Object.entries(res.data).map(([_, value]) => ({ label: value === 1 ? 'user' : 'users', value }));
                        dispatch(Stats.setLastRegistrationsStats(data));
                    }
                } catch (err) {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(err);
                    }
                    // todo err handling on prod.
                }
            })();
        }
    },[]);

    useEffect(() => {
        if(!accountsStats.socialLogins.length) {
            (async() => {
                try {
                    let res = await oblax.stats.socialLogins();
                    if (res.ok) {
                        const data = Object.entries(res.data).map(([_, value]) => ({ label: value === 1 ? 'user' : 'users', value}));
                        dispatch(Stats.setSocialLogins(data));
                    }
                } catch (err) {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(err);
                    }
                    // prod err handler here
                }
            })();
        }
    },[]);
    
    useEffect(() => {
        if(!accountsStats.activeUsers || !accountsStats.inactiveUsers) {
            (async() => {
                try {
                    let res = await oblax.stats.usersCountByActivityStatus();
                    console.log(res);
                    if (res.ok) {
                       const activePerc = Math.round((res.data.active_users / res.data.total_users) * 100)
                       const inactivePerc = Math.round((res.data.inactive_users / res.data.total_users) * 100)
                        dispatch(Stats.setActiveUsers(activePerc));
                        dispatch(Stats.setInactiveUsers(inactivePerc));
                    }
                } catch (err) {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(err);
                    }
                    // prod err handler here
                }
            })();
        }
    },[]);
    
    useEffect(() => {
        if(!accountsStats.topLocations.length) {
            (async() => {
                try {
                    let res = await oblax.stats.topTwoLocationsWithMostUsers();
                    if (res.ok) {
                        const data = res.data.map(o => {
                            return {
                                label: o.city,
                                value: Math.round((o.users / o.total_users) * 100)
                            }
                        });
                        dispatch(Stats.setTopLocations(data));
                    }
                } catch (err) {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(err);
                    }
                    // prod err handler here
                }
            })();
        }
    },[]);

    return (
        <StatsCarousel>
            <StatsCard.Numbers
                title="Registrations"
                values={accountsStats.registrations}
            />
            <StatsCard.Percentages
                title="Active users"
                value={accountsStats.activeUsers}
                label="of all users"
            />
            <StatsCard.Percentages
                title="Inactive users"
                value={accountsStats.inactiveUsers}
                label="of all users"
            />
            <StatsCard.Numbers
                title="Social logins"
                values={accountsStats.socialLogins}
            />
            <StatsCard.Numbers
                title="Registered in the last 24h"
                values={accountsStats.lastRegistrations}
            />
            <StatsCard.Pie
                title="Top 2 locations"
                values={accountsStats.topLocations}
                label="of all users"
            />
        </StatsCarousel>
    )
}

export default UsersStatsCarousel