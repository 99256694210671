import React, { useEffect, useRef, useState } from 'react';
//icons 
import ICON_LEFT from 'assets/images/icons/carousel_arrow_left.svg';
import ICON_RIGHT from 'assets/images/icons/carousel_arrow_right.svg';
//styles
import './style.css';

const StatsCarousel = (props) => {
    const slider = useRef();
    const [hidePrev, setHidePrev] = useState(true);
    const [hideNext, setHideNext] = useState(false);

    const onScroll = () => {
        setHidePrev(slider.current.scrollLeft === 0);
        setHideNext(slider.current.scrollWidth - slider.current.clientWidth === slider.current.scrollLeft);
    };

    useEffect(() => {
        slider.current.addEventListener('scroll', onScroll);
        return () => {
            slider.current.removeEventListener('scroll', onScroll);
        }
    }, []);

    const goNext = () => {
        slider.current.scrollBy({
            top:0, 
            left: 398,
            behavior: 'smooth'
        });
    };
    
    const goPrev = () => {
        slider.current.scrollBy({
            top:0,
            left: -398,
            behavior: 'smooth'
        });
    };

    return (
        <div className="stats-carousel">
            <div className="stats-carousel__slide-buttons">
                <button className={`stats-carousel__slide-buttons__left ${hidePrev ? 'hide' : ''}`} onClick={goPrev}>
                    <img src={ICON_LEFT} alt="Scroll left"/>
                </button>
                <button className={`stats-carousel__slide-buttons__right ${hideNext ? 'hide' : ''}`} onClick={goNext}>
                    <img src={ICON_RIGHT} alt="scroll right"/>
                </button>
            </div>
            <div className="stats-carousel__slides" ref={slider}>
                 {props.children}
            </div>
            <div className="stats-carousel__decorator__end"></div>
        </div>
    )
}

export default StatsCarousel;
