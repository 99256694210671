// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// components
import PortalSidebarNav from 'com/widgets/PortalSidebarNav';
import PortalHeader from 'com/widgets/PortalHeader';
import NotificationLayer from 'com/util/NotificationLayer';

import './style.css';

const PortalPage = (props) => {
	return (
		<>
			<div className="portal-body-left">
				<PortalSidebarNav />
			</div>
			<div className="portal-body-right">
				<PortalHeader 
					title={props.headerTitle} 
					navigation={props.headerNavigation}
				/>
				<div className="portal-body__content">
					{props.children}
				</div>
			</div>
			<NotificationLayer />
		</>
	);
};

PortalPage.propTypes = {
	headerTitle: PropTypes.string,
	headerNavigation: PropTypes.array
};

PortalPage.defaultProps = {
	headerTitle: 'Default Page',
	headerNavigation: null
};

export default PortalPage;
