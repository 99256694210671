import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastsRemoveAll } from 'services/redux/ducks/notification_toasts';
import ReactDom from 'react-dom';
//components
import Toast from 'com/widgets/Toast';
import Actionable from 'com/widgets/Actionable';
// styles
import './style.css';


const NotificationLayer = (props) => {

    const notification_toasts = useSelector(state => state.notification_toasts);
    const dispatch = useDispatch();

    const toastTypes = ['info', 'error', 'warning', 'success'];

    const removeAllToasts = () => {
        dispatch(toastsRemoveAll());
    };

    return ReactDom.createPortal (
        <div className="notification-layer">
            {notification_toasts.map((t) => {
                    return toastTypes.includes(t.type) ?
                        <Toast 
                            type={t.type} 
                            value={t.title} 
                            id={t.id} 
                            timeout={t.timeout} 
                            key={t.id}
                        /> :
                        <Actionable 
                            value={t.content} 
                            id={t.id} 
                            title={t.title} 
                            icon={t.icon} 
                            actions={t.actions} 
                            key={t.id}
                        />
            })}
            
            {notification_toasts.length >= 5 
            ? (
                <button 
                    className="notification-layer__remove-all" 
                    onClick={removeAllToasts}
                >
                    <span className="notification-layer__remove-all__close">&times;</span>
                    Dismiss all notifications
                </button>
            ) 
            : null}
        </div>,
        document.getElementById('toast')

    )
}

export default NotificationLayer;
