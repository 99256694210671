// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './style.css';

const Textarea = (props) => {
	let showError = props.error ? 'error' : '';
	let setDisabled = props.disabled ? 'disabled' : '';

	return (
		<label className={`textarea ${showError} ${setDisabled}`}>
			<span className="textarea-label">{props.label}</span>
			<textarea
				className="textarea-box"
				name={props.name}
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
				disabled={props.disabled}
			/>
			<span className="textarea-error">{props.error}</span>
		</label>
	);
};

Textarea.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	error: PropTypes.string
};

Textarea.defaultProps = {
	name: '',
	placeholder: '',
	value: '',
	disabled: false,
	onChange: () => {},
	error: ''
}

export default Textarea;