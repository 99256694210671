// vendor imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// styles
import './style.css';

const PopoutInput = (props) => {
	let showError = props.error ? 'error' : '';
	let setDisabled = props.disabled ? 'disabled' : '';
	let iconTheme = props.iconTheme ? props.iconTheme : '';
	const [text, setText] = useState(props.value);
	const [isActive, setIsActive] = useState(false);
	const wrapperRef = useRef(null);
	let activeBtn = isActive ? 'set-active-btn' : '';

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const togglePopoutInput = () => {
		setIsActive(prevState => !prevState);
	};

	const changeText = (e) => {
		setText(e.target.value);
	};

	const addText = () => {
		props.onChange(text);
		setIsActive(false);
	};

	const onKeyUp = (e) => {
		if (e.keyCode === 27) {
			setIsActive(false);
		} else if (e.keyCode === 13) {
			addText();
		}
	};

	const handleClickOutside = (e) => {
		if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
			setIsActive(false);
		}
	};

	return (
		<div className={`popout-input-wrapper ${showError} ${setDisabled}`} ref={wrapperRef}>
			<span className={`popout-input-button ${activeBtn} ${iconTheme}`} onClick={togglePopoutInput}>!</span>
			{isActive &&
				<>
					<div className='div-input-confirm'>
						<span className='triangle'></span>
						<input
							className='popout-input--input'
							type='text'
							value={text}
							placeholder={props.placeholder}
							disabled={props.disabled}
							onChange={changeText}
							onKeyUp={onKeyUp}
						/>
						<button className='popout-input-confirm' onClick={addText}></button>
					</div>
					<span className="popout-input-error">{props.error}</span>
				</>
			}
		</div>
	);
};

PopoutInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.string
};

PopoutInput.defaultProps = {
	value: '',
	onChange: () => {},
	placeholder: '',
	disabled: false,
	error: ''
};

export default PopoutInput;