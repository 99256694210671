import React from "react";
import PropTypes from 'prop-types';

//components
import Icon from "com/ui/Icon";
import Echo from "com/util/Echo";
// services
import l11n from 'services/l11n';

//styles
import './style.css';
import { Link } from "react-router-dom";

const ActivityCard = (props) => {

    const body = React.Children.map(props.children, child => child.type.displayName === 'Body' ? child : null);

    return (
        <div className="activity-card">
            <div className="activity-card__header">
            <Icon name={props.icon} color="primary" size="medium" type="line"/>
                <span className="activity-card__header__date"><Echo.Date locale="en-GB" style="full">{props.date}</Echo.Date></span>
            </div>
            <div className="activity-card__content">{ body ? body : <ActivityCardLoader/> }</div>
            <div className="activity-card__footer">
                <div className="activity-card__footer-left">
                    <span className={`activity-card__footer-circle ${props.statusColor}`}></span>
                    {props.status}
                </div>
                <Link to={props.actionLocation} className="activity-card__footer-right">
                    <span>{props.action}</span>
                    <Icon 
                        name="arrow-right-s" 
                        color="primary"
                        size="large" 
                        type="line" 
                    />
                </Link>
            </div>
        </div>
    );
};

ActivityCard.propTypes = {
	icon: PropTypes.string,
    statusColor: PropTypes.oneOf(['grey', 'blue', 'purple', 'lightBlue', 'green']),
    status: PropTypes.string,
    action: PropTypes.string,
    actionLocation: PropTypes.string,
    date: PropTypes.string
};

ActivityCard.defaultProps = {
    icon: '',
    statusColor: 'grey',
    status: '',
    action: '',
    actionLocation: '/',
    date: '',
};

ActivityCard.NoData = (props) => {
    return (
        <div className="ac-no-data">
            <Icon name="bubble-chart" type="fill" size="biggest" color="lightgrey"/>
            <span className="ac-no-data__text">{l11n.t('NO_DATA')}</span>
        </div>
    );
};

const ActivityCardLoader = () => {
    return (
        <div className="loader">
            <div className="loader__left-wrapper">
                <span className="loader__avatar"></span>  
                <div className="loader__left-skeleton">
                    <span className="no-data__loader"></span>   
                    <span className="no-data__loader"></span>     
                </div>  
            </div>
            <div className="loader__right-wrapper">
                <div className="loader__right-skeleton">
                    <span className="no-data__loader"></span>     
                    <span className="no-data__loader"></span>   
                </div>
            </div>
        </div>
    );
};

const Body = (props) => props.children;
Body.displayName = 'Body';
ActivityCard.Body = Body;

const Left = (props) => <div className="activity-card__content-left">{props.children}</div>;
ActivityCard.Left = Left;

const Right = (props) => <div className="activity-card__content-right">{props.children}</div>;
ActivityCard.Right = Right;

export default ActivityCard;
