//vendors
import React from "react";
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
//ui components
import Button from "com/ui/Button";
import DataTable, { Paging } from "com/ui/DataTable";
//widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
//components
import Carousel from "./Carousel";
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
//styles
import './style.css';

import useDataTable from 'services/hooks/use_datatable';

const EcommerceProducts = (props) => {

    const headerNav = [
		{ to: '/ecommerce', label: 'Dashboard' },
		{ to: '/ecommerce/products', label: 'Products' },
		{ to: '/ecommerce/categories', label: 'Categories' },
        { to: '/ecommerce/orders', label: 'Orders'},
        { to: '/ecommerce/warehouse', label: 'Warehouse'},
	];

    const initTableHeaders = [
        { title: 'Name', field: 'name', type: 'string', sort: 'none', sortable: true },
        { title: 'Vendor', field: 'vendor_name', type: 'string', sort: 'none', sortable: true },
        { title: 'In stock', field: 'quantity', type: 'number', sort: 'none', sortable: true } ,
        { title: 'Price', field: 'price', type: 'number', sort: 'none', sortable: true },
        { title: 'Available from', field: 'available_from', type: 'date', sort: 'none', sortable: true },
        { title: 'Available to', field: 'available_to', type: 'date', sort: 'none', sortable: true },
        { title: 'Category', field: 'category', type: 'string', sort: 'none', sortable: true },
        { title: 'Badges', field: 'badges', type: 'string', sort: 'none', sortable: false },
    ];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange, 
        columnSort,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initTableHeaders, 
        'products.getAllV2'
    );

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];
    
    const rowClickHandler = (e) => {
        let rowID = e.target.closest('tr').dataset.rowId;
        console.log(rowID);
    };

    const tagLabels = {
        RECOMENDED: {label: 'Recomended', color: 'GREY'}, 
        BEST_SELLER: {label: 'Best_Seller', color: 'RED'}, 
        VEGAN : {label: 'Vegan', color: 'BLUE'}, 
        KOSHER : {label: 'Kosher', color: 'GREEN'}, 
        NEW_PRODUCT: {label: 'New_Product', color: 'ORANGE'}, 
        OUR_PRODUCT: {label: 'Our_Product', color: 'YELLOW'}
    };

    return (
        <ApplicationTemplate
            headerTitle="E-Commerce"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <Carousel/>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="products"
                        onApply={filterApply}
                    />
                    <Button
                        type="secondary"
                        icon={ICON_PLUS}
                        customClassName="products__create-new-btn"
                    >
                        Create New
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                        {tableData.map((p, i) => {
                            return (
                                <tr key={p.id} data-row-id={p.id} onClick={rowClickHandler} className="proba">
                                    <DataTable.ProductCell company={p.manufacturer_name} images={p.images}>{p.name}</DataTable.ProductCell>
                                    <DataTable.VendorCell>{p.manufacturer_name}</DataTable.VendorCell>
                                    <DataTable.InStockCell>{p.quantity}</DataTable.InStockCell>
                                    <DataTable.ValueCell lang_code={p.country_of_origin} currency={p.price_currency}>{p.price}</DataTable.ValueCell>
                                    <DataTable.DateCell>{p.available_from}</DataTable.DateCell>
                                    <DataTable.DateCell>{p.available_to}</DataTable.DateCell>
                                    <DataTable.TagCell>{p.category.map(c => c.name)}</DataTable.TagCell>
                                    <DataTable.TagCell format = 'short' labels={tagLabels}>{p.badges}</DataTable.TagCell>
                                    <DataTable.ActionCell actions={actions} actionData={p}/>
                                </tr>
                            )
                        })}
                    </DataTable>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default EcommerceProducts;