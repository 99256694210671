// vendors
import React from 'react';
// components
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import DashboardTemplate from 'com/templates/DashboardTemplate';
import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
import CardsList from './CardsList';
import DashboardChart from './DashboardChart';
import StatTables from './StatTables';
import UsersStatsCarousel from 'com/widgets/StatsCarousel/UsersStatsCarousel';
// style
import './style.css';

const UsersDashboard = (props) => {

	const headerNav = [
		{ to: '/users', label: 'Dashboard' },
		{ to: '/users/accounts', label: 'Accounts' },
		{ to: '/users/access-requests', label: 'Access Requests' },
	];

	return (
		<ApplicationTemplate
			headerTitle="Users"
			headerNavigation={headerNav}
		>
			<DashboardTemplate>
				<DashboardTemplate.Top>
					<UsersStatsCarousel />
				</DashboardTemplate.Top>
				<DashboardTemplate.Main>
					<div className="user-dashboard__main-content">
						<DashboardChart/>
						<StatTables />						
					</div>
				</DashboardTemplate.Main>
				<DashboardTemplate.Right>
					<CardsList/>
				</DashboardTemplate.Right>
			</DashboardTemplate>
		</ApplicationTemplate>
	);
};

export default UsersDashboard;