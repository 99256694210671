import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Icon = (props) => {
    return <i className={`app-icon ri-${props.name}-${props.type} ${props.size} ${props.color} ${props.customClassName}`}></i>
};

Icon.propTypes = {
    name: PropTypes.string,
    type: PropTypes.oneOf(['line', 'fill']),
    size: PropTypes.oneOf(['smallest', 'smaller', 'small', 'medium', 'large', 'larger', 'largest', 'big', 'bigger', 'biggest']),
    color: PropTypes.oneOf(['primary', 'secondary', 'white', 'grey', 'lightgrey', 'darkgrey']),
    customClassName: PropTypes.string
};

Icon.defaultProps = {
    name: 'cloud',
    type: 'fill',
    size: 'medium',
    color: 'grey',
    customClassName: ''
};

export default Icon;