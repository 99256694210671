// vendors
import React from "react";

//components
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import DashboardTemplate from "com/templates/DashboardTemplate";

import CardsList from "./CardsList";
import EcommerceStatsCarousel from "com/widgets/StatsCarousel/EcommerceStatsCarousel";
import DashboardChart from "./DashboardChart";
import StatTables from "./StatTables";

//style
import './style.css'

const EcommerceDashboard = (props) => {

    const headerNav = [
		{ to: '/ecommerce', label: 'Dashboard' },
		{ to: '/ecommerce/products', label: 'Products' },
		{ to: '/ecommerce/categories', label: 'Categories' },
        { to: '/ecommerce/orders', label: 'Orders'},
        { to: '/ecommerce/warehouse', label: 'Warehouse'},
	];
    return (
        <ApplicationTemplate
            headerTitle="E-Commerce"
            headerNavigation={headerNav}
        >
            <DashboardTemplate>
                <DashboardTemplate.Top>
                    <EcommerceStatsCarousel />
                </DashboardTemplate.Top>
                <DashboardTemplate.Main>
                    <div className="ecommerce-dashboard__main-content">
                        <DashboardChart/>
                        <StatTables/>
                    </div>
                </DashboardTemplate.Main>
                <DashboardTemplate.Right>
                    <CardsList/>
                </DashboardTemplate.Right>
            </DashboardTemplate>
        </ApplicationTemplate>
    );
};

export default EcommerceDashboard;