import React from 'react';

import oblax from 'services/oblax';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppZones, Private, Public } from './com/util/AppZones';
import AppRouteGroup from './com/util/AppRouteGroup';
// services
import l11n from './services/l11n';
import Auth, { setLoggedIn } from './services/redux/ducks/auth';
import User from './services/redux/ducks/user';
import useNotification from 'services/hooks/use_notification';
// styles
import './assets/styles/global.css';

const App = () => {
	l11n.setLanguage('en');
	const [show, setShow] = useState(false);
	const loggedIn = useState(false);
	const dispatch = useDispatch();
	const authState = useSelector(state => state.auth);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			await oblax.auth.onAuthStateChanged(setLoginVerification);
			setShow(true);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await getUserData();
		})();
	}, [loggedIn]);
	
	const setLoginVerification = (data) => {
		const isUserLoggedIn = (data !== null);
		setLoggedIn(isUserLoggedIn);
		dispatch(Auth.setLoggedIn(isUserLoggedIn));
		dispatch(Auth.setUserData(data));
	}

	const getUserData = async () => {
		if(!authState.loggedIn) return;
		try {
			let data = await oblax.users.getLoggedInUserData();
			dispatch(User.setUserData(data.data));
		} catch (err) {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log(err);
			}
			sendNotification({ type: 'error', title: l11n.t(`ERROR_GET_USER_DATA`) });
		}
	};

	return (
		show && <AppZones>
			<Public>
				<AppRouteGroup name="public" />
			</Public>
			<Private>
				<AppRouteGroup name="top-level" />
			</Private>
		</AppZones>
	)
};

export default App;
