//vendor imports
import React, { useState } from 'react';
//templates
import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
//components
import ActionsMenu from 'com/widgets/ActionsMenu';

const DemoActionsMenu = (props) => {

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Delete', action: onMenuOption1, icon: 'delete-bin' },
        { label: 'Duplicate', action: onMenuOption2, icon: 'file-copy' },
        { label: 'Deactivate', action: onMenuOption3, icon: 'close-circle' }
    ];

    return (
        <ComponentDemoTemplate>
            <ActionsMenu actions={actions} data={{name: 'Pero'}}/>
        </ComponentDemoTemplate>
    );
};

export default DemoActionsMenu;