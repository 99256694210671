// vendor imports
import React from 'react';

export const Tag = (props) => {
	const removeTags = () => {
		props.removeTags(props.index);
	};

	return (
		<div key={props.index} className="tag">
			<span className="tag-title">{props.tag}</span>
			<span className="tag-close-icon" onClick={removeTags}>&times;</span>
		</div>
	);
};