export default {
    WELCOME: 'Welcome',
    SAMPLE: 'Sample text %s',

    SUCCESS_LOGOUT: 'Successfuly logged out',

    TITLE_RESET_PASSWORD: 'Reset Password',
    TITLE_CHECK_YOUR_EMAIL: 'Check Your Email',
    TITLE_PASSWORD_CHANGED: 'Password Changed!',

    PU_VIEW_REQUEST_ACCESS_MSG_TITLE: 'Access Requested!',
    PU_VIEW_REQUEST_ACCESS_MSG_TEXT: 'You successfully requested access to the oblax control panel. Once an administrator approves you request, you will receive a confirmation email.',
    PU_VIEW_REQUEST_ACCESS_MSG_BUTTON: 'Back to login',

    PU_VIEW_CHECK_EMAIL_MSG_TITLE: 'Check your email!',
    PU_VIEW_CHECK_EMAIL_MSG_TEXT: 'A message with a link to reset your password has been sent to your email address. Please make sure you reset as soon as possible due to a time limit on the link. Thank you!',
    PU_VIEW_CHECK_EMAIL_MSG_BUTTON: 'Back to login',

    PU_VIEW_PASSWORD_CHANGED_MSG_TITLE: 'Password changed!',
    PU_VIEW_PASSWORD_CHANGED_MSG_TEXT: 'Your successfully changed your password. Please proceed to the login page where you can enter the Oblax control panel.',
    PU_VIEW_PASSWORD_CHANGED_MSG_BUTTON: 'Back to login',

    ERROR_EMAIL_ADDRESS: 'Invalid email address',
    ERROR_MIN_STRING_LENGTH: 'Too short',
    ERROR_AUTH_WRONG_CREDENTIALS: 'Bad credentials',
    ERROR_AUTH_ACCOUNT_DOES_NOT_EXIST: 'Account does not exist',
    ERROR_AUTH_BAD_RESET_HASH: 'Bad reset password link',
    ERROR_LOGOUT: 'Could not logout. Something went wrong',
    ERROR_GET_USER_DATA: `Unable to get user's data`,
    ERROR_AUTH_ACCOUNT_ALREADY_EXIST: 'Email address is already in use',

    ERROR_GET_MOST_ACTIVE_USERS: 'Unable to get most active users',
    ERROR_GET_BIGGEST_SPENDERS: 'Unable to get the biggest spenders',
    ERROR_GET_MOST_LOYAL_USERS: 'Unable to get most loyal users',

    INFO_NO_DATA_MOST_ACTIVE_USERS: 'No data for most active users',
    INFO_NO_DATA_BIGGEST_SPENDERS: 'No data for the biggest spenders',
    INFO_NO_DATA_MOST_LOYAL_USERS: 'No data for most loyal users',

    ERROR_GET_LATEST_SIGNUPS: 'Unable to get latest signups',
    INFO_NO_DATA_LATEST_SIGNUPS: 'No data for latest signups',

    NO_DATA: 'No data available at this moment',
    UNABLE_TO_GET_DATA: 'Unable to get data at this moment',

    APPROVED_SUCCESS: 'The account was approved',
    APPROVED_FAILURE: 'The account can not be approved at this moment',
    REJECT_SUCCESS: 'The account was rejected',
    REJECT_FAILURE: 'The account can not be rejected at this moment',

    ERROR_GET_LATEST_ORDERS: 'Unable to get latest orders',
    INFO_NO_DATA_LATEST_ORDERS: 'No data for latest orders',

    ERROR_GET_BEST_SELLERS: 'Unable to get best sellers',
    INFO_NO_DATA_BEST_SELLERS: 'No data for best sellers',

    ERROR_GET_ORDERS_PIPELINE: 'Unable to get orders pipeline',
    INFO_NO_DATA_ORDERS_PIPELINE: 'No data for orders pipeline',

    ERROR_GET_OUT_OF_STOCK_INDEX: 'Unable to get out of stock index',
    INFO_NO_DATA_OUT_OF_STOCK_INDEX: 'No data for out of stock index',

    SIGNUP: 'signup',
    SIGNUPS: 'signups',

    MONTH_JAN: 'Jan',
    MONTH_FEB: 'Feb',
    MONTH_MAR: 'Mar',
    MONTH_APR: 'Apr',
    MONTH_MAY: 'May',
    MONTH_JUN: 'Jun',
    MONTH_JUL: 'Jul',
    MONTH_AUG: 'Aug',
    MONTH_SEP: 'Sep',
    MONTH_OCT: 'Oct',
    MONTH_NOV: 'Nov',
    MONTH_DEC: 'Dec',

    MONTH_JANUARY: 'January',
    MONTH_FEBRUARY: 'February',
    MONTH_MARCH: 'March',
    MONTH_APRIL: 'April',
    MONTH_JUNE: 'June',
    MONTH_JULY: 'July',
    MONTH_AUGUST: 'August',
    MONTH_SEPTEMBER: 'Septempber',
    MONTH_OCTOBER: 'October',
    MONTH_NOVEMBER: 'November',
    MONTH_DECEMBER: 'December',

    PRODUCT: 'Product',
    AMOUNT: 'Amount',
    WORTH: 'Worth',

    NEW: "New Orders",
    PROCESSING: "Packing",
    SHIPPED: "In transit",
    DELIVERED: "Delivered",
};