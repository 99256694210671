// vendor imports
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import oblax from 'services/oblax';

// components
import SectionTitle from "com/ui/SectionTitle";
import Icon from "com/ui/Icon";
import ActivityCard from "com/widgets/ActivityCard";
import Avatar from "com/widgets/Avatar";

// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

// styles
import './style.css';

const CardsList = (porps) => {

	const [ordersData, setOrdersData] = useState([]);
	const sendNotification = useNotification();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				let res = await oblax.stats.latestOrders();
				if (res.ok) {
					setLoading(false);
					setOrdersData(res.data);
				}
			} catch (err) {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log(err);
				}
				sendNotification({ type: 'error', title: l11n.t('ERROR_GET_LATEST_ORDERS') });
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return (
		<div className="cards-list">
			<div className="cards-list__header">
				<SectionTitle icon="shopping-cart-2">Latest orders</SectionTitle>
				<Link to="/" className="card-list__header-button">
					<Icon name="arrow-right-s" color="white" type="line" size="larger" />
				</Link>
			</div>
			<div className="cards-list__wrapper">
				{
					loading ?
						<LoadingData /> :
						<CardData data={ordersData} />
				}
			</div>
		</div>
	);
};

const LoadingData = () => {
	return (
		<>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
		</>
	);
};

const CardData = (props) => {

	const statusColors = {
		PROCESSING: 'blue',
		SHIPPED: 'lightBlue',
		DELIVERED: 'green',
		ACCEPTED: 'purple'
	};
	const date = new Date();

	return (
		props.data.length > 0 ?
			props.data.map((order, i) => {
				let color = statusColors[order.status];
				return (
					<ActivityCard
						icon="shopping-cart-2-fill "
						status={order.status}
						action="View order"
						statusColor={color}
						date={order._created}
					>
						<ActivityCard.Body>
							<ActivityCard.Left>
								<div className="cards-list__user-picture"><Avatar src={order.picture} /></div>
								<div className="cards-list__user-details">
									<span className="cards-list__user-details-name">{order.full_name}</span>
									<span className="cards-list__user-details-email" title={order.email}>{order.email}</span>
								</div>
							</ActivityCard.Left>
							<ActivityCard.Right>
								<div className="cards-list__user-order">
									<div className="cards-list__user-order-products">
										<span className="cards-list__user-order-products-amount">{order.quantity}</span>
										<span className="cards-list__user-order-products-tag">Products</span>
									</div>
									<div className="cards-list__user-order-sum">
										<span className="cards-list__user-order-sum-amount">{order.total_price}</span>
										<span className="cards-list__user-order-sum-currency">{order.currency}</span>
									</div>
								</div>
							</ActivityCard.Right>
						</ActivityCard.Body>
					</ActivityCard>
				);
			}) :
			<ActivityCard.NoData />
	);
};

export default CardsList;