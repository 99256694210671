// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// components
import Radio from './index';

const RadioGroup = (props) => {
	return (
		<>
			{props.options.map((option, i) => (
				<Radio
					key={i}
					name={props.name}
					label={option.label}
					value={option.value}
					disabled={props.disabled}
					checked={props.value === option.value}
					onChange={props.onChange}
				/>
			))}
		</>
	);
};

RadioGroup.propTypes = {
	name: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.any,
	onChange: PropTypes.func
};

RadioGroup.defaultProps = {
	name: '',
	disabled: false,
	value: '',
	onChange: () => {}
};

export default RadioGroup;