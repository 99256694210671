// vendor imports
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import oblax from 'services/oblax';

// widgets
import StatsCarousel from "com/widgets/StatsCarousel";
import StatsCard from "com/widgets/StatsCard";

// services
import Stats from 'services/redux/ducks/carousel_stats';

const EcommerceStatsCarousel = (props) => {

	// const pieValues = [
	// 	{ value: 15, label: 'on promotion' },
	// 	{ value: 15, label: 'on sale' },
	// 	// { value: 17, label: 'not on sale'},
	// ];

	const dispatch = useDispatch();
	const { productsStats } = useSelector(state => state.carousel_stats);
	const { ordersStats } = useSelector(state => state.carousel_stats);

	useEffect(() => {
		if (!productsStats.domesticProducts) {
			(async () => {
				try {
					let res = await oblax.stats.domesticProducts();
					if (res.ok) {
						dispatch(Stats.setDomesticProducts(res.data.domestic_products));
						dispatch(Stats.setTotalActiveProducts(res.data.total_active));
					}
				} catch (err) {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log(err);
					}
					// prod err handling here
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (!productsStats.productsWithPriceOverThousand) {
			(async () => {
				try {
					let res = await oblax.stats.productsWithPriceOverThousand();
					if (res.ok) {
						dispatch(Stats.setProductsWithPriceOverThousand(res.data.price_above_thousand));
						dispatch(Stats.setTotalActiveProducts(res.data.total_active));
					}
				} catch (err) {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log(err);
					}
					// prod err handling here
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (!ordersStats.pendingOrders) {
			(async () => {
				try {
					let res = await oblax.stats.pendingOrders();
					if (res.ok) {
						dispatch(Stats.setPendingOrders(res.data.pending_orders));
						dispatch(Stats.setTotalOrders(res.data.total_orders));
					}
				} catch (err) {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log(err);
					}
					// prod err handling here
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (!ordersStats.deliveredOrders) {
			(async () => {
				try {
					let res = await oblax.stats.deliveredOrders();
					if (res.ok) {
						dispatch(Stats.setDeliveredOrders(res.data.delivered_orders));
						dispatch(Stats.setTotalOrders(res.data.total_orders));
					}
				} catch (err) {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log(err);
					}
					// prod err handling here
				}
			})();
		}
	}, []);

	let domesticProductsPerc = Math.round((productsStats.domesticProducts / productsStats.totalActiveProducts) * 100)
	let productsWithPriceOverThousandPerc = Math.round((productsStats.productsWithPriceOverThousand / productsStats.totalActiveProducts) * 100)
	let numbersForPendingOrders = [
		{ value: ordersStats.pendingOrders, label: 'pending' },
		{ value: ordersStats.totalOrders, label: 'total' },
	];
	let deliveredOrdersPerc = Math.round((ordersStats.deliveredOrders / ordersStats.totalOrders) * 100)

	return (
		<StatsCarousel>

			<StatsCard.Percentages
				title="Domestics goods"
				value={domesticProductsPerc ? domesticProductsPerc : 0}
				label="of your products"
				reverseColors={true}
			/>
			<StatsCard.Percentages
				title="Above 1000 MKD"
				value={productsWithPriceOverThousandPerc ? productsWithPriceOverThousandPerc : 0}
				label="of your products"
				reverseColors={false}
			/>
			{/* <StatsCard.Pie
				title="Pricing"
				values={pieValues}
				label="of your products"
			/> */}
			{/* we don't have api call for this one */}

			<StatsCard.Numbers
				title="Orders"
				values={numbersForPendingOrders}
			/>
			<StatsCard.Percentages
				title="Delivery success"
				value={deliveredOrdersPerc ? deliveredOrdersPerc : 0}
				label="of your orders"
				reverseColors={false}
			/>
			{/* <StatsCard.Percentages
				title="Closing expiery"
				value={30}
				label="of your products"
				reverseColors={false}
			/> */}
			{/* we don't have api call for this one */}
		</StatsCarousel>
	);

};

export default EcommerceStatsCarousel;
