import React, { useState } from "react";

import ComponentDemoTemplate from "com/templates/ComponentDemoTemplate";

import ContentPanel from "com/widgets/ContentPanel";

const DemoContentPanel = () => {
    return (
        <ComponentDemoTemplate>
            <ContentPanel title="General Information" type="light">
                <ContentPanel.Body>
                    <ContentPanel.Separator line="light">General</ContentPanel.Separator>
                    <h4>This is the content</h4>
                    <p>ELENCE</p>
                </ContentPanel.Body>
                <ContentPanel.Footer>Footer</ContentPanel.Footer>
            </ContentPanel>
            <br/>
            <ContentPanel title="General Information" type="light">
                <ContentPanel.Body>
                    <ContentPanel.Separator line="light">General</ContentPanel.Separator>
                    <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                </ContentPanel.Body>
            </ContentPanel>
            <br />
            <ContentPanel title="Locations" type="dark">
                <ContentPanel.Body>
                    <ContentPanel.Separator line="dark">Contact</ContentPanel.Separator>
                    <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
                </ContentPanel.Body>
                <ContentPanel.Footer>Footer</ContentPanel.Footer>
            </ContentPanel>
        </ComponentDemoTemplate>
    )
}

export default DemoContentPanel;

