import { useEffect, useRef } from 'react';

const useClickOutside = (callback) => {
    const ref = useRef(null);
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    return ref;
};

export default useClickOutside;