import React from 'react';
// components
import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCard from 'com/widgets/StatsCard';

const Carousel = () => {

    const userProfilesPie = [
        { value: 15, label: "fully set" },
        { value: 15, label: "minimum" }
    ]

    const registrations = [
        { value: 15, label: "pending" },
        { value: 342, label: "total" }
    ]

    const pricing = [
        { value: 10, label: "label" },
        { value: 115, label: "label" }
    ]

    return (
        <StatsCarousel>
            <StatsCard.Percentages
                title="Inactive"
                value={15}
                label="of your users"
                reverseColors={true}
            />
            <StatsCard.Percentages
                title="Active users"
                value={25}
                label="in past 24 hours"
            />
            <StatsCard.Pie
                title="User profiles"
                values={userProfilesPie}
            />
            <StatsCard.Numbers
                title="Registrations"
                values={registrations}
            />
            <StatsCard.Percentages
                title="Registration"
                value={85}
                label="success"
            />
            <StatsCard.Percentages
                title="Unregistered"
                value={10}
                label="of your users" />
            <StatsCard.Pie
                title="Pricing"
                values={pricing}
            />
        </StatsCarousel>
    );
};

export default Carousel;