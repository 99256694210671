const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

const dateTimeFormat = new Intl.DateTimeFormat(
	'uk',
	{
		year: 'numeric',
		month: 'numeric',
		day: 'numeric'
	}
);

const dateLong = (dateTime) => {
	let d = new Date(dateTime);
	return dateTimeFormat.format(d);
};

export const formatDateAndTime = (dateInput) => {
	const formatData = (input) => {
		if (input > 9) {
			return input;
		} else return `0${input}`;
	};

	const format = {
		dd: formatData(dateInput.getDate()),
		mm: formatData(dateInput.getMonth() + 1),
		yyyy: dateInput.getFullYear(),
		HH: formatData(dateInput.getHours()),
		MM: formatData(dateInput.getMinutes()),
	};

	const format24Hour = ({ dd, mm, yyyy, HH, MM }) => {
		return (`${dd}.${mm}.${yyyy} ${HH}:${MM}`);
	};
	return format24Hour(format);
}

const timeBetween = (fromDate) => {
	const toDate = new Date();
	const timediff = toDate - fromDate;

	if (isNaN(timediff)) {
		return NaN;
	}

	switch (true) {
		case (toDate.getFullYear() - fromDate.getFullYear()) > 0: return `${toDate.getFullYear() - fromDate.getFullYear()} year/s ago`;
		case ((toDate.getFullYear() * 12 + toDate.getMonth()) - (fromDate.getFullYear() * 12 + fromDate.getMonth())) > 0: return `${((toDate.getFullYear() * 12 + toDate.getMonth()) - (fromDate.getFullYear() * 12 + fromDate.getMonth()))} month/s ago`;
		case Math.floor(timediff / WEEK) > 0: return `${Math.floor(timediff / WEEK)} week/s ago`;
		case Math.floor(timediff / DAY) > 0: return `${Math.floor(timediff / DAY)} day/s ago`;
		case Math.floor(timediff / HOUR) > 0: return `${Math.floor(timediff / HOUR)} hour/s ago`;
		case Math.floor(timediff / MINUTE) > 0: return `${Math.floor(timediff / MINUTE)} minute/s ago`;
		case Math.floor(timediff / SECOND) > 0: return `${Math.floor(timediff / SECOND)} second/s ago`;
		default: return `${Math.floor(timediff / SECOND)} second/s ago`;
	}
};

export const sqlDate = (de) => {
	let date = new Date(de);
	let d = date.getDate();
	let m = date.getMonth() + 1;
	let y = date.getFullYear();
	d = (d < 10) ? "0" + d : d;
	m = (m < 10) ? "0" + m : m;
	return `${y}-${m}-${d}`;
};


// returns iso string with default time value.
const shortDateNumeric = (dateInput) => {
    let d = new Date(dateInput);
    d.setUTCHours(0,0,0,0);
    d.toISOString();
    return d;
}

export default {
	dateLong,
	timeBetween,
    shortDateNumeric,
	sqlDate
};