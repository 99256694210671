import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Icon from 'com/ui/Icon';

import LOGO from 'assets/images/logo.svg';

import './style.css';

const PortalSidebarNav = (props) => {
    return (
        <div className="sidebar-nav">
            <Link to="/" className="sidebar-nav__logo">
                <img src={LOGO} alt="" />
            </Link>
            <nav>
                <NavLink to="/users" className="sidebar-nav__link">
                    <Icon name="group-2" color="white" size="medium"/>
                </NavLink>
                <NavLink to="/ecommerce" className="sidebar-nav__link">
                    <Icon name="shopping-cart-2" color="white" size="medium" />
                </NavLink>
                <NavLink to="/pagesforms" className="sidebar-nav__link">
                    <Icon name="pages" color="white" size="medium" />
                </NavLink>
                {/* <NavLink to="/contacts" className="sidebar-nav__link">
                    <Icon name="profile-fill " color="white" size="medium" />
                </NavLink> */}
                {/* <NavLink to="/files" className="sidebar-nav__link">
                    <Icon name="folder-5" color="white" size="medium" />
                </NavLink> */}
                {/* <NavLink to="/stats" className="sidebar-nav__link">
                    <Icon name="pie-chart-2" color="white" size="medium" />
                    <span className="badge">3</span> 
                </NavLink>*/}
                {/* <NavLink to="/billing" className="sidebar-nav__link">
                    <Icon name="bank-card" color="white" size="medium" />
                </NavLink> */}
            </nav>
            <Link to="/help" className="sidebar-nav__help">
                <Icon name="question" color="white" size="medium" />
            </Link>
        </div>
    );
};

export default PortalSidebarNav;