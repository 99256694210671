import React, { useEffect,useState } from 'react';
import oblax from 'services/oblax';
// ui
import Icon from 'com/ui/Icon';
// components
import StatTableCard from 'com/widgets/StatTableCard';
import Avatar from 'com/widgets/Avatar';
// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';
// styles
import './style.css';

const StatsMostLoyal = (props) => {

    const [mostLoyal,setMostLoyal] = useState([]);
    const [loading, setLoading] = useState(true);
    const mostLoyalCardHeaders = ['User', 'Buys', 'Amount'];
    const sendNotification = useNotification();

    useEffect(() => {
        (async() => {
            try {
                let res = await oblax.stats.usersWithMostOrders();
                if (res.ok) {
                    setMostLoyal(res.data);
                     // this should be uncommented
                    setLoading(false); 

                    // this is ONLY just for testing purposes
                    // setTimeout(() => {
                    //     setLoading(false);
                    // }, 2000);
                }
            } catch (err) {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log(err);
                }
                sendNotification({ type:'error', title: l11n.t('ERROR_GET_MOST_LOYAL_USERS')});
            }
        })();
    },[]);

    return (
        <StatTableCard title="Most loyal" icon="list-ordered " columns={mostLoyalCardHeaders}>
            {
                loading ?
                <StatTableCard.Loader cols={3} rows={5}/> :
                <TableData data={mostLoyal}/>
            }
		</StatTableCard>
    );
};

const TableData = (props) => {
    return (
        props.data.length > 0 ?
        props.data.map((user) => {
            return (
                <tr key={user.id}>
                    <td>
                        <div className="user-data-wrapper">
                            <Avatar src={user.picture} />
                            <div className="user-details">
                                <span className="user-details--full-name">
                                    {`${user.first_name} ${user.last_name}`}
                                </span>
                                <span className="user-details--email">
                                    {user.email}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="user-details--buys">
                            {user.orders}
                        </span>
                    </td>
                    <td>
                        <div className="user-details--amount-wrapper">
                            <span className="user-details--amount">
                                {user.total ? user.total.toLocaleString() : 0}
                            </span>
                            <span className="user-details--currency">
                                {user.currency}
                            </span>
                        </div>
                    </td>
                </tr>
            );
        }) :
        <StatTableCard.NoData colspan={3}/>
    );
};

export default StatsMostLoyal;