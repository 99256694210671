import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';

import IMG_LOGIN_SPACEMAN from 'assets/images/login_spaceman.svg';
import IMG_LOGO_LOGIN_TEXT from 'assets/images/logo_login_text.svg';

import QUOTES from 'data/quotes';
import arrays from 'services/util/arrays';

import './style.css';

const PublicTemplate = (props) => {

    const quoteInit = {quoteText: '', quoteAuthor: ''};
    const [quote, setQuote] = useState(quoteInit);

    useEffect(() => {
        setQuote(arrays.random(QUOTES));
    }, []);    

    return (
        <div className="login-container">
            <div className="login-container__left">
                <NavLink to="/">
                    <img className="login-container__left__logo" src={IMG_LOGO_LOGIN_TEXT} alt="" />
                </NavLink>
                <div className="login-container__left__quote">
                    <span className="quote__message">
                        {quote.quoteText}
                    </span>
                    <span className="quote__author">
                        -- {quote.quoteAuthor}
                    </span>
                </div>
                <img className="login-container__left__spaceman" src={IMG_LOGIN_SPACEMAN} />
            </div>
            <div className="login-container__right">
                <div className="login-container__right__top">
                    {props.children}
                </div>
                <div className="login-container__right__bottom">
                    <nav>
                        <a href="https://oblax.io" target="_blank" rel="noopener noreferrer">Oblax.io</a>
                        <a href="https://support.oblax.io" target="_blank">Support</a>
                        <a href="/tas">Terms and Conditions</a>
                        <span>&copy;BeyondBasics 2022</span>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default PublicTemplate;