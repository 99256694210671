import React from 'react';

import './style.css';

const DetailViewTemplate = (props) => {

    const mainPanel = React.Children.map(props.children, child => child.type.displayName === 'Main' ? child : null);
    const sidebarPanel = React.Children.map(props.children, child => child.type.displayName === 'Sidebar' ? child : null);

    return (
        <div className="detail-view-tpl">
            {
                mainPanel.length > 0 ?
                    <div className="detail-view-tpl__main">{mainPanel}</div> :
                    null
            }
            {
                sidebarPanel.length > 0 ?
                    <div className="detail-view-tpl__sidebar">
                        <div className="detail-view-tpl__sidebar__content">{sidebarPanel}</div>
                    </div> :
                    null
            }
        </div>
    );
};

const Main = (props) => props.children;
Main.displayName = 'Main';
DetailViewTemplate.Main = Main;

const Sidebar = (props) => props.children;
Sidebar.displayName = 'Sidebar';
DetailViewTemplate.Sidebar = Sidebar;


export default DetailViewTemplate;
