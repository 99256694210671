//vendor imports
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//components
import Echo from "com/util/Echo";
import Icon from 'com/ui/Icon';
import ActionsMenu from 'com/widgets/ActionsMenu';
//styles
import './style.css';

const ItemCard = (props) => {
    return (
        <Link to={props.link} className={`item-card__container ${props.customClassName}`}>
            <div className="item-card__main">
                <img className={`item-card__main-image`} src={props.image} alt={props.imgAlt}/>
                <span  className="item-card__main-info__title">{props.title}</span>
                <span  className="item-card__main-info__details">{props.details}</span>
                <div className="item-card__main-middle">
                    <Icon 
                        name={props.subtitleIcon} 
                        color="secondary" 
                        type="fill" 
                        size="smallest"
                    />
                    <span className="item-card__main-middle__subtitle">{props.subtitle}</span>
                </div>
            </div>
            <div className="item-card__footer">
                <div className="item-card__footer-left">
                    <span className="item-card__footer-left__label">{props.footerLabel}</span>
                    <span className="item-card__footer-left__date">
                        <Echo.Date locale="en-GB" style="full">{props.date}</Echo.Date>
                    </span>
                </div>
                <ActionsMenu actions={props.actions}/>
            </div>
        </Link>
    )
};

ItemCard.propTypes = {
    link: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
    footerLabel: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        action: PropTypes.func
    })),
    customClassName: PropTypes.string,
    imgAlt: PropTypes.string,
    subtitleIcon: PropTypes.string,
    subtitle: PropTypes.string
};

ItemCard.defaultProps = {
    link: '/',
    image: '',
    title: '',
    details:'',
    footerLabel: '',
    date: new Date(),
    actions:[],
    customClassName:'',
    imgAlt: '',
    subtitleIcon: 'map-pin',
    subtitle: 'subtitle'
};

export default ItemCard;