// vendors
import React, {useEffect} from "react";
// templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
// ui components
import DataTable, { Paging } from 'com/ui/DataTable';
import Echo from "com/util/Echo";
// widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
import StatsCarousel from 'com/widgets/StatsCarousel';
// services
import useDataTable from 'services/hooks/use_datatable';
import d from 'services/util/date';
// icons
import ICON_CALENDAR from 'assets/images/icons/calendar-icon.svg';
// styles
import './style.css';

const EcommerceOrders = (props) => {

    const headerNav = [
		{ to: '/ecommerce', label: 'Dashboard' },
		{ to: '/ecommerce/products', label: 'Products' },
		{ to: '/ecommerce/categories', label: 'Categories' },
        { to: '/ecommerce/orders', label: 'Orders'},
        { to: '/ecommerce/warehouse', label: 'Warehouse'},
	];

    const initTableHeaders = [
        { title: 'Invoice ID', field: 'invoice_id', type: 'string', sort: 'none', sortable: true },
        { title: 'Buyer', field: 'full_name', type: 'string', sort: 'none', sortable: true },
        { title: 'Location', field: 'delivery_city', type: 'string', sort: 'none', sortable: true },
        { title: 'No.Items', field: 'quantity', type: 'number', sort: 'none', sortable: true},
        { title: 'Total', field: 'total', type: 'number', sort: 'none', sortable: true},
        { title: 'Delivery date', field: 'delivery_date', type: 'date', sort: 'none', sortable: true},
        { title: 'Delivery timeslot', field: 'delivery_timeslot', type: 'date', sort: 'none', sortable: false},
        { title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
        { title: 'Created', field: '_created', type: 'date', sort: 'none', sortable: true },
        { title: 'Actions', field: '', type: '', sort: 'none', sortable: false },
    ];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange, 
        columnSort,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initTableHeaders, 
        'orders.getAllV2'
    );

    const groupByDate = tableData.reduce((groupData, obj) => {
        if (!groupData[d.shortDateNumeric(obj._created)]) {
            groupData[d.shortDateNumeric(obj._created)] = [];
        }
        groupData[d.shortDateNumeric(obj._created)].push(obj);
        return groupData;
    },{});

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Option 1', action: onMenuOption1 },
        { label: 'Option 2', action: onMenuOption2 },
        { label: 'Option 3', action: onMenuOption3 }
    ];

    let labels = {
        ACCEPTED: {label: 'accepted', color: 'GREEN'},
        QUEUED: {label: 'queued', color: 'YELLOW'},
        CHARGEABLE: {label: 'chargeable', color: 'BLUE'},
        DECLINED: {label: 'declined', color: 'RED'}
    };

    return (
        <ApplicationTemplate
            headerTitle="E-Commerce"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <StatsCarousel>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                    </StatsCarousel>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter 
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="orders"
                        onApply={filterApply}
                    />
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                         {Object.keys(groupByDate).map(date => {
                            return (
                                <>
                                    <tr className="date-row">
                                        <td colSpan={tableHeaders.length} className="date-row__cell">
                                            <div>
                                                <img src={ICON_CALENDAR}/>
                                                <span><Echo.Date>{date}</Echo.Date></span>
                                            </div>
                                        </td>
                                    </tr>
                                    {groupByDate[date].map((o,i) => {
                                        return (
                                            <tr key={i}>
                                                <DataTable.IdCell>{o.invoice_id}</DataTable.IdCell>
                                                <DataTable.UserCell avatar={o.picture} email={o.email}>{o.full_name}</DataTable.UserCell>
                                                <DataTable.LocationCell country={o.delivery_country}>{o.delivery_city} {o.delivery_zipcode}</DataTable.LocationCell>
                                                <DataTable.Cell>{o.quantity}</DataTable.Cell>
                                                <DataTable.ValueCell currency="MKD">{o.total}</DataTable.ValueCell>
                                                <DataTable.DateCell>{o.delivery_timeslot_start}</DataTable.DateCell>     
                                                <DataTable.Cell>
                                                    <Echo.Date onlyTime={true}>{o.delivery_timeslot_start}</Echo.Date> - <Echo.Date onlyTime={true}>{o.delivery_timeslot_end}</Echo.Date>    
                                                </DataTable.Cell>     
                                                <DataTable.StatusCell status={o.status} labels={labels}/>
                                                <DataTable.DateCell>{o._created}</DataTable.DateCell>
                                                <DataTable.ActionCell actions={actions} actionData={o}/>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </DataTable>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default EcommerceOrders;