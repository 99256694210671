import React, { useState } from "react";

//components
import ComponentDemoTemplate from "com/templates/ComponentDemoTemplate";

import StatTableCard from "com/widgets/StatTableCard";

//icons
import ICON_CONFIG from 'assets/images/icons/settings-blue.svg';

const DemoStatsTableCard = () => {

    const tableHeaders = ['User', 'Buys', 'Amount'];
    const anotherTableHeaders = ['Page', 'Total submits', 'This week', 'Last submission'];
    
    return (
        <ComponentDemoTemplate>
            <StatTableCard title="Most loyal" icon="home-gear" columns={tableHeaders}>
                <>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>two</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>two</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>two</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>two</td>
                    </tr>
                </>
            </StatTableCard>
            <hr/>
            <StatTableCard title="Most active forms" columns={anotherTableHeaders}>
                <>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>three</td>
                        <td>four</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>three</td>
                        <td>four</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>three</td>
                        <td>four</td>
                    </tr>
                    <tr>
                        <td>one</td>
                        <td>two</td>
                        <td>three</td>
                        <td>four</td>
                    </tr>
                </>
            </StatTableCard>
        </ComponentDemoTemplate>
    )
}

export default DemoStatsTableCard;
