import React from 'react';

import './style.css';

const DashboardTemplate = (props) => {

    const topPanel = React.Children.map(props.children, child => child.type.displayName === 'Top' ? child : null);
    const mainPanel = React.Children.map(props.children, child => child.type.displayName === 'Main' ? child : null);
    const rightPanel = React.Children.map(props.children, child => child.type.displayName === 'Right' ? child : null);

    return (
        <div className="dashboard-tpl">
            {
                topPanel.length > 0 ?
                <div className="dashboard-tpl__top">{topPanel}</div> : 
                null
            }
            <div className="dashboard-tpl__bottom">
                {
                    mainPanel.length > 0 ?
                    <div className="dashboard-tpl__bottom__main">{mainPanel}</div> :
                    null
                }
                {
                    rightPanel.length > 0 ? 
                    <div className="dashboard-tpl__bottom__right">{rightPanel}</div> :
                    null
                }
            </div>
        </div>
    );
};

const Top = (props) => props.children;
Top.displayName = 'Top';
DashboardTemplate.Top = Top;

const Main = (props) => props.children;
Main.displayName = 'Main';
DashboardTemplate.Main = Main;

const Right = (props) => props.children;
Right.displayName = 'Right';
DashboardTemplate.Right = Right;

export default DashboardTemplate;
