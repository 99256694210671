import React, { useState, useEffect } from 'react';
// components
import StatTableCard from 'com/widgets/StatTableCard';
import Icon from 'com/ui/Icon';
// css
import './style.css'

const StatsMostVisited = (props) => {

    const mostVisitedPagesHeaders = ["Page", "Total visits", "This week", "Last visit"];
    
    const[loading, setLoading] = useState(true);

    const pagesData = [
        { page: "FAQ", domain: "http://domain.mk/faq", total_visits: 927, this_week_visits: 28},
        { page: "About Us", domain: "http://domain.mk/about-us", total_visits: 927, this_week_visits: 28 },
        { page: "How To Setup Payent", domain: "http://domain.mk/setup", total_visits: 927, this_week_visits: 28 },
        { page: "Terms & Conditions", domain: "http://domain.mk/terms-and-cond", total_visits: 927, this_week_visits: 28}
    ];

     // The emptyData state and the useEffect are ONLY for testing purposes

     const [emptyData, setEmptyData] = useState([]);

     useEffect(() => {
          setTimeout(() => {
             setLoading(false);
         }, 2000);
     }, []);

    return (
        <StatTableCard title="Most visited pages" icon="list-ordered " columns={mostVisitedPagesHeaders}>
            {
                loading ?
                <StatTableCard.Loader cols={4} rows={5}/> :
                <TableData data={pagesData}/>
            }
        </StatTableCard>
    );

};

const TableData = (props) => {
    const timestamp = new Date().toLocaleString();
    return (
        props.data.length > 0 ?
        props.data.map((p,i) => {
            return (
                <tr key={i}>
                    <td>
                        <div className='page-data__wrapper'>
                            <Icon name="window-2" color="secondary" type="line" size="largest" />
                            <div className='page-data__details'>
                                <span className='page-data__details__page-name'>{p.page}</span>
                                <span className='page-data__details__domain'>{p.domain}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className='page-data__total-visits'>
                            {p.total_visits}
                        </span>
                    </td>
                    <td>
                        <span className='page-data__this-week-visits'>
                            {p.this_week_visits}
                        </span>
                    </td>
                    <td>
                        <div className='page-data__last-visits'>
                            <span className='page-data__last-visits__hours-ago'>4 hours ago</span>
                            <span className='page-data__last-visits__timestamp'>{timestamp}</span>
                        </div>
                    </td>
                </tr>
            );
        }) :
        <StatTableCard.NoData colspan={4}/>
    );
};

export default StatsMostVisited;