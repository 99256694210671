// vendor imports 
import React, { useState } from "react";
// components
import StatsBestSellers from "./StatsBestSellers";
import StatsOrdersPipeline from "./StatsOrdersPipeline";
import StatsOutOfStock from "./StatsOutOfStock";
// styles
import './style.css';

const StatTables = () => {

    return (
        <div className="stat-tables">
            <div className="stat-tables__container">
                <StatsBestSellers />
            </div>
            <div className="stat-tables__container order-pipeline">
                <StatsOrdersPipeline />
            </div>
            <div className="stat-tables__container">
                <StatsOutOfStock />
            </div>
        </div>
    )
};

export default StatTables;
