// vendor imports
import React, { useState } from "react";

// components from external library
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, DefaultTooltipContent, ResponsiveContainer} from 'recharts';
// components
import SectionTitle from 'com/ui/SectionTitle';
import DatePicker from 'com/ui/DatePicker';
import Echo from 'com/util/Echo';

// styles
import './style.css';

const DashboardChart = (props) => {

    const [datePicker, setDatePicker] = useState(new Date());

    const dateFormat1 = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' };
    const colors = { greyLight: "#ADADAD33", greyDark: "#ACACAC", blue: "#00AADD"};

    const datePickerChange = (e) => {
        setDatePicker(e.target.value);
    };

    const data = [
        {
            name: '04:00',
            orders: 11,
            sum: 58223,
            test: 10,
        },
        {
            name: '06:00',
            orders: 12,
            sum: 52223,
            test: 19,
        },
        {
            name: '08:00',
            orders: 25,
            sum: 14982,
            test: 14,
        },
        {
            name: '10:00',
            orders: 23,
            sum: 5223,
            test: 10,
        },
        {
            name: '12:00',
            orders: 10,
            sum: 90539,
            test: 16,
        },
        {
            name: '12:00',
            orders: 10,
            sum: 32119,
            test: 17,
        },
        {
            name: '12:00',
            orders: 10,
            sum: 10804,
            test: 18,
        },
        {
            name: '12:00',
            orders: 14,
            sum: 2923,
            test: 19,
        },
        {
            name: '12:00',
            orders: 18,
            sum: 65943,
            test: 20,
        },
        {
            name: '12:00',
            orders: 10,
            sum: 12223,
            test: 31,
        },
    ];

    const CustomTooltip = (props) => {
        if (props.active && props.payload && props.payload.length) {
          props.payload[0].chartType = "LineChart";
          return (
            <div className="tooltip-ecommerce">
                <div className="tooltip-ecommerce__order">
                    <span className="tooltip-ecommerce__order-label">{props.payload[0].payload.orders}</span>
                    <span>orders</span>
                </div>
                <div className="tooltip-ecommerce__sum">
                    <span className="tooltip-ecommerce__sum-label">{props.payload[0].payload.sum}</span>
                    <span>mkd</span>
                </div>
            </div>
          );
        }
    
        return null;
    };

    return (
        <div className="dashboard-chart">
            <div className="dashboard-chart__header">
                <SectionTitle icon="line-chart" type="fill">Today's Sales</SectionTitle>
                <div className="dashboard-chart__header-dates">
                    <span className="rectangle_blue"></span>
                    <span>Today</span>
                    <span className="rectangle_gray"></span>
                    <span><Echo.Date locale="en-GB" style="full">{datePicker}</Echo.Date></span>
                </div>
                <DatePicker 
                    value={datePicker}
                    onChange={datePickerChange}
                    dateFormat={dateFormat1}
                    mondayFirst={true}
                    locale="en-US"
                    customClassName="dashboard-chart__datepicker"
                />
            </div>
            <div className="dashboard-chart__linechart-ecommerce">
                <ResponsiveContainer width={"99%"} height={400}>
                    <LineChart width={300} height={400} data={data} margin={{ top:20, left: -25, bottom: 5}}>
                        <XAxis
                            dataKey="name"
                            stroke={colors.greyLight}
                            tick={{ fill: colors.greyDark, fontSize: 14, dx: 1}}
                        />
                        <YAxis
                            stroke={colors.greyLight}
                            type="number"
                            ticks={[0, 10, 20, 30, 40, 50]} 
                            tick={{fill: colors.greyDark, fontSize: 14, dy: -25}}
                        />
                        <Line
                            type="monotone"
                            dataKey="orders"
                            stroke={colors.blue}
                            strokeWidth="1.5"
                            dot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1}} 
                            activeDot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1 }} 
                        />
                        <Tooltip content={<CustomTooltip/>}/>
                        <Line
                            type="monotone"
                            dataKey="test"
                            stroke={colors.greyDark}
                            strokeWidth="1.5"
                            dot={{fill:colors.greyDark , stroke:colors.greyDark, strokeWidth: 1}} 
                            activeDot={{fill:colors.greyDark ,stroke:colors.greyDark, strokeWidth: 1 }} 
                        />
                        <CartesianGrid stroke={colors.greyDark} opacity="0.2"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default DashboardChart;