// vendor imports 
import React, { useEffect, useState } from "react";
import oblax from 'services/oblax';

// components
import StatTableCard from "com/widgets/StatTableCard";

// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';

// styles
import './style.css';

const StatsBestSellers = (props) => {

	const bestSellersHeaders = [l11n.t('PRODUCT'), l11n.t('AMOUNT'), l11n.t('WORTH')];
	const [loading, setLoading] = useState(true);
	const [productsData, setProductsData] = useState([]);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			try {
				let res = await oblax.stats.bestSellersProducts();
				if (res.ok) {
					setLoading(false);
					setProductsData(res.data);
				}
			} catch (err) {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log(err);
				}
				sendNotification({ type: 'error', title: l11n.t('ERROR_GET_BEST_SELLERS') });
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return (
		<StatTableCard title="Best sellers" icon="list-ordered " columns={bestSellersHeaders}>
			{
				loading ?
					<StatTableCard.Loader cols={3} rows={5} /> :
					<TableData data={productsData} />
			}
		</StatTableCard>
	)
};

const TableData = (props) => {
	return (
		props.data.length > 0 ?
			props.data.map((p, i) => {
				const src = p.product_images ? `${process.env.REACT_APP_MINIO_CDN}/products/${p.product_images.filter(i => i.cover)[0].path.replace(/\ /g, '%20')}` : null;
				return (
					<tr key={i}>
						<td>
							<div className="product-data__wrapper">
								<div className="product-data__wrapper-picture">
									<img src={src} alt="" />
								</div>
								<div className="product-data__details">
									<span className="product-data__details-name">{p.product_name}</span>
									<span className="product-data__details-vendor">{p.manufacturer_name}</span>
								</div>
							</div>
						</td>
						<td>
							<div className="product-data__amount-wrapper">
								<span className="product-data__amount-value">{p.quantity}</span>
								<span className="product-data__amount-tag">pcs</span>
							</div>

						</td>
						<td>
							<div className="product-data__worth-wrapper">
								<span className="product-data__worth">{p.total}</span>
								<span className="product-data__worth-tag">{p.currency}</span>
							</div>
						</td>
					</tr>
				);
			}) :
			<StatTableCard.NoData colspan={3} />
	);
};

export default StatsBestSellers;