import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Paging = (props) => {
	const [paging, setPaging] = useState([]);
	const [pages, setPages] = useState({ first: 1, prev: 1, next: 1, last: 1 });

	useEffect(() => {
		let mask = props.mask;
		let page = props.page;
		let totalRows = props.totalRows;
		let limit = props.limit;
		let maxPagesNumber = (totalRows % limit > 0 ? 1 : 0) + ((totalRows - totalRows % limit) / limit);
		setPages({
			first: 1,
			prev: page > 1 ? page - 1 : 1,
			next: page < maxPagesNumber ? page + 1 : maxPagesNumber,
			last: maxPagesNumber
		});
		setPaging(generatePagingArray(page, maxPagesNumber, mask));
	}, [props.page, props.limit, props.mask, props.totalRows]);

	/**
	 * @param { current page } page 
	 * @param { maximum page numbers based on data length } max 
	 * @param { how many page numbers to be shown in the paging } mask 
	 * @returns Returns an array of page numbers
	 */
	const generatePagingArray = (page, max, mask) => {
		let pageNumbers = [];
		let half = Math.floor(mask / 2);
		let start = page - half + 1 - mask % 2;
		let end = page + half;
		if (mask > max) {
			mask = max;
		}
		// handle start boundary case
		if (start <= 0) {
			start = 1;
			end = mask;
		}
		// handle end boundary case
		if (end > max) {
			start = max - mask + 1;
			end = max;
		}
		// populate the visible paging numbers array
		for(let i = start; i <= end; i++) {
			pageNumbers.push(i);
		}
		return pageNumbers;
	};

	const onClick = (e) => {
		props.onClick(Number(e.target.dataset.page));
	};

	return (
		<div className='table-paging'>
			<button onClick={onClick} data-page={pages.first} className='table-paging__paging-button light'>First</button>
			<button onClick={onClick} data-page={pages.prev} className='table-paging__paging-button light'>Previous</button>
			{paging.map((pageNumber, index) => {
				return <button onClick={onClick} key={index} data-page={pageNumber} className={`table-paging__paging-button light ${pageNumber === props.page ? 'active' : ''}`}>{pageNumber}</button>
			})}
			<button onClick={onClick} data-page={pages.next} className='table-paging__paging-button light'>Next</button>
			<button onClick={onClick} data-page={pages.last} className='table-paging__paging-button light'>Last</button>
		</div>
	);

};

Paging.propTypes = {
	limit: PropTypes.number,
	mask: PropTypes.number,
	onClick: PropTypes.func,
	page: PropTypes.number,
	totalRows: PropTypes.number
};

Paging.defaultProps = {
	limit: 5,
	mask: 5,
	onClick: () => {},
	page: 1,
	totalRows: 32
};

export default Paging;