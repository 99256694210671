import React from 'react';
import PropTypes from 'prop-types';

import ICON_INFO from 'assets/images/icons/input-info.svg';
import ICON_INPUT_TEXT from 'assets/images/icons/input-type-text.svg';
import ICON_INPUT_EMAIL from 'assets/images/icons/input-type-email.svg';
import ICON_INPUT_NUMBER from 'assets/images/icons/input-type-number.svg';
import ICON_INPUT_PASSWORD from 'assets/images/icons/input-type-password.svg';

import './style.css';

const icons = {
    text: ICON_INPUT_TEXT,
    number: ICON_INPUT_NUMBER,
    password: ICON_INPUT_PASSWORD,
    email: ICON_INPUT_EMAIL
};

const Input = (props) => {

    let showError = props.error ? 'error' : '';
    let setDisabled = props.disabled ? 'disabled' : '';

    return (
        <label className={`input-group ${showError} ${setDisabled} ${props.customClassName}`}>
            <span className="input-title">
                {props.label}
                {props.description && (
                    <span className="description">
                        <img src={ICON_INFO} alt="" />
                        <span className="info-text">{props.description}</span>
                        <span className="info-text-arrow"></span>
                    </span>
                )}
            </span>
            <input
                className="input-box"
                style={{ backgroundImage: `url(${icons[props.type]})` }}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                type={props.type}
                placeholder={props.placeholder}
                disabled={props.disabled}
            />
            <span className="input-error">{props.error}</span>
        </label>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number', 'email']),
    onChange: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    customClassName: PropTypes.string,
    name: PropTypes.string
};

Input.defaultProps = {
    label: '',
    description: '',
    type: 'text',
    onChange: () => {},
    error: '',
    placeholder: '',
    disabled: false,
    customClassName: '',
    name: ''
};

export default Input;