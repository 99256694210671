// vendors
import React from "react";
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from 'com/templates/ListViewTemplate';
//ui components
import Button from "com/ui/Button";
import DataTable, { Paging } from "com/ui/DataTable";
//widgets
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
//components
import Carousel from "./Carousel";
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
//styles
import './style.css';

import useDataTable from 'services/hooks/use_datatable';

const EcommerceWarehouse = (props) => {

    const headerNav = [
		{ to: '/ecommerce', label: 'Dashboard' },
		{ to: '/ecommerce/products', label: 'Products' },
		{ to: '/ecommerce/categories', label: 'Categories' },
        { to: '/ecommerce/orders', label: 'Orders'},
        { to: '/ecommerce/warehouse', label: 'Warehouse'},
	];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    const initTableHeaders = [
        { title: 'Vendor', field: 'vendor_name', type: 'string', sort: 'none', sortable: true },
        { title: 'Import Date', field: '_created', type: 'date', sort: 'none', sortable: true },
        { title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
        { title: 'No. Products', field: 'products_count', type: 'number', sort: 'none', sortable: true },
        { title: 'Price Total', field: 'total_price', type: 'number', sort: 'none', sortable: true },
        { title: 'Price Total With Tax', field: 'total_price_with_tax', type: 'number', sort: 'none', sortable: true },
        { title: 'Price Margin', field: 'price_margin', type: 'number', sort: 'none', sortable: false },
        { title: 'Supervised By', field: 'received_by_first_name', type: 'string', sort: 'none', sortable: true },
        { title: 'Approved By', field: 'approved_by_first_name', type: 'string', sort: 'none', sortable: true }
    ];

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange, 
        columnSort,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initTableHeaders, 
        'warehouse.getReceivingsV2'
    );

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];

    const rowClickHandler = (e) => {
        let rowID = e.target.closest('tr').dataset.rowId;
        console.log(rowID);
    };

    return (
        <ApplicationTemplate
            headerTitle="E-Commerce"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <Carousel/>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="warehouse"
                        onApply={filterApply}
                    />
                    <Button
                        type="secondary"
                        icon={ICON_PLUS}
                        customClassName="warehouse__create-new-btn"
                    >
                        Create New
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <DataTable columns={tableHeaders} onColumnSort={columnSort}>
                        {
                            tableData.map((w,i) => {
                                return (
                                    <tr key={w.id} data-row-id={w.id} onClick={rowClickHandler}>
                                        <DataTable.VendorCell city={w.vendor_location}>{w.vendor_name}</DataTable.VendorCell>
                                        <DataTable.DateCell>{w._created}</DataTable.DateCell>
                                        <DataTable.StatusCell status={w.status}/>
                                        <DataTable.Cell>{w.products_count}</DataTable.Cell>
                                        <DataTable.ValueCell currency={w.currency}>{w.total_price}</DataTable.ValueCell>
                                        <DataTable.ValueCell currency={w.currency}>{w.total_price_with_tax}</DataTable.ValueCell>
                                        <DataTable.ValueCell currency={w.currency}>{w.total_price_with_tax - w.total_price}</DataTable.ValueCell>
                                        <DataTable.UserCell avatar='' city={w.city}>{w.received_by_first_name} {w.received_by_last_name}</DataTable.UserCell>
                                        <DataTable.UserCell avatar='' city={w.city}>{w.approved_by_first_name} {w.approved_by_last_name}</DataTable.UserCell>
                                        <DataTable.ActionCell actions={actions} actionData={w}/>
                                    </tr>
                                )
                            })
                        }
                    </DataTable>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );
};

export default EcommerceWarehouse;