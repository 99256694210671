import React, { createContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';

const FilterContext = createContext();

const FilterContextProvider = (props) => {
    const [filterOptions, setFilterOptions] = useState();
    const [dataTypes, setDataTypes] = useState([]);
    const [savedFilters, setSavedFilters] = useState([]);
    const [filter, setFilter] = useState({});
    const [applyTrigger, setApplyTrigger] = useState(0);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        setFilterOptions(props.filterOptions);
    }, [props.filterOptions]); 

    useEffect(() => {
        setDataTypes(props.dataTypes);
    }, [props.dataTypes]); 

    useEffect(() => {
        setSavedFilters(props.savedFilters);
    }, [props.savedFilters]); 

    useEffect(() => {
        if(shouldUpdate) {
            setShouldUpdate(false);
            props.onApply(filter);
        }
    }, [filter]);

    const applyFilters = (direct = false) => {
        if(!direct) {
            setShouldUpdate(true);
            setApplyTrigger(applyTrigger + 1);
        } else {
            setApplyTrigger(applyTrigger + 1);
            props.onApply(filter);
        }
    };

    return (
        <FilterContext.Provider value={{
            filterOptions,
            setFilterOptions,
            dataTypes,
            setDataTypes,
            filter, 
            setFilter,
            applyTrigger,
            applyFilters,
            savedFilters,
            setSavedFilters,
            id,
            name,
            setId,
            setName,
            popoverSetActive: props.popoverSetActive,
            tableName: props.tableName
        }}>
            {props.children}
        </FilterContext.Provider>
    )
};

FilterContextProvider.propTypes = {
    dataTypes: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        field: PropTypes.string,
        type: PropTypes.oneOf(['string', 'number', 'date', 'list'])
    }))
};

export { FilterContextProvider, FilterContext };