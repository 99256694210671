import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DEFAULT_AVATAR from 'assets/images/default_avatar.jpg';

import './style.css';

const Avatar = (props) => {
    const [error, setError] = useState(false);
    let image = `${process.env.REACT_APP_MINIO_GATEWAY }avatars/${props.src}`;

    const onError = () => {
        setError(true);
    }

    return (
        <picture className="user-avatar">
            {!error ? <source srcSet={image}/> : null}
            <img src={DEFAULT_AVATAR} alt="" onError={onError}/>
        </picture>
    );
};

Avatar.propTypes = {
    src: PropTypes.string
};

Avatar.defaultProps = {
    src: ''
};

export default Avatar;