import React from 'react';

import './style.css';

const Spinner = (props) => {
    return (
        <div className="lds-ripple"><div></div><div></div></div>
    );
};

export default Spinner;