import React from 'react';
import PropTypes from 'prop-types';
// templates
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
//styles
import './style.css';

const Numbers = (props) => {
	return (
		<StatsCardTemplate>
			<div className="stats-card-numbers">
				<span className="stats-card-title">{props.title}</span>
				<div className="stats-card-values">
					{props.values.map((v, i) => {
						let color = i % 2 === 0 ? 'orange' : 'blue';
						return (
							<div key={i} className="numbers-value-wrapper">
								<span className={`numbers-value ${color}`}>{v.value}</span>
								<span className="stats-card--footer">{v.label}</span>
							</div>
						)
					})}
				</div>
			</div>
		</StatsCardTemplate>
	);
};

Numbers.propTypes = {
	title: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.number,
		label: PropTypes.string
	})).isRequired
};

export default Numbers;