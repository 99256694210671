//vendor imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// ui
import Icon from 'com/ui/Icon';
// custom hooks
import useClickOutside from 'services/hooks/use_clickoutside';
// styles
import './style.css';

const ActionsMenu = (props) => {
    const [isActive, setIsActive] = useState(false);
    const actionsBtn = useClickOutside(() => {
        setIsActive(false);
    });

    const onActionsClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsActive(!isActive);
    };

    return (
        <button className='actions-menu__button' onClick={onActionsClick} ref={actionsBtn}>
            <Icon 
                name="settings-5" 
                type="fill" 
                color="lightgrey" 
                size="medium"
            />
            {isActive ?
                <div className="actions-menu__options">
                    <span className="actions-menu__triangle"></span>
                    <ul className="actions-menu__options-values">
                        {props.actions.map((a, i) => {
                            return (
                                <Option 
                                    key={i} 
                                    id={a.id} 
                                    action={a.action} 
                                    label={a.label} 
                                    data={props.data}
                                    icon={a.icon}
                                />
                            )
                        })}
                    </ul>
                </div> :
            null}
        </button>
    );
};

ActionsMenu.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        action: PropTypes.func,
        label: PropTypes.string,
        icon: PropTypes.string
    })),
    data: PropTypes.object
};

ActionsMenu.defaultProps = {
    actions: [],
    data: {}, 
};

const Option = (props) => {
    const handleOnClick = () => {
        props.action(props.data);
    };

    return (
        <li className="actions-menu__options__container">
            <Icon 
                name={props.icon}
                type="fill" 
                color="lightgrey" 
                size="medium"
            />
            <span 
                className="actions-menu-settings__options__item" 
                onClick={handleOnClick}
            >
                {props.label}
            </span>
        </li>
    )
};

Option.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    action: PropTypes.func,
    data: PropTypes.any
};

Option.defaultProps = {
    label: 'Action',
    icon: 'file-settings',
    action: (p) => { console.log(p); },
    data: null
};

export default ActionsMenu;