import React from 'react';
import PropTypes from 'prop-types';
//component
import Icon from '../Icon';
//styles
import './style.css';

const SectionTitle = (props) => {
	return (
		<div className="section-title">
			<Icon name={props.icon} color="secondary" size="large" type="fill"/>
			<div className="section-title-text">{props.children}</div>
		</div>
	);
};

SectionTitle.propTypes = {
	icon: PropTypes.string,
	children: PropTypes.string
};

SectionTitle.defaultProps = {
	icon: 'settings-3',
	title: ''
};

export default SectionTitle;