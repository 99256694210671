// vendor imports
import React from 'react';
//components
import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCard from 'com/widgets/StatsCard';

const Carousel = (props) => {

    const numbers2 = [
        { value: 4, label: 'pending' },
        { value: 92, label: 'total' }
    ];

    const pieValues = [
        { value: 24, label: 'fully set' },
        { value: 17, label: 'minimum' },
    ];

    return (
        <StatsCarousel>
            <StatsCard.Percentages
                title="Inactive"
                value={15}
                label="of your users"
            />
            <StatsCard.Percentages
                title="Active users"
                value={25}
                reverseColors={true}
            />
            <StatsCard.Pie
                title="User profiles"
                values={pieValues}
            />
            <StatsCard.Numbers
                title="Registration"
                values={numbers2}
            />
            <StatsCard.Percentages
                title="Registration"
                value={85}
                label="success"
                reverseColors={false}
            />
            <StatsCard.Percentages
                title="Unregistered"
                value={3}
                label="of your users"
                reverseColors={true}
            />
        </StatsCarousel>
    )
};

export default Carousel;