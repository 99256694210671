// vendor imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// components
import PopoutInput from '../PopoutInput/index';

export const SingleImage = (props) => {
	const [title, setTitle] = useState(props.title);

	const selectCover = () => {
		props.selectCover(props.index, props.image.cover);
	};

	const removeImage = () => {
		props.removeImage(props.index);
	};

	const changeTitle = () => {
		props.changeTitle(props.index, title);
	};

	useEffect(() => {
		changeTitle();
	}, [title]);

	return (
		<div className='container-single-image' key={props.index}>
			<div className='wrapper-single-image'>
				<img className='single-image' src={props.image.url} alt=''/>
				<div className="container-single-image__item-backdrop"></div>
				<span className={props.image.cover ? 'checked-image' : 'unchecked-image'} onClick={selectCover}></span>
				<span className='title-popout-input'>
					<PopoutInput
						placeholder='Image description'
						value={title}
						onChange={setTitle}
						showError={false}
						errorMessage={'Error has happened!'}
						disabled={false}
						iconTheme="light"
					/>
				</span>
				<span className='remove-image' onClick={removeImage}></span>
			</div>
		</div>
	);
};

SingleImage.propTypes = {
	index: PropTypes.number.isRequired,
	image: PropTypes.shape({
		url: PropTypes.string,
		cover: PropTypes.bool,
		title: PropTypes.string,
	}),
	title: PropTypes.string,
	selectCover: PropTypes.func,
	removeImage: PropTypes.func,
	changeTitle: PropTypes.func
};

SingleImage.defaultProps = {
	index: 0,
	image: [],
	title: '',
	selectCover: () => {},
	removeImage: () => {},
	changeTitle: () => {}
};