import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Dropdown from 'com/ui/Dropdown';

const DemoDropdown = (props) => {

    const countries = [
        { value: 'Macedonia', label: 'Macedonia' },
        { value: 'Serbia', label: 'Serbia' },
        { value: 'Bulgaria', label: 'Bulgaria' },
        { value: 'Albania', label: 'Albania' },
        { value: 'Montenegro', label: 'Montenegro' },
        { value: 'Croatia', label: 'Croatia' },
        { value: 'Germany', label: 'Germany' },
        { value: 'Sweden', label: 'Sweden' },
        { value: 'Spain', label: 'Spain' },
        { value: 'France', label: 'France' },
        { value: 'Italy', label: 'Italy' },
        { value: 'Poland', label: 'Poland' },
    ];

    const [dropdown, setDropdown] = useState('Montenegro');

    const dropDownChange = (e) => {
        setDropdown(e.target.value);
    };

    return (
        <ComponentDemoTemplate>
            <>
                <Dropdown
                    label="Dropdown label"
                    options={countries}
                    value={dropdown}
                    onChange={dropDownChange}
                    search={true}
                />
                <hr/>
                <Dropdown
                    label="Dropdown label"
                    options={countries}
                    value={dropdown}
                    onChange={dropDownChange}
                    search={false}
                />
                <hr />
                <Dropdown
                    label="Dropdown label"
                    options={countries}
                    value={dropdown}
                    onChange={dropDownChange}
                    disabled={true}
                    search={true}
                />
                <hr />
                <Dropdown
                    label="Dropdown label"
                    options={countries}
                    value={dropdown}
                    onChange={dropDownChange}
                    search={true}
                    error="Some error... IDK..."
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoDropdown;