export default [
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
    { 
        id: 'ma123456789', 
        first_name: 'Ivana', 
        last_name: 'Kozolovska', 
        city: 'Skopje', 
        avatar: '', 
        status: 'active', 
        registration_date: new Date(), 
        last_login: new Date(), 
        email: 'ivanak@gmail.com', 
        country: 'North Macedonia', 
        acct_provider: 'boostercloud', 
        role: 'User',
        category: 'people',
        access_request_date: new Date() 
    },
];