// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// components
import { Tag } from './tag';
// styles
import './style.css';

const Tagbox = (props) => {
	let showError = props.error ? 'error' : '';
	let setDisabled = props.disabled ? 'disabled' : '';

	const customFunction = (data) => {
		let arrOfStrings = [];
		return arrOfStrings;
	};

	const addTags = (event) => {
		if (event.target.value !== "") {
			let tags = props.value.map(tag => tag);
			if (!tags.includes(event.target.value)) {
				props.onChange({
					target: {
						name: props.name,
						value: [...props.value, event.target.value]
					}
				});
				customFunction(event.target.value);
			}
			event.target.value = "";
		}
	};
	const onKeyUp = (event) => event.key === "Enter" ? addTags(event) : null;

	const removeTags = (indexToRemove) => {
		if(!props.disabled) {
			props.onChange({
				target: {
					name: props.name,
					value: [...props.value.filter((t, index) => index !== indexToRemove)]
				}
			});
		}
	};

	return (
		<label className={`tagbox ${showError} ${setDisabled} ${props.customClassName}`}>
			<span className="tagbox-label">
				{props.label}
			</span>
			<div className="tagbox-wrapper">
				{props.value.map((tag, index) => (
					<Tag
						key={index}
						index={index}
						tag={tag}
						removeTags={removeTags}
					/>
				))}
				<input
					className="tag-box"
					type="text"
					onKeyUp={onKeyUp}
					disabled={props.disabled}
				/>
			</div>
			<span className="tagbox-error">{props.error}</span>
		</label>
	);
};

Tagbox.propTypes = {
	label: PropTypes.string,
	value: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	customClassName: PropTypes.string,
	name: PropTypes.string
};

Tagbox.defaultProps = {
	label: '',
	value: [],
	onChange: () => {},
	disabled: false,
	error: '',
	customClassName: '',
	name: ''
};

export default Tagbox;