import React, {useContext, useEffect, useState} from 'react';

import SavedFilters from './savedFilters';
import FilterBuilder from './filterBuilder';

import { FilterContext } from './context';

const Popover = (props) => {
    const filterCtx = useContext(FilterContext);
    const [listActive, setListActive] = useState(false);

    useEffect(() => {
        let f = 0;
        Object.keys(filterCtx.filter).forEach(k => {
            if(filterCtx.filter[k].length > 0) f++;
        });
        if(f) {
            setListActive(listActive => !listActive);
        }
    }, []);

    const toggleListActive = (e) => {
        setListActive(listActive => !listActive);
    };

    return (
        <>
            <span className="popout-data-filter__popover_arrow"></span>
            <div className="popout-data-filter__popover">
                <SavedFilters 
                    hide={listActive}
                    onClickCreate={toggleListActive}
                />
                <FilterBuilder 
                    onClickBack={toggleListActive}
                />
            </div>
        </>
    );
};

export default Popover;