import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FilterContext } from './context';

const TagList = (props) => {
    const filterCtx = useContext(FilterContext);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        let tagItems = [];
        Object.keys(filterCtx.filter).forEach(k => {
            if(filterCtx.filter[k].length > 0) {
                tagItems = [
                    ...tagItems, 
                    {
                        label: filterCtx.dataTypes.find(dt => dt.field === k).title, 
                        value: k
                    }
                ]
            }
        });
        setTags(tagItems);
    }, [filterCtx.applyTrigger])

    const remove = (e) => {
        e.persist();
        filterCtx.setFilter(state => {
            return {
                ...state,
                [e.target.dataset.id]: []
            };
        });
        filterCtx.applyFilters();
    };

    return (
        <ul className="popout-data-filter__tag-list">
            {tags.map((t, i) => {
                return (
                    <li key={i} className="popout-data-filter__tag-list__tag">
                        {t.label}
                        <span onClick={remove} data-id={t.value} className="popout-data-filter__tag-list__tag__remove">&times;</span>
                    </li>
                )
            })}
        </ul>
    );
};

TagList.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })),
    onTagRemove: PropTypes.func
};

TagList.defaultProps = {
    tags: [],
    onTagRemove: () => {}
};

export default TagList;