import React, { useEffect, useContext } from 'react';

import Button from 'com/ui/Button';

import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
import ICON_FILTER from 'assets/images/icons/filter-orange.svg';
import ICON_TRASH from 'assets/images/icons/trash-grey.svg';
import ICON_EDIT from 'assets/images/icons/edit-grey.svg';

import oblax from 'services/oblax';

import { FilterContext } from './context';

import Echo from 'com/util/Echo';

const SavedFilters = (props) => {
    const filterCtx = useContext(FilterContext);

    const createFilter = () => {
        filterCtx.setId('');
        filterCtx.setName('');
        filterCtx.setFilter({});
        props.onClickCreate();
    };

    return (
        <div className={`popout-data-filter__popover__saved-filters ${props.hide ? 'hide' : ''}`}>
            <div className="popover__saved-filters__header">
                <span className="popover__saved-filters__header__title">Saved filters</span>
                <Button 
                    type="secondary" 
                    icon={ICON_PLUS} 
                    onClick={createFilter}
                >
                    Create New
                </Button>
            </div>
            <div className="popover__saved-filters__body">
                <ul className="popover__saved-filters__list">
                    {filterCtx.savedFilters.map((li, i) => {
                        return (
                            <ListItem 
                                key={i} 
                                data={li} 
                                onClickCreate={props.onClickCreate}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

const ListItem = (props) => {
    const filterCtx = useContext(FilterContext);

    const filterFields = (obj) => {
        let out = [];
        Object.keys(obj).forEach(k => {
            if(obj[k].length > 0) {
                out.push(k);
            }
        });
        return out;
    };

    const fields = filterFields(props.data.filter);

    const onEdit = (e) => {
        e.stopPropagation();
        filterCtx.setFilter(props.data.filter);
        props.onClickCreate();
        filterCtx.setName(props.data.name);
        filterCtx.setId(props.data.id);
    }

    const onDelete = async (e) => {
        e.stopPropagation();
        try {
            await oblax.dataFilter.removeDataFilter(props.data.id);
            filterCtx.setSavedFilters(filterCtx.savedFilters.filter(f => {
                return f.id !== props.data.id;
            }));
        } catch(err) {
            console.log(err);
        }
    }

    const onClick = () => {
        filterCtx.setFilter(props.data.filter);
        filterCtx.applyFilters();
        filterCtx.popoverSetActive(false);
        filterCtx.setName(props.data.name);
        filterCtx.setId(props.data.id);
    }
    
    return (
        <li className="popover__saved-filters__list-item" onClick={onClick}>
            <div className="popover__saved-filters__list-item__left">
                <span className="popover__saved-filters__list-item__left__title">{props.data.name}</span>
                <div className="popover__saved-filters__list-item__left__tags">
                    <img src={ICON_FILTER} alt="" />
                    {fields.map((f, i) => {
                        return <span className="popover__saved-filters__list-item__left__tag" key={i}>{f}</span>
                    })}
                </div>
            </div>
            <div className="popover__saved-filters__list-item__right">
                <div className="popover__saved-filters__list-item__right__created">
                    <span className="popover__saved-filters__list-item__right__created-label">created</span>
                    <span className="popover__saved-filters__list-item__right__created-date">
                        <Echo.Date>{props.data._created}</Echo.Date>
                    </span>
                </div>
                <button 
                    className="popover__saved-filters__list-item__right__edit"
                    onClick={onEdit}
                >
                    <img src={ICON_EDIT} alt="" />
                </button>
                <button 
                    className="popover__saved-filters__list-item__right__delete"
                    onClick={onDelete}
                >
                    <img src={ICON_TRASH} alt="" />
                </button>
            </div>
        </li>
    );
};

export default SavedFilters;