import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
// templates
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
//styles
import './style.css';

const Pie = (props) => {
	const shiftSize = 2;
	const [data, setData] = useState([]);
	
	useEffect(() => {
		let d = [];
		let total = 100;
		for(let i in props.values) {
			total -= props.values[i].value;
			d.push({
				value: props.values[i].value,
				color: i % 2 === 0 ? '#359fdc' : '#FF5800' 
			});
		}
		d.push({
			value: total,
			color: '#989898'
		});
		setData(d);
	}, [props.values]);

	return (
		<StatsCardTemplate>
			<div className="stats-card-chart__wrapper">
				<div className="stats-card__text">
					<span className="stats-card-title">{props.title}</span>
					<div className="stats-card-description">
						{props.values.map((v, i) => {
							let color = i % 2 === 0 ? 'blue' : 'orange';
							return (
								<div key={i} className="label-wrapper">
									<div className={`square ${color}`}></div>
									<span className="stats-card--label">{v.label}</span>
								</div>		
							)
						})}
						<span className="stats-card--footer">{props.label}</span>
					</div>
				</div>
				<div className="stats-card__pie-chart">
					<PieChart
						data={data}
						radius={PieChart.defaultProps.radius - shiftSize}
						segmentsShift={shiftSize}
						startAngle={270}
					/>
				</div>
			</div>
		</StatsCardTemplate>
	);
};

Pie.propTypes = {
	title: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.number,
		label: PropTypes.string
	})).isRequired,
	label: PropTypes.string
};

Pie.defaultProps = {
	label: ''
};

export default Pie;