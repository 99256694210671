//import vendors
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
//import date-arrays
import { months, weekDays, weekDaysSunFirst } from 'data/date-constants'
//import icons
import rightArrow from 'assets/images/icons/blue-arrow-next.svg';
import leftArrow from 'assets/images/icons/blue-arrow -previously.svg';
import calendarIcon from 'assets/images/icons/calendar-icon.svg';
//import styles
import './style.css';

const DatePicker = (props) => {
	const [isActive, setIsActive] = useState(false);
	const [calendar, setCalendar] = useState();
	const [showDate, setShowDate] = useState();
	const [zTop, setZTop] = useState('');
	const [calendarDays, setCalendarDays] = useState([]);
	const  wrapperRef = useRef(null);

	const currentDate = new Date();

	let setDisabled = props.disabled ? 'disabled' : '';
	let showError = props.error ? 'error' : '';

	const handleClickOutsideDatePicker = (e) => {
		if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
			setIsActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutsideDatePicker);
		return () => {
			document.removeEventListener("click", handleClickOutsideDatePicker);
		};
	}, []);

	useEffect(() => {
		if (props.type === 'date') {
			setShowDate(props.value.toLocaleDateString(props.locale, props.dateFormat));
			console.log('props.value', props.value);
			setCalendar(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		if (props.type === 'range') {
			let from = props.valueFrom ? props.valueFrom.toLocaleDateString(props.locale, props.dateFormat) : '--';
			let to = props.valueTo ? props.valueTo.toLocaleDateString(props.locale, props.dateFormat) : '--';
			setShowDate(`${from} - ${to}`);
			setCalendar(props.valueFrom);
		}
	}, [props.valueFrom, props.valueTo]);

	useEffect(() => {
		setZTop(isActive ? 'z-top' : '');
	}, [isActive]);

	useEffect(() => {
		if(calendar) {
			generateAllDays(calendar);
		}
	}, [calendar]);

	const dropDown = (e) => {
		setIsActive(!isActive);
		e.stopPropagation();
	};

	//function to generate the days
	const generateAllDays = (d) => {
		let month = d.getMonth();
		let year = d.getFullYear();
		let days = [];

		const numDaysInCurMonth = numberOfDaysInMonth(year, month);
		const numDaysInPrevMonth = numberOfDaysInMonth(year, month - 1);
		const firstWeekdayInCurrMonth = firstWeekdayInMonth(year, month);

		for(let i = 0; i < firstWeekdayInCurrMonth; i++) {
			let d;
			d = numDaysInPrevMonth - firstWeekdayInCurrMonth + 1 + i;
			days.push({
				date: d,
				month: month - 1 === -1 ? 11 : month - 1, 
				year: month - 1 === -1 ? year - 1 : year
			});
		}

		for(let i = 1; i <= numDaysInCurMonth; i++) {
			days.push({
				date: i,
				month: month,
				year: year
			});
		}

		let rest = 7 - (days.length % 7);

		for(let i = 1; i <= rest; i++) {
			days.push({
				date: i,
				month: month + 1 === 12 ? 0 : month + 1,
				year: month + 1 === 12 ? year + 1 : year
			});
		}

		setCalendarDays(days);
	};

	// JS starts date from 1. date 0 is the last day of previous month
	// JS starts month from 0
	// JS starts day from Sunday 0

	const numberOfDaysInMonth = (year, month) => {
		if(month === -1) { // if it's december from previous year
			return new Date(year - 1, 0, 0).getDate();	
		}
		if(month === 12) { // if it's january from next year
			return new Date(year + 1, 1, 0).getDate();
		}
		return new Date(year, month + 1, 0).getDate();
	};

	const firstWeekdayInMonth = (year, month) => {
		let day;
		if (month === -1) { // if it's december from previous year
			day = new Date(year - 1, 0, 1).getDay();
		} else if (month === 12) { // if it's january from next year
			day = new Date(year + 1, 1, 1).getDay();
		} else {
			day = new Date(year, month, 1, 12, 0, 0, 0).getDay();
		}

		if(props.mondayFirst) { // monday is the first day, shift one to the left
			return day === 0 ? 6 : day - 1;
		}
		return day;
	};

	const decrementMonth = (e) => {
		let cal = normalizeDate(calendar);
		cal.setDate(1);
		if (calendar.getMonth() > 0 && calendar.getMonth() <= 11) {
			cal.setMonth(cal.getMonth() - 1);
			setCalendar(cal);
		} else if (calendar.getMonth() === 0) {
			cal.setMonth(11);
			cal.setFullYear(cal.getFullYear() - 1);
			setCalendar(cal);
		}
	};

	const incrementMonth = () => {
		let cal = normalizeDate(calendar);
		cal.setDate(1);
		if (calendar.getMonth() >= 0 && calendar.getMonth() < 11) {
			cal.setMonth(cal.getMonth() + 1);
			setCalendar(cal);
		} else if (calendar.getMonth() === 11) {
			cal.setMonth(0);
			cal.setFullYear(cal.getFullYear() + 1);
			setCalendar(cal);
		}
	};

	const decrementYear = () => {
		let cal = normalizeDate(calendar);
		cal.setFullYear(cal.getFullYear() - 1);
		setCalendar(cal);
	};

	const incrementYear = () => {
		let cal = normalizeDate(calendar);
		cal.setFullYear(cal.getFullYear() + 1);
		setCalendar(cal);
	};

	const handleChangeDate = (e) => {
		e.stopPropagation();
		e.preventDefault();
		
		let day = Number(e.target.dataset.date);
		let month = Number(e.target.dataset.month);
		let year = Number(e.target.dataset.year);
		let date = new Date(year, month, day, 12, 0, 0, 0);

		switch(props.type) {
			case 'date':
				props.onChange({
					target: {
						name: props.name,
						value: date
					}
				});
				setIsActive(false);
			break;
			case 'range':
				let from = null;
				let to = null;
				if ((props.valueFrom && props.valueTo) || (!props.valueFrom && !props.valueTo)) {
					from = date;
				} else if(props.valueFrom && !props.valueTo) {
					from = props.valueFrom;
					to = date;
					setIsActive(false);
				};
				props.onChange({
					target: {
						name: props.name,
						value: {from, to}
					}
				});
			break;
			default:
				console.error('Unknown DatePicker type');
		}
	};

	const getCurrentDate = () => {
		props.onChange({
			target: {
				name: props.name,
				value: currentDate
			}
		});
	};

	const normalizeDate = (d) => {
		let cd = new Date(d);
		cd.setHours(12);
		cd.setMinutes(0);
		cd.setSeconds(0);
		cd.setMilliseconds(0);
		return cd;
	};

	const addClass = (day) => {
		let cd = normalizeDate(currentDate); // today
		let d = normalizeDate(props.value); // currently selected date
		let dy = new Date(day.year, day.month, day.date, 12, 0, 0, 0); // date from calendar
		let df = normalizeDate(props.valueFrom);
		let dt = normalizeDate(props.valueTo);

		let classList = [];

		switch(props.type) {
			case 'date':
				if (cd.getTime() === dy.getTime()) classList.push('current-day');
				if (props.value.getMonth() === day.month) classList.push('listed-month');
				if (calendar.getMonth() !== day.month) classList.push('unlisted');
				if (d.getTime() === dy.getTime()) classList.push('selected');
			break;
			case 'range':
				if (df.getTime() === dy.getTime()) classList.push('date-from');
				if (dt.getTime() === dy.getTime()) classList.push('date-to');
				if (props.valueFrom.getMonth() === day.month) classList.push('listed-month');
				if (calendar.getMonth() !== day.month) classList.push('unlisted');
				if (df.getTime() < dy.getTime() && dt.getTime() > dy.getTime()) classList.push('between');
			break;
			default:
				console.error('Unknown DatePicker type');
		}
		return classList.join(' ');
	};

	return (
		<div className={`datepicker-wrapper ${showError} ${setDisabled} ${props.customClassName}`}>
			<span className="datepicker-label">{props.label}</span>
			<label className="datepicker" ref={wrapperRef}>
				<div className={`datepicker-dropdown-wrapper ${zTop}`}>
					<button className="icon-and-input-wrapper" onClick={dropDown}>
						<img onClick={getCurrentDate} src={calendarIcon} alt="" className="icon-and-input-wrapper__icon"/>
						<span className="datepicker-input">{showDate}</span>
						<span className={`${isActive ? "dropdown-arrow-up" : "dropdown-arrow-down"}`}></span>
					</button>
					{isActive && (
						<>
							<div className="divider"></div>
							<div className={`datepicker-content`}>
								<div className="month-and-year-container">
									<div className="months-icons">
										<button className="month-arrow" onClick={decrementMonth}>
											<img src={leftArrow} alt="left arrow icon" />
										</button>
										<span>{months[calendar.getMonth()]}</span>
										<button className="month-arrow" onClick={incrementMonth}>
											<img src={rightArrow} alt="right arrow icon" />
										</button>
									</div>
									<div className="year-icons">
										<button className="year-arrow" onClick={decrementYear}>
											<img src={leftArrow} alt="left arrow icon" />
										</button>
										<span>{calendar.getFullYear()}</span>
										<button className="year-arrow" onClick={incrementYear}>
											<img src={rightArrow} alt="right arrow icon" />
										</button>
									</div>
								</div>
								<div className="days-of-week">
									{
										props.mondayFirst ? 
										weekDays.map((weekDay, i) => {
											return <div className="week-day" key={i}>{weekDay}</div>
										}) :
										weekDaysSunFirst.map((weekDay, i) => {
											return <div className="week-day" key={i}>{weekDay}</div>
										})
									}
								</div>
								<ul className="days-of-month">
									{calendarDays.map((day, index) => {
										let css = addClass(day);
										return (
											<li
												key={index}
												className="day-of-month"
												onClick={handleChangeDate}
											>
												<span 
													data-month={day.month}
													data-date={day.date}
													data-year={day.year}
													className={`day-of-month__circle ${css}`}
												>
													{day.date}
												</span>
											</li>
										)
									})}
								</ul>
							</div>
						</>
					)}
				</div>
			</label>
			<span className="datepicker-error">{props.error}</span>
		</div>
	);
};

DatePicker.propTypes = {
	value: PropTypes.instanceOf(Date),
	label: PropTypes.string,
	onChange: PropTypes.func,
	dateFormat: PropTypes.shape({
		weekday: PropTypes.oneOf(['long', 'short', 'numeric', '2-digit']),
		year: PropTypes.oneOf(['numeric', '2-digit']),
		month: PropTypes.oneOf(['long', 'short', 'numeric', '2-digit']),
		day: PropTypes.oneOf(['numeric', '2-digit']),
	}),
	mondayFirst: PropTypes.bool,
	locale: PropTypes.string,
	disabled: PropTypes.bool,

	type: PropTypes.oneOf(['date', 'range']),
	min: PropTypes.instanceOf(Date),
	max: PropTypes.instanceOf(Date),
	valueFrom: PropTypes.instanceOf(Date),
	valueTo: PropTypes.instanceOf(Date),
	customClassName: PropTypes.string
};

DatePicker.defaultProps = {
	value: new Date(),
	label: '',
	onChange: () => {},
	// dateFormat: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
	dateFormat: { year: 'numeric', month: 'short', day: 'numeric' },
	mondayFirst: true,
	locale: "en-US",
	disabled: false,

	type: 'date',
	valueFrom: new Date(),
	valueTo: new Date(),
	customClassName: ''
};

export default DatePicker;
