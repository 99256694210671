// vendor imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// component
import { Option } from './option';
// styles
import './style.css';

const Dropdown = (props) => {
    let setDisabled = props.disabled ? 'disabled' : '';
    let showError = props.error ? 'error' : '';
    let values = props.options.map((item) => item.value);

    const [isActive, setIsActive] = useState(false);
    const [zTop, setZTop] = useState('');
    const [query, setQuery] = useState('');
    const [cursor, setCursor] = useState(values.indexOf(props.value));
    const [options, setOptions] = useState([]);

    const wrapperRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setZTop(isActive ? 'z-top' : '');
    }, [isActive]);

    useEffect(() => {
        setOptions(!query ? 
            props.options : 
            props.options.filter(option =>
                option.label.toLowerCase().includes(query.toLowerCase())
            )
        );
    }, [props.options, query])

    const dropDown = () => {
        setIsActive(prevState => !prevState);
        setCursor(values.indexOf(props.value));
    };

    const selectItem = (item) => {
        props.onChange({
            target: {
                name: props.name,
                value: item
            }
        });
        setIsActive(false);
        setQuery("");
        setCursor(values.indexOf(item));
    };

    const handleArrowKeysScrollBrowser = () => {
        if (isActive === true) {
            document.onkeydown = (e) => {
                if (e.keyCode === 38 || e.keyCode === 40) {
                    return false;
                }
            }
        } else {
            document.onkeydown = (e) => {
                if (e.keyCode === 38 || e.keyCode === 40) {
                    return true;
                }
            }
        }
    };

    const toggleDropdownAndNavigation = (e) => {
        if (e.keyCode === 27) {
            setIsActive(false);
        } else if (e.keyCode === 13) {
            setIsActive(prevState => !prevState);
            props.onChange({
                target: {
                    name: props.name,
                    value: options[cursor].value
                }
            });
            setQuery("");
        } else if (e.keyCode === 38 && cursor > 0) {
            setCursor(cursor - 1);
        } else if (e.keyCode === 40 && cursor < options.length - 1) {
            setCursor(cursor + 1);
        }
        handleArrowKeysScrollBrowser();
    };

    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    // const options = !query ? props.options
    //     : props.options.filter(option =>
    //         option.label.toLowerCase().includes(query.toLowerCase())
    //     );

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setIsActive(false);
        }
    };

    const scrollTo = (ref) => {
        if (ref) {
            ref.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    const getLabelForValue = (value) => {
        // console.log(value);
        for(let o of options) {
            // console.log(`${o.value} === ${value}`, o.value === value);
            if(o.value === value) {
                return o.label;
            }
        }
        return '';
    };

    return (
        <div tabIndex="0" onKeyUp={toggleDropdownAndNavigation} className={`dropdown-wrapper ${setDisabled} ${showError} ${props.customClassName}`} ref={wrapperRef}>
            <span className="dropdown-wrapper__label">{props.label}</span>
            <div className={`dropdown-box ${zTop}`}>
                <div className="dropdown__btn" onClick={dropDown}>
                    <div className="dropdown__label">
                        <span className="dropdown__label__text">{getLabelForValue(props.value)}</span>
                        <span className={`${isActive ? "dropdown--arrow-up" : "dropdown--arrow-down"}`}></span>
                    </div>
                </div>
                {isActive && (
                    <div className="dropdown-content-box">
                        <div className="">
                            <div className="dropdown-content-box__divider"></div>
                            {props.search && <input type="text" className="dropdown-content-box__search-input" value={query} onChange={handleSearch} />}
                            <div className="dropdown-content-box__list">
                                {options.map((option, i) => (
                                    <Option
                                        key={i}
                                        indexKey={i}
                                        selectItem={selectItem}
                                        value={props.value}
                                        optionValue={option.value}
                                        scrollTo={scrollTo}
                                        label={option.label}
                                        active={i === cursor}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <span className="input-error">{props.error}</span>
        </div>
    )
};

Dropdown.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, label: PropTypes.string})),
    value: PropTypes.string,
    onChange: PropTypes.func,
    search: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    customClassName: PropTypes.string,
    name: PropTypes.string.isRequired
};

Dropdown.defaultProps = {
    label: '',
    options: [],
    value: '',
    onChange: () => {},
    search: false,
    disabled: false,
    error: '',
    customClassName: '',
    name: ''
};

export default Dropdown;