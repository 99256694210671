import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Checkbox from 'com/ui/Checkbox'; 

const DemoSwitch = (props) => {

    const [checkbox, setCheckbox] = useState(false);

    const onChange = (e) => {
        setCheckbox(state => !state);
    };

    return (
        <ComponentDemoTemplate>
            <Checkbox
                label="checkbox label"
                name='agree'
                checked={checkbox}
                disabled={false}
                onChange={onChange}
            />
        </ComponentDemoTemplate>
    );
};

export default DemoSwitch;