import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
// templates
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
//styles
import './style.css';

const Percentages = (props) => {
	const shiftSize = 2;
	const data = [
		{ 
			value: props.value, 
			color: props.reverseColors ? '#FF5800' : '#359fdc' 
		},
		{ 
			value: 100  - props.value, 
			color: props.reverseColors ? '#359fdc' : '#FF5800' 
		}
	];

	return (
		<StatsCardTemplate>
			<div className="stats-card-chart__wrapper">
				<div className="stats-card__text">
					<span className="stats-card-title">{props.title}</span>
					<div className="stats-card-description">
						<span className="percentage-value">{props.value}</span>
						<span className="percent-icon">%</span>
					</div>
					<span className="stats-card--footer">{props.label}</span>
				</div>
				<div className="stats-card__pie-chart">
					<PieChart
						data={data}
						radius={PieChart.defaultProps.radius - shiftSize}
						segmentsShift={shiftSize}
						startAngle={270}
					/>
				</div>
			</div>
		</StatsCardTemplate>
	);
};

Percentages.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	label: PropTypes.string,
	reverseColors: PropTypes.bool
};

Percentages.defaultProps = {
	label: '',
	reverseColors: false
};

export default Percentages;