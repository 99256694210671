// vendor imports
import React from 'react';

export const Option = (props) => {
    const selectItem = () => {
        props.selectItem(props.optionValue);
    };

    return (
        <div key={props.indexKey}
            onClick={selectItem}
            className={`dropdown-content-box__list-item ${props.value === props.optionValue ? "selected" : ""} ${props.active ? "active" : ""}`}
            ref={props.active ? props.scrollTo : null}
        >
            {props.label}
        </div>
    );
};
