//vendor imports
import React from "react";

//templates
import ComponentDemoTemplate from "com/templates/ComponentDemoTemplate";

//components
import ItemCard from "com/widgets/ItemCard";

const DemoItemCard = () => {

    const date = new Date();

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];

    const userAvatar = 'https://images.unsplash.com/photo-1510227272981-87123e259b17?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=3759e09a5b9fbe53088b23c615b6312e';

    const companyLogo = 'https://www.gstatic.com/images/branding/googlelogo/svg/googlelogo_clr_74x24px.svg';

    return (
        <ComponentDemoTemplate>
            <ItemCard 
                link="/"
                image={userAvatar}
                title='Paul Thomson'
                details='paul@gmail.com'
                subtitle='Dallas, TX, United States'
                footerLabel='Last modified'
                date={date}
                actions={actions}
            />
            <br/>
            <ItemCard 
                link="/"
                image={userAvatar}
                title='Paul Thomson'
                details='paul@gmail.com'
                subtitle='Dallas, TX, United States'
                footerLabel='Last modified'
                date={date}
                actions={actions}
            />
            <br/>
            <ItemCard 
                link="/"
                image={companyLogo}
                title='Google'
                details='4 contacts'
                subtitle='Dallas, TX, United States'
                footerLabel='Last modified'
                date={date}
                actions={actions}
            />
            <br/>
            <ItemCard 
                link="/"
                image={companyLogo}
                title='Nike'
                details='5 contacts'
                subtitle='Dallas, TX, United States'
                footerLabel='Last modified'
                date={date}
                actions={actions}
            />
        </ComponentDemoTemplate>
    )
};

export default DemoItemCard;