import en from './english';
import mk from './macedonian';

let activeLanguage = 'en';

const langs = {
    en,
    mk,
};

const setLanguage = (language) => {
    if (langs.hasOwnProperty(language)) {
        activeLanguage = language;
    }
};

const t = (...args) => {
    if(args.length === 0){
        return '';
    }
    let o = langs[activeLanguage][args[0]];
    for(let i = 1; i < args.length; i++){
        o = o.replace('%s', args[i]);
    }
    return o;
};

export default {
    setLanguage,
    t
};