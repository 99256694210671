import React from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
import NotificationLayer from 'com/util/NotificationLayer';

import Button from 'com/ui/Button';

import useNotification from 'services/hooks/use_notification';

import ICON_CONFIG from 'assets/images/icons/settings-blue.svg';

const DemoNotifications = (props) => {

    const sendNotification = useNotification();

    const addNewToast = () => {
        sendNotification({ type: 'warning', title: 'New toast' });
    };

    const addNewErrorToast = () => {
        sendNotification({ type: 'error', title: 'Error' });
    };

    const addNewInfoToast = () => {
        sendNotification({ type: 'info', title: 'Informative' });
    };

    const addNewSuccessToast = () => {
        sendNotification({ type: 'success', title: 'Success' });
    };

    const addNewActionable = () => {
        sendNotification({
            title: 'It is Christmas!',
            content: 'Santa Claus is coming to town!',
            actions: [
                { label: 'Yes', action: () => {console.log('one'); } },
                { label: 'No', action: () => {console.log('two'); } },
                { label: 'Maybe', action: () => {console.log('three'); } }
            ],
            icon: ICON_CONFIG
        });
    };

    return (
        <ComponentDemoTemplate>
            <>
                <Button onClick={addNewToast}>Toast</Button>
                <hr/>
                <Button onClick={addNewSuccessToast}>Success toast</Button>
                <hr/>
                <Button onClick={addNewErrorToast}>Error toast</Button>
                <hr/>
                <Button onClick={addNewInfoToast}>Info toast</Button>
                <hr/>
                <Button onClick={addNewActionable}>Actionable notification</Button>
                <NotificationLayer />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoNotifications;