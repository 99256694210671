import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import RadioGroup from 'com/ui/Radio/RadioGroup';

const DemoRadioGroup = (props) => {

    const [radio, setRadio] = useState('yellow');

    const colors = [
        { value: 'red', label: 'red color' },
        { value: 'pink', label: 'pink color' },
        { value: 'blue', label: 'blue color' },
        { value: 'green', label: 'green color' },
        { value: 'yellow', label: 'yellow color' },
        { value: 'purple', label: 'purple color' }
    ];

    const radioChange = (e) => {
        setRadio(e.target.value);
    };

    return (
        <ComponentDemoTemplate>
            <>
                <RadioGroup
                    options={colors}
                    name='colors'
                    disabled={false}
                    value={radio}
                    onChange={radioChange}
                />
                <hr/>
                <RadioGroup
                    options={colors}
                    name='colors2'
                    disabled={true}
                    value={radio}
                    onChange={radioChange}
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoRadioGroup;