import React from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCard from 'com/widgets/StatsCard';

const DemoStatsCarousel = () => {
    const numbers = [
        {value: 12, label: 'pending'},
        {value: 4, label: 'total'},
        {value: 81, label: 'total'}
    ];

    const numbers2 = [
        { value: 4, label: 'pending' },
        { value: 92, label: 'paid' }
    ];

    const pieValues = [
        { value: 24, label: 'on promotion'},
        { value: 17, label: 'on sale'},
        // { value: 17, label: 'not on sale'},
    ];

    const pieValues2 = [
        { value: 8, label: 'piece 1' },
        { value: 17, label: 'second' },
        { value: 12, label: 'third piece'},
    ];

    return (
        <ComponentDemoTemplate>
            <StatsCarousel>
                
                <StatsCard.Pie
                    title="Pricing"
                    values={pieValues}
                    label="of your products"
                />
                <StatsCard.Percentages
                    title="Closing expiery"
                    value={30}
                    label="of your products"
                    reverseColors={true}
                />
                <StatsCard.Numbers
                    title="Invoices"
                    values={numbers2}
                />
                <StatsCard.Percentages
                    title="Products"
                    value={14}
                    label="inactive products"
                />
                <StatsCard.Numbers
                    title="Orders"
                    values={numbers}
                />
                <StatsCard.Numbers
                    title="Some number"
                    values={[{ value: 1495, label: 'label' }]}
                />
                <StatsCard.Pie
                    title="Pricing"
                    values={pieValues2}
                    label="of your products"
                />
            </StatsCarousel>
        </ComponentDemoTemplate>
    )
};

export default DemoStatsCarousel;
