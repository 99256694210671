import React from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
import SectionTitle from 'com/ui/SectionTitle';

const DemoSectionTitle = () => {
    return (
        <ComponentDemoTemplate>
            <SectionTitle icon="settings-3">Some title whatsoever</SectionTitle>
        </ComponentDemoTemplate>
    );
};

export default DemoSectionTitle;