export default {
    WELCOME: 'Добредојде',
    SAMPLE: 'Тест текст %s',

    SUCCESS_LOGOUT: 'Успешна одјава',

    TITLE_RESET_PASSWORD: 'Промена на лозинка',
    TITLE_CHECK_YOUR_EMAIL: 'Провери го твојот email',
    TITLE_PASSWORD_CHANGED: 'Лозинката е променета!',

    PU_VIEW_REQUEST_ACCESS_MSG_TITLE: 'Пристапот е побаран',
    PU_VIEW_REQUEST_ACCESS_MSG_TEXT: 'Успешно побаравте пристап до oblax контролниот панел. Email порака ќе ви биде испратена веднаш штом администратор на системот го одобри вашето барање.',
    PU_VIEW_REQUEST_ACCESS_MSG_BUTTON: 'Назад кон најава',

    PU_VIEW_CHECK_EMAIL_MSG_TITLE: 'Проверете го вашиот email',
    PU_VIEW_CHECK_EMAIL_MSG_TEXT: 'Порака со врска е испратена на вашата email адреса. Ве молиме пристапете кон промена на лозинката во поскоро време поради временско ограничување на врската.Ви благодариме!',
    PU_VIEW_CHECK_EMAIL_MSG_BUTTON: 'Назад кон најава',

    PU_VIEW_PASSWORD_CHANGED_MSG_TITLE: 'Лозинката е променета!',
    PU_VIEW_PASSWORD_CHANGED_MSG_TEXT: 'Успешно ја променивте својата лозинка.Y Ве молиме продолжете на страната за најава од каде можете да пристапите на Oblax контролниот панел.',
    PU_VIEW_PASSWORD_CHANGED_MSG_BUTTON: 'Назад кон најава',

    ERROR_EMAIL_ADDRESS: 'Невалидна email адреса',
    ERROR_MIN_STRING_LENGTH: 'Премногу краток текст',
    ERROR_AUTH_WRONG_CREDENTIALS: 'Лоша најава',
    ERROR_AUTH_ACCOUNT_DOES_NOT_EXIST: 'Непостоечки профил',
    ERROR_AUTH_BAD_RESET_HASH: 'Невалиден линк за ресет на лозинка',
    ERROR_LOGOUT: 'Моментално не може да се одјавите. Пробајте повторно подоцна',
    ERROR_GET_USER_DATA: 'Не може да се земат податоците за корисникот',
    ERROR_AUTH_ACCOUNT_ALREADY_EXIST: 'Email адресата е веќе во употреба',
    
    ERROR_GET_MOST_ACTIVE_USERS:'Не може да се земат податоците за најактивните корисници',
    ERROR_GET_BIGGEST_SPENDERS:'Не може да се земат податоците за најголемите потрошувачи',
    ERROR_GET_MOST_LOYAL_USERS:'Не може да се земат податоците за најлојалните корисници',

    INFO_NO_DATA_MOST_ACTIVE_USERS:'Податоците за најактивни корисници се празни',
    INFO_NO_DATA_BIGGEST_SPENDERS:'Податоците за најголемите потрошувачи се празни',
    INFO_NO_DATA_MOST_LOYAL_USERS:'Податоците за најлојалните корисници се празни',

    ERROR_GET_LATEST_SIGNUPS: 'Не може да се земат податоците за последно регистрирани корисници',
    INFO_NO_DATA_LATEST_SIGNUPS: 'Нема податоци за последно регистрирани корисници',

    NO_DATA: 'Нема податоци во моментов',
    UNABLE_TO_GET_DATA: 'Не може да се добијат податоци во овој момент',

    APPROVED_SUCCESS: 'Корисничката сметка беше одобрена',
    APPROVED_FAILURE: 'Корисничката сметка не може да се одобри во овој момент',
    REJECT_SUCCESS: 'Корисничката сметка беше одбиена',
    REJECT_FAILURE: 'Корисничката сметка не може да се одбие во овој момент',

    ERROR_GET_LATEST_ORDERS: 'Не може да се земат податоците за последно направени нарачки',
    INFO_NO_DATA_LATEST_ORDERS: 'Нема податоци за последно направени нарачки',

    ERROR_GET_BEST_SELLERS: 'Не може да се земат податоците за најпродавани производи',
    INFO_NO_DATA_BEST_SELLERS: 'Нема податоци за најпродавани производи',

    ERROR_GET_ORDERS_PIPELINE: 'Не може да се земат податоците за преглед на нарачки',
    INFO_NO_DATA_ORDERS_PIPELINE: 'Нема податоци за преглед на нарачки',

    ERROR_GET_OUT_OF_STOCK_INDEX: 'Не може да се земат податоците за продукти што ги нема на залиха',
    INFO_NO_DATA_OUT_OF_STOCK_INDEX: 'Нема податоци за продукти што ги нема на залиха',

    SIGNUP: 'регистрација',
    SIGNUPS: 'регистрации',

    MONTH_JAN: 'Јан',
    MONTH_FEB: 'Фев',
    MONTH_MAR: 'Мар',
    MONTH_APR: 'Апр',
    MONTH_MAY: 'Мај',
    MONTH_JUN: 'Јун',
    MONTH_JUL: 'Јул',
    MONTH_AUG: 'Авг',
    MONTH_SEP: 'Сеп',
    MONTH_OCT: 'Окт',
    MONTH_NOV: 'Нов',
    MONTH_DEC: 'Дек',

    MONTH_JANUARY: 'Jануари',
    MONTH_FEBRUARY: 'Февруари',
    MONTH_MARCH: 'Март',
    MONTH_APRIL: 'Април',
    MONTH_MAY: 'Мај',
    MONTH_JUNE: 'Јуни',
    MONTH_JULY: 'Јули',
    MONTH_AUGUST: 'Август',
    MONTH_SEPTEMBER: 'Септември',
    MONTH_OCTOBER: 'Октомври',
    MONTH_NOVEMBER: 'Ноември',
    MONTH_DECEMBER: 'Декември',

    PRODUCT: 'Производ',
    AMOUNT: 'Количина',
    WORTH: 'Вредност',

    NEW: "Нови нарачки",
    PROCESSING: "Во пакување",
    SHIPPED: "Во транзит",
    DELIVERED: "Испорачани",
};