// vendor imports
import React, { useState } from 'react';
// demo template
import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
// component
import InputTable from 'com/widgets/InputTable';
import Echo from 'com/util/Echo';

const DemoInputTable = () => {

    const initData = [
        { id: 'a1234', price: 100, purchase_price: 90, tax: 5, currency: 'MKD', created: new Date() },
        { id: 'b1234', price: 110, purchase_price: 95, tax: 5, currency: 'MKD', created: new Date() },
        { id: 'c1234', price: 115, purchase_price: 100, tax: 5, currency: 'MKD', created: new Date() }
    ];

    const initTableHeaders = [
        { label: 'Price', type: 'number', name: 'price' },
        { label: 'Purchase price', type: 'number', name: 'purchase_price' },
        { label: 'Tax %', type: 'number', name: 'tax' },
        { label: 'Currency', type: 'dropdown', name: 'currency' },
        { label: 'Date', type: 'datepicker', name: 'created' },
    ];

    const currencies = [
        { value: 'MKD', label: 'MKD' },
        { value: 'EUR', label: 'EUR' }
    ];

    const [tableHeaders, setTableHeaders] = useState(initTableHeaders);
    const [data, setData] = useState(initData);
    const [activeRow, setActiveRow] = useState(0);

    const removeRecord = (index) => {
        const filteredData = data.filter((d, i) => i !== index);
        setData(filteredData);
    };

    const addRecord = (newData) => {
        newData.id = Math.floor(100000 + Math.random() * 900000);
        console.log(newData);
        setData([...data, newData]);
    };

    const handleOnRowClick = (index) => {
        setActiveRow(index);
    };

    return (
        <ComponentDemoTemplate>
            <InputTable columns={tableHeaders} values={currencies} addRecord={addRecord} >
                {data.map((d, index) => {
                    return (
                        <InputTable.Row key={`${index}_${d.id}`} index={index} active={activeRow === index} onClick={handleOnRowClick}>
                            <InputTable.Cell><Echo.Number>{d.price}</Echo.Number></InputTable.Cell>
                            <InputTable.Cell><Echo.Number>{d.purchase_price}</Echo.Number></InputTable.Cell>
                            <InputTable.Cell><Echo.Number>{d.tax}</Echo.Number></InputTable.Cell>
                            <InputTable.Cell>{d.currency}</InputTable.Cell>
                            <InputTable.Cell><Echo.Date locale="de-DE">{d.created}</Echo.Date></InputTable.Cell>
                            <InputTable.Remove index={index} active={activeRow === index} action={removeRecord} />
                        </InputTable.Row>
                    );
                })}
            </InputTable>
        </ComponentDemoTemplate>
    );
};

export default DemoInputTable;