import React from 'react';
//styles
import './style.css';

const StatsCardTemplate = (props) => {

    return (
        <div className="stats-card-template">
            <div className="stats-card-template-container">
                {props.children}
            </div>
        </div>
    );
};

export default StatsCardTemplate;