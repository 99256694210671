import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Button from 'com/ui/Button';
import Modal from 'com/widgets/Modal';

const DemoModalWindow = (props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const footerActions = [
        {
            label: 'Cancel',
            primary: false,
            action: handleCloseModal
        },
        {
            label: 'Save',
            primary: true,
            action: () => {
                console.log('ACTION');
            }
        }
    ];

    return (
        <ComponentDemoTemplate>
            <>
                <Button onClick={() => setIsModalOpen(!isModalOpen)}>Open modal window</Button>
                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    title="Confirmation needed"
                    footerActions={footerActions}
                    // icon='config'
                >
                    <span>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words <br />  which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</span>
                </Modal>
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoModalWindow;