import React, { useState,useEffect } from 'react';
import oblax from 'services/oblax';
// ui
import Icon from 'com/ui/Icon';
// utils 
import dateFormatter from 'services/util/date';
// components
import StatTableCard from 'com/widgets/StatTableCard';
import Avatar from 'com/widgets/Avatar';
// services 
import l11n from 'services/l11n';
import useNotification from 'services/hooks/use_notification';
// util
import Echo from 'com/util/Echo';
// styles
import './style.css';

const StatsMostActive = (props) => {

    const [mostActive,setMostActive] = useState([]);
    const [loading, setLoading] = useState(true);
    const mostActiveCardHeaders = ['User', 'Last seen'];

    useEffect(() => {
        (async () => {
            try {
                let res = await oblax.stats.usersWithRecentPurchases();
                if (res.ok) {
                    setMostActive(res.data);
                    // this should be uncommented
                    setLoading(false); 
                }
            } catch (err) {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log(err);
                }
            }
        })();
    }, []);

    return (
        <StatTableCard title="Most Active" icon="time-line " columns={mostActiveCardHeaders}>
            {
                loading ? 
                <StatTableCard.Loader cols={2} rows={5}/> :
                <TableData data={mostActive}/>
            }
        </StatTableCard>
    );
};

const TableData = (props) => {
    return (
        props.data.length > 0 ?
        props.data.map((user) => {
            return (
                <tr key={user.id}> 
                    <td>
                        <div className="user-data-wrapper">
                            <Avatar src={user.picture} />
                            <div className="user-details">
                                <span className="user-details--full-name">
                                    {`${user.first_name} ${user.last_name}`}
                                </span>
                                <span className="user-details--email">
                                    {user.email}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="user-details--last-seen">
                            <Echo.Date>{user._modified}</Echo.Date>
                        </span>
                    </td>
                </tr>
            ) 
        }) :
        <StatTableCard.NoData colspan={2}/>
    );
};

export default StatsMostActive;