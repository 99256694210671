// vendor imports
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// component
import { Unit } from './unit';
// styles
import './style.css';

const DropdownInput = (props) => {
	let setDisabled = props.disabled ? 'disabled' : '';
	let showError = props.error ? 'error' : '';
	const [isActive, setIsActive] = useState(false);
	const [zTop, setZTop] = useState('');
	const [cursor, setCursor] = useState(props.units.indexOf(props.value.unit));
	const wrapperRef = useRef(null);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		setZTop(isActive ? 'z-top' : '');
	}, [isActive]);

	const dropDown = () => {
		setIsActive(prevState => !prevState);
		setCursor(props.units.indexOf(props.value.unit));
	};

	const selectItem = (item) => {
		props.onChange({ ...props.value, unit: item });
		setIsActive(false);
		setCursor(props.units.indexOf(item));
	};

	const handleArrowKeysScrollBrowser = () => {
		if (isActive === true) {
			document.onkeydown = (e) => {
				if (e.keyCode === 38 || e.keyCode === 40) {
					return false;
				}
			}
		} else {
			document.onkeydown = (e) => {
				if (e.keyCode === 38 || e.keyCode === 40) {
					return true;
				}
			}
		}
	};

	const toggleDropdownAndNavigation = (e) => {
		if (e.keyCode === 27) {
			setIsActive(false);
		} else if (e.keyCode === 13) {
			props.onChange({ ...props.value, unit: props.units[cursor] });
			setIsActive(prevState => !prevState);
		} else if (e.keyCode === 38 && cursor > 0) {
			setCursor(cursor - 1);
		} else if (e.keyCode === 40 && cursor < props.units.length - 1) {
			setCursor(cursor + 1);
		}
		handleArrowKeysScrollBrowser();
	};

	const handleInput = (e) => {
		props.onChange({ ...props.value, value: e.target.value });
	};

	const handleClickOutside = (e) => {
		if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
			setIsActive(false);
		}
	};

	const scrollTo = (ref) => {
		if (ref) {
			ref.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	};

	return (
		<label className={`dropdown-input ${setDisabled} ${showError}`}>
			<span className="dropdown-input__label">{props.label}</span>
			<div tabIndex="0" onKeyUp={toggleDropdownAndNavigation} className={`dropdown ${zTop}`} ref={wrapperRef}>
				<div className="dropdown-btn">
					<div className="dropdown-btn__top">
						<div className="toggle-btn" onClick={dropDown}>
							<span className="unit-title">{props.value.unit}</span>
							<span className={`${isActive ? "dropdown-arrow-up" : "dropdown-arrow-down"}`}></span>
						</div>
						<div className="vertical-divider"></div>
						<input
							type="number"
							className="input-type"
							value={props.value.value}
							onChange={handleInput}
						/>
					</div>
					{isActive && (
						<>
							<div className="divider"></div>
							<div className="dropdown-content" tabIndex="0">
								{props.units.map((unit, i) => (
									<Unit
										key={i}
										indexKey={i}
										selectItem={selectItem}
										value={props.value.unit}
										unit={unit}
										scrollTo={scrollTo}
										active={i === cursor}
									/>
								))}
							</div>						
						</>
					)}
				</div>
			</div>
			<span className="dropdown-input__error">{props.error}</span>
		</label>
	)
};

DropdownInput.propTypes = {
	units: PropTypes.array,
	label: PropTypes.string,
	value: PropTypes.shape({unit: PropTypes.string, value: PropTypes.any}),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	error: PropTypes.string
};

DropdownInput.defaultProps = {
	units: [],
	label: '',
	value: {},
	onChange: () => {},
	disabled: false,
	error: ''
};

export default DropdownInput;