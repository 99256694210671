// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './style.css';

const Switch = (props) => {
	let setDisabled = props.disabled ? 'disabled' : '';

	return (
		<label className={`switch ${setDisabled}`}>
			<span className="switch__label">{props.label}</span>
			<input
				className="switch__input"
				type="checkbox"
				disabled={props.disabled}
				checked={props.checked}
				onChange={props.onChange}
			/>
			<span className={`switch__span ${props.checked && 'switch-checked'}`} />
		</label>
	);
};

Switch.propTypes = {
	label: PropTypes.string,
	disabled: PropTypes.bool,
	checked:PropTypes.bool,
	onChange: PropTypes.func
};

Switch.defaultProps = {
	label: '',
	disabled: false,
	checked: false,
	onChange: () => {}
};

export default Switch;