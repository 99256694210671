// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
// icons
import ICON_WARRNING from 'assets/images/icons/warning-toast-icon.svg';
import ICON_CLOSE from 'assets/images/icons/close.svg';
// styles
import './style.css';

const Error = (props) => {
    return (
        <div className="error-wrapper">
            <div className="error-message">
                <img src={ICON_WARRNING} alt="" className="error-message__icon" />
                <span>{props.message}</span>
            </div>
            <button className="error-message__remove-icon" onClick={props.onClick}>&times;</button>
        </div>
    );
};

Error.propTypes = {
    message: PropTypes.string
};

Error.defaultProps = {
    message: ''
};

export default Error;