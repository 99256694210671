import React from 'react';

import StatsCarousel from 'com/widgets/StatsCarousel';
import StatsCard from 'com/widgets/StatsCard';

const Carousel = () => {
	const numbers = [
		{ value: 15, label: 'pending' },
		{ value: 342, label: 'total' }
	];

	const pieValues = [
		{ value: 15, label: '15% fully set' },
		{ value: 15, label: '15% minimum' },
	];

	return (
		<StatsCarousel>
			<StatsCard.Percentages
				title="Inactive"
				value={15}
				label="of your users"
				reverseColors={true}
			/>
			<StatsCard.Percentages
				title="Active users"
				value={25}
				label="in past 24 hours"
			/>
			<StatsCard.Pie
				title="User profiles"
				values={pieValues}
				label=""
			/>
			<StatsCard.Numbers
				title="Registrations"
				values={numbers}
			/>
			<StatsCard.Percentages
				title="Registration"
				value={85}
				label="success"
			/>
			<StatsCard.Percentages
				title="Unregistered"
				value={3}
				label="of your users"
				reverseColors={true}
			/>
		</StatsCarousel>
	)
};

export default Carousel;