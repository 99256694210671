import React, { useState } from 'react';

//styles
import './style.css';

//components from external library
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, DefaultTooltipContent, ResponsiveContainer} from 'recharts';
//components
import SectionTitle from 'com/ui/SectionTitle';
import DatePicker from 'com/ui/DatePicker';
import Echo from 'com/util/Echo';

const DashboardChartPeople = (props) => {

	const datum = new Date();

	const [datePicker, setDatePicker] = useState(new Date());
	const dateFormat1 = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' };
	const colors = { greyLight: "#ADADAD33", greyDark: "#ACACAC", blue: "#00AADD"};

	const datePickerChange = (e) => {
		setDatePicker(e.target.value);
	};

	const data = [
		{
			name: '04:00',
			signups: 11,
			test: 10,
		},
		{
			name: '06:00',
			signups: 12,
			test: 12,
		},
		{
			name: '08:00',
			signups: 25,
			test: 14,
		},
		{
			name: '10:00',
			signups: 23,
			test: 10,
		},
		{
			name: '12:00',
			signups: 10,
			test: 16,
		},
		{
			name: '12:00',
			signups: 10,
			test: 17,
		},
		{
			name: '12:00',
			signups: 10,
			test: 18,
		},
		{
			name: '12:00',
			signups: 14,
			test: 19,
		},
		{
			name: '12:00',
			signups: 18,
			test: 20,
		},
		{
			name: '12:00',
			signups: 10,
			test: 31,
		},
	];

	const CustomTooltip = (props) => {
		if (props.active && props.payload && props.payload.length) {
		  props.payload[0].chartType = "LineChart";
		  return (
			<div className="tooltip">
			  <p className="label">{props.payload[0].payload.signups}</p>
			  <span>registrations</span>
			</div>
		  );
		}
	
		return null;
	};

	return (
		<div className="dashboard-chart">
			<div className="dashboard-chart__header">
				<SectionTitle icon="line-chart" type="fill">People</SectionTitle>
				<div className="dashboard-chart__header-dates">
					<span className="rectangle_blue"></span>
					<span>Today</span>
					<span className="rectangle_gray"></span>
					<span><Echo.Date locale="en-GB" style="full">{datePicker}</Echo.Date></span>
				</div>
				<DatePicker
					value={datePicker}
					onChange={datePickerChange}
					dateFormat={dateFormat1}
					mondayFirst={true}
					locale="en-US"
					customClassName="dashboard-chart__datepicker"
				/>
			</div>
			<div className="dashboard-chart__linechart">
				<ResponsiveContainer width={"99%"} height={400}>
					<LineChart width={300} height={400} data={data} margin={{ top:20, left: -25, bottom: 5}}>
						<XAxis 
							dataKey="name" 
							stroke={colors.greyLight} 
							tick={{ fill: colors.greyDark, fontSize: 14, dx: 1}}
						/>
						<YAxis 
							stroke={colors.greyLight} 
							type="number" 
							ticks={[0, 10, 20, 30, 40, 50]} 
							tick={{fill: colors.greyDark, fontSize: 14, dy: -25}}
						/>
						<Line 
							type="monotone"
							dataKey="signups"
							stroke={colors.blue}
							strokeWidth="1.5"
							dot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1}} 
							activeDot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1 }} 
						/>
						<Tooltip content={<CustomTooltip/>}/>
						<Line 
							type="monotone"
							dataKey="test"
							stroke={colors.greyDark}
							strokeWidth="1.5"
							dot={{fill:colors.greyDark , stroke:colors.greyDark, strokeWidth: 1}} 
							activeDot={{fill:colors.greyDark ,stroke:colors.greyDark, strokeWidth: 1 }} 
						/>
						<CartesianGrid stroke={colors.greyDark} opacity="0.2"/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default DashboardChartPeople;