import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import DropdownInput from 'com/ui/DropdownInput';

const DemoDropdownInput = (props) => {

    const units = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
    const expiration = { unit: 'Days', value: 28 };
    
    const [dropdownInput, setDropdownInput] = useState(expiration);

    return (
        <ComponentDemoTemplate>
            <>
                <DropdownInput
                    units={units}
                    label="Expiration"
                    value={dropdownInput}
                    onChange={setDropdownInput}
                    disabled={false}
                />
                <hr/>
                <DropdownInput
                    units={units}
                    label="Expiration"
                    value={dropdownInput}
                    onChange={setDropdownInput}
                    disabled={true}
                />
                <hr />
                <DropdownInput
                    units={units}
                    label="Expiration"
                    value={dropdownInput}
                    onChange={setDropdownInput}
                    disabled={false}
                    error="An error has happened"
                />
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoDropdownInput;