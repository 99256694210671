// vendor imports
import React, { useState } from 'react';
// demo template
import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
// component
import DataTree from 'com/widgets/DataTree';

const DemoCategoryTree = () => {

	const initData = [
		{
			id: "1",
			name: "Home",
			link: "http://google-home.com",
			children: [
				{
					id: "1a",
					name: "Home 1",
					link: "http://google-home1.com",
					children: [

					]
				},
				{
					id: "1b",
					name: "Home 2",
					link: "http://google-home2.com",
					children: [

					]
				}
			]
		},
		{
			id: "2",
			name: "Contact",
			link: "http://google-contact.com",
			children: [

			]
		},
		{
			id: "3",
			name: "Products",
			link: "http://google-products.com",
			children: [
				{
					id: "3a",
					name: "Products 1",
					link: "http://google-products1.com",
					children: [
						{
							id: "3a1",
							name: "Products 1-1",
							link: "http://google-products1-1.com",
							children: [

							]
						},
						{
							id: "3a2",
							name: "Products 1-2",
							link: "http://google-products1-2.com",
							children: [

							]
						}
					]
				},
				{
					id: "3b",
					name: "Products 2",
					link: "http://google.com",
					children: [

					]
				}
			]
		}
	];
	const [data, setData] = useState(initData);

	const onEdit = (node, pl) => {
		console.log('edit node', node, pl);
	};

	const onCreateChild = (node, pl) => {
		console.log('create child node', node, pl);
	};

	const onDelete = (node, pl) => {
		console.log('delete node', node, pl);
	};

	return (
		<ComponentDemoTemplate>
			<div style={{backgroundColor: '#fff', padding: '40px 20px'}}>
				<DataTree
					data={data}
					setData={setData}
					fullTree={data}
					onEdit={onEdit}
					onCreateChild={onCreateChild}
					onDelete={onDelete}
					// onlyWithinParent={true} // ONLY reorder within the node it is positioned in
				/>
			</div>
		</ComponentDemoTemplate>
	);
};

export default DemoCategoryTree;