import React from 'react';
import PropTypes from 'prop-types';

const Num = (props) => {
    let value = new Intl.NumberFormat(props.locale).format(props.children);
    return value;
};

Num.propTypes = {
    locale: PropTypes.string,
    children: PropTypes.number.isRequired
};

Num.defaultProps = {
    locale: 'en-GB'
};

const Datum = (props) => {
    if (props.children) {
        let d = new Date(props.children);
        let format = {};
        if(props.style) {
            format.dateStyle = props.style;
        }
        if(props.onlyTime) {
            format = {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            };
        }
        let date = new Intl.DateTimeFormat(props.locale, format).format(d);
        return date;
    }
    return '';
};

Datum.propTypes = {
    locale: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(Date)
    ]).isRequired,
    style: PropTypes.oneOf(['short', 'full']),
};

Datum.defaultProps = {
    locale: 'en-GB'
};

export default {
    Number: Num,
    Date: Datum
};