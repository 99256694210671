import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toastsRemove } from 'services/redux/ducks/notification_toasts';
// styles
import './style.css'
// icons
import ICON_POSITIVE from 'assets/images/icons/positive-toast-icon.svg';
import ICON_NEGATIVE from 'assets/images/icons/negative-toast-icon.svg';
import ICON_WARNING from 'assets/images/icons/warning-toast-icon.svg';
import ICON_INFO from 'assets/images/icons/informative-toast-icon.svg';

const COLORS = {
    warning: 'warning',
    success: 'success',
    info: 'info',
    error: 'error'
};

const ICONS = {
    warning: ICON_WARNING,
    success: ICON_POSITIVE,
    info: ICON_INFO,
    error: ICON_NEGATIVE
};

const Toast = (props) => {
    const dispatch = useDispatch();
    const [unmount, setUnmount] = useState('');

    useEffect(() => {
        setTimeout(() => {
            setUnmount('unmount');
            setTimeout(() => {
                dispatch(toastsRemove(props.id));
            }, 300);
        }, props.timeout);
    },[])

    const removeToast = () => {
        dispatch(toastsRemove(props.id));
    };

    return (
        <div className={`toast-container ${COLORS[props.type]} ${unmount}`}>
            <img 
                className="toast-container__icon"
                src={ICONS[props.type]}
                alt=""
            />
            <div className="toast-container__content">
                {props.value}
            </div>
            <span className="toast-container__separator"></span>
            <button className="toast-container__remove" onClick={removeToast}>
                &times;
            </button>
        </div>
    );
};

Toast.propTypes = {
    type: PropTypes.oneOf(['warning', 'success', 'info', 'error']),
    value: PropTypes.string,
    timeout: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

Toast.defaultProps = {
    type: 'info',
    timeout: 4000,
    value: 'Empty Toast'
};

export default Toast;
