import React from 'react';

import ComponentsList from 'data/coms';
import { NavLink } from 'react-router-dom';

import './style.css';

const ComponentDemoTemplate = (props) => {
    return (
        <div className="component-demo-tpl">
            <div className="component-demo-tpl__navigation">
                {ComponentsList.map((c, i) => {
                    return (<NavLink key={i} to={c.link} exact>{c.name}</NavLink>)
                })}
            </div>
            <div className="component-demo-tpl__body">
                {props.children}
            </div>
        </div>
    );
};

export default ComponentDemoTemplate;