import React from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Input from 'com/ui/Input';

const DemoInput = (props) => {
    return (
        <ComponentDemoTemplate>
            <>
                <Input type="text" label="Some label"/>
                <Input type="text" label="Another one" description="Some description"/>
                <Input type="email" label="Your emaili address" description="Some description"/>
                <Input type="password" label="Enter your password" description="Some description"/>
                <Input type="number" label="Tell us your age" description="Some description"/>
                <Input type="text" label="Some label" error="Custom error message"/>
                <Input type="text" label="Some label" disabled={true}/>
            </>
        </ComponentDemoTemplate>
    );
};

export default DemoInput;