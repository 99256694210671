//vendor imports
import React from 'react';
//templates
import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';
//components
import DetailsItemCard from 'com/widgets/DetailsItemCard';

const DemoDetailsItemCard = (props) => {

    const date = new Date();

    const badges = [
        { icon: 'mail', color: 'primary' },
        { icon: 'mail', color: 'lightgrey' },
        { icon: 'hashtag ', color: 'lightgrey' }
    ];

    const onMenuOption1 = (data) => {
        console.log('On Click - Menu Option 1');
        console.log('Data', data);
    };

    const onMenuOption2 = (data) => {
        console.log('On Click - Menu Option 2');
        console.log('Data', data);
    };

    const onMenuOption3 = (data) => {
        console.log('On Click - Menu Option 3');
        console.log('Data', data);
    };

    const actions = [
        { label: 'Menu Option 1', action: onMenuOption1 },
        { label: 'Menu Option 2', action: onMenuOption2 },
        { label: 'Menu Option 3', action: onMenuOption3 }
    ];

    return (
        <ComponentDemoTemplate>
            <DetailsItemCard 
                title ="Review Form" 
                footerLabel="Last modified"
                date={date}
                link="/"
                actions={actions}
            > 
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
            </DetailsItemCard>
            <br/>
            <DetailsItemCard 
                title ="Newsletter Form" 
                footerLabel="Last modified"
                date={date}
                link="/"
                actions={actions}
            > 
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
            </DetailsItemCard>
            <br/>
            <DetailsItemCard 
                title ="Review Form" 
                footerLabel="Last modified"
                date={date}
                link="/"
                badges={badges}
                actions={actions}
            > 
             Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
                Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, 
                sometimes by accident, sometimes on purpose (injected humour and the like).
            </DetailsItemCard>
        </ComponentDemoTemplate>
    )
};

export default DemoDetailsItemCard;