import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toastsRemove } from 'services/redux/ducks/notification_toasts';
//styles
import './style.css';
//icons
import ICON_BIN from 'assets/images/icons/bin-orange.svg';

const Actionable = (props) => {
    const dispatch = useDispatch();
    const [unmount, setUnmount] = useState('');

    const removeActionable = () => {
        setUnmount('unmount');
        setTimeout(() => {
            dispatch(toastsRemove(props.id));
        }, 300);
    };

    return (
        <div className={`actionable-container ${unmount}`}>
            <span className="actionable-container__decoration__line"></span>
            <div className="actionable-container__icon-container">
                <img 
                    className="actionable-container__icon"
                    src={props.icon}
                    alt='actonable notification icon' 
                />
            </div>
            <div className="actionable-container__content">
                <h3>{props.title}</h3>
                <span>{props.value}</span>
                <div className="actionable-container__actions">
                    {props.actions.map((a,i) => {
                        return <button key={i} onClick={a.action}>{a.label}</button>
                    })}
                </div>
            </div>
            <button className="actionable-container__remove" onClick={removeActionable}>
                &times;
            </button>
        </div>
    )
};

Actionable.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icon: PropTypes.node,
    title: PropTypes.string,
    value: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, action: PropTypes.func}))
};

Actionable.defaultProps = {
    icon: ICON_BIN,
    title: 'Notification Title',
    value: 'Sample value',
    actions: []
};

export default Actionable;
