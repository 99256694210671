import React from 'react';
// components
import StatsMostActive from './StatsMostActive';
import StatsMostVisited from './StatsMostVisited';
// css
import './style.css'

const StatTables = () => {

    return (
        <div className="stat-tables">
            <div className="stat-tables__card">
                <StatsMostVisited/>
            </div>
            <div className="stat-tables__card">
                <StatsMostActive/>
            </div>
        </div>
    );
};

export default StatTables;