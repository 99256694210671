import React, { useState } from 'react';

import ComponentDemoTemplate from 'com/templates/ComponentDemoTemplate';

import Switch from 'com/ui/Switch'; 

const DemoSwitch = (props) => {

    const [switchValue, setSwitchValue] = useState(false);

    return (
        <ComponentDemoTemplate>
            <Switch
                label="Switch option"
                checked={switchValue}
                disabled={false}
                onChange={() => setSwitchValue(!switchValue)}
            />
            <Switch
                label="Switch option (disabled)"
                checked={switchValue}
                disabled={true}
                onChange={() => setSwitchValue(!switchValue)}
            />
        </ComponentDemoTemplate>
    );
};

export default DemoSwitch;