//vendors
import React from "react";
//templates
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import ListViewTemplate from "com/templates/ListViewTemplate";
import StatsCardTemplate from 'com/templates/StatsCardTemplate';
//ui compoonents
import { Paging } from "com/ui/DataTable";
import Button from "com/ui/Button";
//widgets
import StatsCarousel from 'com/widgets/StatsCarousel';
import DetailsItemCard from "com/widgets/DetailsItemCard";
import PopoutDataFilter from "com/widgets/PopuoutDataFilter";
//services
import useDataTable from 'services/hooks/use_datatable';
//icons
import ICON_PLUS from 'assets/images/icons/btn-plus-white.svg';
//styles
import './style.css';


const PagesFormsForms = (props) => {

    const headerNav = [
		{ to: '/pagesforms', label: 'Dashboard' },
		{ to: '/pagesforms/pages', label: 'Pages' },
		{ to: '/pagesforms/forms', label: 'Forms' },
	];

    const initDataFilter = {
        limit: 10,
        order: {dir: 'desc', field: '_created'},
        filters: []
    };

    const initFilterFields = [
        {title: 'Name', field: 'name', type: 'string', sort: 'none', sortable: true},
    ];

    const [
        tableData, 
        tableHeaders,
        paging,
        pagingChange,
        filterApply
    ] = useDataTable(
        initDataFilter, 
        initFilterFields, 
        'forms.getAllMessageFormsV2'
    );

    const optionOne = () => {
        console.log("Option 1 clicked");
    };

    const optionTwo = () => {
        console.log("Option 2 clicked");
    };

    const optionThree = () => {
        console.log("Option 3 clicked");
    };

    const data = {
        active: [
            {label: 'Active', value: '1'},
            {label: 'Inactive', value: '0'},
        ]
    };

    let actions = [
        {label: 'option 1', action: optionOne},
        {label: 'option 2', action: optionTwo},
        {label: 'option 3', action: optionThree},
    ];

    const socialsSorted = tableData.map(({ notification_email, email_response, notification_slack, ...rest}) => {
            return {
                ...rest,
                socials:[
                    {
                        icon: 'mail',
                        color: notification_email ? 'primary' : 'lightgrey'
                    },
                    {
                        icon: 'mail-send',
                        color: email_response ? 'primary' : 'lightgrey'
                    },
                    {
                        icon: 'slack',
                        color: notification_slack ? 'primary' : 'lightgrey'
                    },
                ]
            };
    });

    return (
        <ApplicationTemplate
            headerTitle="Pages &amp; Forms"
            headerNavigation={headerNav}
        >
            <ListViewTemplate>
                <ListViewTemplate.Top>
                    <StatsCarousel>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                        <StatsCardTemplate>1</StatsCardTemplate>
                    </StatsCarousel>
                </ListViewTemplate.Top>
                <ListViewTemplate.Actions>
                    <PopoutDataFilter
                        dataTypes={tableHeaders}
                        filterOptions={data}
                        tableName="pages_details"
                        onApply={filterApply}
                    />
                    <Button 
                        customClassName="create-form"
                        icon={ICON_PLUS}
                        type="secondary"
                    >
                        Create new
                    </Button>
                </ListViewTemplate.Actions>
                <ListViewTemplate.Main>
                    <ul className="forms-card-container">
                        {socialsSorted.map(f => {
                            return(
                                <li className="forms-card-container__details-card">
                                    <DetailsItemCard
                                        title={f.name}
                                        link={f.permalink}
                                        actions={actions}
                                        badges={f.socials}
                                        date={f._modified}
                                        footerLabel={f._created === f._modified ? 'Created' : 'Last Modified'}
                                        key={f.id} 
                                    >
                                        {f.fields.map((field,i) => {
                                            return(
                                                <ul className="form-fields-container" key={i}>
                                                    <li className="form-fields-container__list">
                                                        <div className="form-fields-container__list__square"></div>
                                                        <p>{field}</p>
                                                    </li>
                                                </ul>
                                            )
                                        })}
                                    </DetailsItemCard>
                                </li>
                            )
                        })}
                    </ul>
                    <Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange}/>
                </ListViewTemplate.Main>
            </ListViewTemplate>
        </ApplicationTemplate>
    );

};

export default PagesFormsForms;