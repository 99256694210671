import React, { useState, useEffect } from 'react';
//components from external library
import { 
    LineChart, 
    Line, 
    XAxis, 
    YAxis, 
    Tooltip, 
    CartesianGrid, 
    ResponsiveContainer
} from 'recharts';
//components
import SectionTitle from 'com/ui/SectionTitle';
import DatePicker from 'com/ui/DatePicker';
import Echo from 'com/util/Echo';
//services
import oblax from 'services/oblax';
import { sqlDate } from 'services/util/date';
import l11n from 'services/l11n';
//styles
import './style.css';

const DashboardChart = (props) => {

    const [todaysSignups, setTodaysSignups] = useState([]);
    const [datePicker, setDatePicker] = useState(new Date());

    const dateFormat1 = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'long' };
    const colors = { greyLight: "#ADADAD33", greyDark: "#ACACAC", blue: "#00AADD"};
    const months = [
        l11n.t('MONTH_JAN'),
        l11n.t('MONTH_FEB'),
        l11n.t('MONTH_MAR'),
        l11n.t('MONTH_APR'),
        l11n.t('MONTH_MAY'),
        l11n.t('MONTH_JUN'),
        l11n.t('MONTH_JUL'),
        l11n.t('MONTH_AUG'),
        l11n.t('MONTH_SEP'),
        l11n.t('MONTH_OCT'),
        l11n.t('MONTH_NOV'),
        l11n.t('MONTH_DEC'),
    ];

    useEffect(() => {
        (async() => {
            try {
                let res = await oblax.stats.monthlySignups(sqlDate(new Date()));
                if (res.ok) {
                   let formattedData  = formatingUserSignupsChart(res.data);
                   setTodaysSignups(formattedData);
                }
            } catch (err) {
               console.log(err);
            }
        })();
    },[]);

    useEffect(() => {
        (async() => {
            let now = new Date();
            if(!(datePicker.getFullYear() == now.getFullYear() && datePicker.getMonth() == now.getMonth() && datePicker.getDate() == now.getDate())) {
                try {
                    let res = await oblax.stats.monthlySignups(sqlDate(datePicker));
                    if (res.ok) {
                       let formattedData  = formatingUserSignupsChart(res.data);
                       let newData = todaysSignups.map((s, i) => {
                        return { 
                            ...s,
                            parallelSigups: formattedData[i].signups
                        }
                       });
                       console.log('newData', newData);
                       setTodaysSignups(newData);
                    }
                } catch (err) {
                   console.log(err);
                }
            }

        })();
    }, [datePicker])

    const datePickerChange = (e) => {
        setDatePicker(e.target.value);
    };

    const formatingUserSignupsChart = (data) => {
        let d = [];
        for(let i = 0; i < 12; i++) {
            let entry = {
                signups: 0,
                month: months[i]
            };
            for(let s of data) {
                let m = new Date(s.date).getMonth();
                if(m === i) {
                    entry.signups += s.signups;
                }
            }
            d.push(entry);
        }
        return d;
    };

    return (
        <div className="dashboard-chart">
            <div className="dashboard-chart__header">
                <SectionTitle icon="line-chart" type="fill">Today's Signups</SectionTitle>
                <div className="dashboard-chart__header-dates">
                    <span className="rectangle_blue"></span>
                    <span>Today</span>
                    <span className="rectangle_gray"></span>
                    <span><Echo.Date locale="en-GB" style="full">{datePicker}</Echo.Date></span>
                </div>
                <DatePicker
                    value={datePicker}
                    onChange={datePickerChange}
                    dateFormat={dateFormat1}
                    mondayFirst={true}
                    locale="en-US"
                    customClassName="dashboard-chart__datepicker"
                />
            </div>
            <div className="dashboard-chart__linechart">
                <ResponsiveContainer width={"99%"} height={400}>
                    <LineChart width={300} height={400} data={todaysSignups} margin={{ top:20, left: -25, bottom: 5}}>
                        <Line 
                            type="monotone"
                            dataKey="signups"
                            stroke={colors.blue}
                            strokeWidth="1.5"
                            dot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1}} 
                            activeDot={{fill:colors.blue , stroke:colors.blue, strokeWidth: 1 }} 
                        />
                        <Line 
                            type="monotone"
                            dataKey="parallelSigups"
                            stroke={colors.greyDark}
                            strokeWidth="1.5"
                            dot={{fill:colors.greyDark , stroke:colors.greyDark, strokeWidth: 1}} 
                            activeDot={{fill:colors.greyDark ,stroke:colors.greyDark, strokeWidth: 1 }} 
                        />
                        <XAxis 
                            dataKey="month" 
                            stroke={colors.greyLight} 
                            tick={{ fill: colors.greyDark, fontSize: 14, dx: 1}}
                        />
                        <YAxis 
                            stroke={colors.greyLight} 
                            type="number" 
                            ticks={[0, 10, 20, 30, 40, 50]} 
                            tick={{fill: colors.greyDark, fontSize: 14, dy: -25}}
                        />
                        <Tooltip content={<CustomTooltip/>} offset={-50}/>
                        <CartesianGrid stroke={colors.greyDark} opacity="0.2"/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
};

const CustomTooltip = (props) => {
    if (props.active && props.payload && props.payload.length) {
    let label1 = props.payload[0].payload.signups === 1 ? l11n.t('SIGNUP') : l11n.t('SIGNUPS');
    let label2 = props.payload[0].payload.parallelSigups === 1 ? l11n.t('SIGNUP') : l11n.t('SIGNUPS');
    return (
        <div className="tooltip-container">
            <div className="tooltip blue ">
                {props.payload[0].payload.signups} {label1}
            </div>
            { !isNaN(props.payload[0].payload.parallelSigups) ? 
                <div className="tooltip grey">
                    {props.payload[1].payload.parallelSigups} {label2}
                </div> :
                null
            }
        </div>
      );
    }
    return null;
};

export default DashboardChart;