import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// components
import SectionTitle from "com/ui/SectionTitle";
import ActivityCard from "com/widgets/ActivityCard";
import Icon from "com/ui/Icon";
//css
import './style.css';

const CardsList = () => {
    const [usersData, setUsersData] = useState([
        {
            id: 1,
            name: 'Joshua Murray',
            email: 'joshua@murray.com',
            message:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its',
            status: "Contact Form"
        },
        {
            id: 2,
            name: 'Joshua Murray',
            email: 'joshua@murray.com',
            message:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            status: "Contact Form"
        },
        {
            id: 3,
            name: 'Joshua Murray',
            email: 'joshua@murray.com',
            message:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            status: "Review Form"
        },
        {
            id: 4,
            name: 'Joshua Murray',
            email: 'joshua@murray.com',
            message:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            status: "Contact Form"
        },
        {
            id: 5,
            name: 'Joshua Murray',
            email: 'joshua@murray.com',
            message:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
            status: "Review Form"
        },
    ]);

    const [loading, setLoading] = useState(true);

    // the emptyData state and the useEffect are ONLY for testing purposes

    const [emptyData, setEmptyData] = useState([]);

    useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 6000);
	}, []);

    return (
        <div className="cards-list">
            <div className="cards-list__header">
                <SectionTitle icon="chat-poll">Latest messages</SectionTitle>
                <Link className="card-list__header-button">
                    <Icon name="arrow-right-s" color="white" type="line" size="larger" />
                </Link>
            </div>
            <div className="cards-list__wrapper">
                {
                    loading ?
					<LoadingData/> :
					<CardData data={usersData}/>
                }
            </div>
        </div>
    );
};

const LoadingData = () => {
	return (
		<>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
			<ActivityCard icon="team-fill "></ActivityCard>
		</>
	);
};

const CardData = (props) => {
    const date = new Date();
    return (
        props.data.length > 0 ?
        props.data.map((u, i) => {
            let color = u.status == "Contact Form" ? "blue" : "purple";
            return (
                <ActivityCard
                    icon="team-fill "
                    status={u.status}
                    statusColor={color}
                    action="Message Details"
                    date={date}
                >
                    <ActivityCard.Body>
                        <ActivityCard.Left>
                            <div className="cards-list__user-details">
                                <span className="cards-list__user-details-name">{u.name}</span>
                                <span className="cards-list__user-details-email">{u.email}</span>
                            </div>
                        </ActivityCard.Left>
                        <ActivityCard.Right>
                            <p className="cards-list__user-details-message">
                                {u.message}
                            </p>
                        </ActivityCard.Right>
                    </ActivityCard.Body>
                </ActivityCard>
            );
        }) :
        <ActivityCard.NoData/>
    );
};

export default CardsList;