// vendors
import React from "react";

//components
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import DashboardTemplate from "com/templates/DashboardTemplate";
import Carousel from "./Carousel";
import StatTables from "./StatTables";
import CardsList from "./CardsList";
import DashboardChart from "./DashboardChart";
//style
import './style.css';

const PagesFormsDashboard = (props) => {

    const headerNav = [
        { to: '/pagesforms', label: 'Dashboard' },
        { to: '/pagesforms/pages', label: 'Pages' },
        { to: '/pagesforms/forms', label: 'Forms' },
    ];

    return (
        <ApplicationTemplate
            headerTitle="Pages &amp; Forms"
            headerNavigation={headerNav}
        >
            <DashboardTemplate>
                <DashboardTemplate.Top>
                    <Carousel />
                </DashboardTemplate.Top>
                <DashboardTemplate.Main>
                    <div className="pagesforms-dashboard__main-content">
                        <DashboardChart />
                        <StatTables />
                    </div>
                </DashboardTemplate.Main>
                <DashboardTemplate.Right>
                    <CardsList />
                </DashboardTemplate.Right>
            </DashboardTemplate>
        </ApplicationTemplate>
    )
};

export default PagesFormsDashboard;