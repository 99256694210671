// vendors 
import React from "react";

//components
import ApplicationTemplate from 'com/templates/ApplicationTemplate';
import DashboardTemplate from "com/templates/DashboardTemplate";
import Carousel from './Carousel';
import DashboardChartPeople from './DashboardChart/DashboardChartPeople';
import DashboardChartCompanies from './DashboardChart/DashboardChartCompanies';
import CardsList from './CardsList';

// styles 
import './style.css'

const ContactsDashboard = (props) => {

	const headerNav = [
		{ to: '/contacts', label: 'Dashboard' },
		{ to: '/contacts/people', label: 'People' },
		{ to: '/contacts/companies', label: 'Companies' },
	];
	return (
		<ApplicationTemplate
			headerTitle="Contacts"
			headerNavigation={headerNav}
		>
			<DashboardTemplate>
				<DashboardTemplate.Top>
					<Carousel />
				</DashboardTemplate.Top>
				<DashboardTemplate.Main>
					<div className="contacts-dashboard__main-content">
						<DashboardChartPeople />
						<DashboardChartCompanies />
					</div>
				</DashboardTemplate.Main>
				<DashboardTemplate.Right>
					<CardsList />
				</DashboardTemplate.Right>
			</DashboardTemplate>
		</ApplicationTemplate>
	)
};

export default ContactsDashboard;