import React, { useState } from 'react';
import { useSelector } from 'react-redux';
//components
import Avatar from 'com/widgets/Avatar';
import Menu from './menu';
//icon
import ICON_MENU_DOTS from 'assets/images/icons/user-menu-dots.svg';
//styles
import './style.css';

const UserMenu = () => {
	const userData = useSelector(state => state.user.profile);
	const [menuVisibility, setMenuVisibility] = useState(false);

	return (
		<button
			className="user-menu"
			onBlur={() => { console.log('blur'); setMenuVisibility(false) }}
			onClick={() => { console.log('click'); setMenuVisibility(!menuVisibility) }}
		>
			<div className="user-menu__text">
				<span className="user-menu__text__name">
					{userData.first_name} {userData.last_name}
				</span>
				<span className="user-menu__text__role">{userData.role}</span>
			</div>
			<Avatar src={userData.picture} />
			<img className="user-menu__text__dots" src={ICON_MENU_DOTS} alt="" />
			{ menuVisibility ? <Menu /> : null }
		</button>
	);
};

export default UserMenu;